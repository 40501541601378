import { Navigate } from 'react-router-dom';
import LocationContainerPage from '../../../../views/Locations/LocationContainerPage';
import ObjectPropertiesPage from '../../../../views/Properties/ObjectPropertiesPage';
import ObjectsWrapper from '../../../../../common/ObjectWrapper/ObjectsWrapper';
import ObjectsWrapperRemoteConfig from '../../../../../common/ObjectWrapper/helpers/ObjectWrapperRemoteConfig';

export default {
  path: 'info',
  children: [
    {
      index: true,
      element: <Navigate to={'analytics/locations'} />,
    },
    {
      path: 'analytics/:reportId?',
      element: (
        <ObjectsWrapperRemoteConfig
          key={'project_ObjectsWrapper_info_analytics'}
          contentType={'info'}
          section={'analytics'}
        />
      ),
    },
    {
      path: 'objects/:reportId?',
      element: (
        <ObjectsWrapper key={'project_ObjectsWrapper_info_analytics'} contentType={'info'} section={'analytics'} />
      ),
    },
    {
      path: 'locationContainerPage',
      element: <LocationContainerPage />,
      children: [
        {
          path: ':buildingId/:floorId/:unitId',
          element: (
            <ObjectPropertiesPage
              key={'project_PropertyAnalytics_location_info_locationsInfo'}
              contentType={'info'}
              subjectName={'locationsInfo'}
            />
          ),
        },
      ],
    },
  ],
};
