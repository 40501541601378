import AwesomeDebouncePromise from 'awesome-debounce-promise';

import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import { QCReportContext } from '../../../common/analytics/contexts';
import { lokiInstance } from '../../../common/configureMiddleware';
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';

import _ from 'lodash';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';

// actions
import { saveMenus } from '../../menus/funcs.js';
import { filterChecklist, columnTypes, getChecklistReport } from '../../../common/analytics/funcs';

import 'react-table/react-table.css';
import '../../assets/css/table.css';
import theme from '../../assets/css/theme';
import { sectionHeight } from '../Reports/Table.js';
import { newColumnWidth } from '../Reports/TableColumnHeader';

import analyticsMessages from '../../../common/analytics/analyticsMessages';

import TableWrapper from '../Reports/TableWrapper';
import Select from 'react-select';
import SelectableUsers from '../../components/CementoComponents/SelectableUsers';
import TableFilters from '../Reports/TableFilters';
import TableColumnHeader from '../Reports/TableColumnHeader';
import QCTableCell from '../Reports/QCTableCell';
import TableCollapsibleCell from '../Reports/TableCollapsibleCell';
import TradesSelector from '../../components/CementoComponents/TradesSelector';
import DateRangePicker from '../../components/CementoComponents/DateRangePicker';
import {withChecklistItemInstances} from '../../../common/posts/hooks/useChecklistItemInstances';
import withRouterHOC from '../../components/Router/util/withRouterHOC.js';
import { track } from '../../../common/lib/reporting/actions.js';
import DataManagerInstance from '../../../common/dataManager/DataManager.js';
import { SUBJECTS } from '../../../common/dataManager/subjects.js';
import { withLoading } from '../../../common/hooks/useLoading.js';
import LoadingIndicator from '../../components/Loading/LoadingIndicator.js';

// Const
const marginRight = 20;
const cellHeight = 40;
const customTop = 51;
const borderColor = 'rgb(215, 215, 215)';

class QCAnalytics extends React.Component {
	constructor(props) {
		super(props);
		this.setComponentData = this.setComponentData.bind(this);
		this.getSideCardObject = this.getSideCardObject.bind(this);
		this.getChecklistsIssues = this.getChecklistsIssues.bind(this);
		this.displaySelectedView = this.displaySelectedView.bind(this);
		this.filtersHandler = this.filtersHandler.bind(this);
		this.filterHandler = this.filterHandler.bind(this);
		this.handleSaveFilter = this.handleSaveFilter.bind(this);
		this.getFilterMenuComponent = this.getFilterMenuComponent.bind(this);
		this.createColumnComponents = this.createColumnComponents.bind(this);
		this.handleUpdateSelectedFilterSet = this.handleUpdateSelectedFilterSet.bind(this);
		this.lokiPostsListener = this.lokiPostsListener.bind(this);
		this.getChecklistReportData = this.getChecklistReportData.bind(this);
		this.reCalcHeader = this.reCalcHeader.bind(this);
		this.firstColumnRenderFunc = this.firstColumnRenderFunc.bind(this);

		const { intl } = props;
		const statusReject = {
			label: intl.formatMessage(analyticsMessages.statusLabels.rejected),
			value: 'rejected',
		};
		const statusResolved = {
			label: intl.formatMessage(analyticsMessages.statusLabels.resolved),
			value: 'resolved',
		};
		const statusConfirm = {
			label: intl.formatMessage(analyticsMessages.statusLabels.confirm),
			value: 'confirm',
		};
		const statusConfirm2 = {
			label: intl.formatMessage(analyticsMessages.statusLabels.confirm2),
			value: 'confirm2',
		};
		this.allStatusArray = [statusReject, statusResolved, statusConfirm, statusConfirm2];
		this.allBadgesArray = [
			{
				value: 'issues',
				label: intl.formatMessage(analyticsMessages.taskBadge),
			},
			{ value: 'docs', label: intl.formatMessage(analyticsMessages.docBadge) },
		];
		this.allViewTypesArray = [
			{
				value: 'qcProgress',
				label: intl.formatMessage(analyticsMessages.viewType.progress),
			},
			{
				value: 'grade',
				label: intl.formatMessage(analyticsMessages.viewType.grade),
			},
		];

		this.state = {
			sortedBuildings: [],
			filtersSelectableObjects: {},
			calcAllStatuses: (props.configurations || {}).getNested(['checklists', 'calcAllStatuses'], false),
			filtersMenu: null,
		};
	}

	UNSAFE_componentWillMount() {
		const { track, selectedProjectId, viewer } = this.props;
		track('enterQCAnalyticsPage', { projectId: selectedProjectId })

		const baseParams = {
			scope: "projects",
			scopeId: selectedProjectId,
			viewer,
		}

		DataManagerInstance.loadAndConnect({
			...baseParams,
			subject: SUBJECTS.CHECKLIST_ITEM_INSTANCES,
		});
		DataManagerInstance.loadAndConnect({
			...baseParams,
			subject: SUBJECTS.PROPERTIES_INSTANCES,
			queryParams: { subjectName: "locationsInfo" },
		});
		DataManagerInstance.loadAndConnect({
			...baseParams,
			subject: SUBJECTS.PROPERTIES_INSTANCES,
			queryParams: { subjectName: "checklistItemsInfo" },
		});
		DataManagerInstance.loadAndConnect({
			...baseParams,
			subject: SUBJECTS.POSTS,
		});

		this.lokiPosts = lokiInstance.getCollection('posts');
		this.lokiPosts.cementoOn('QCAnalyticsPostsListener', this.lokiPostsListener);
		this.setComponentData({}, this.props);
	}

	componentWillUnmount() {
		this.lokiPosts.cementoOff('QCAnalyticsPostsListener');
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	setComponentData(props, nextProps) {
		const { menus, selectedProjectId } = nextProps;

		let analyticsSubMenus = menus ? menus['qa'] : null;

		if (
			nextProps.selectedProjectId &&
			(nextProps.selectedProjectId != props.selectedProjectId ||
				props.isValDiff(nextProps, ['match', 'params', 'reportId']) ||
				nextProps.menus != props.menus) || 
				props.isValDiff(nextProps, ['configurationMode'])
		)
			if (props.isValDiff(nextProps, ['match', 'params', 'reportId']))
				this.setState({ selectedUser: null, selectedRange: null }, () =>
					this.displaySelectedView(nextProps, analyticsSubMenus),
				);
			else this.displaySelectedView(nextProps, analyticsSubMenus);

		if (nextProps.selectedProjectId && nextProps.checklistInstances != this.props.checklistInstances) {
			// Debouncer for update to not be called many times
			this.refreshTableDataDebouncer(nextProps.checklistInstances == this.props.checklistInstances);
		}

		if (props.isValDiff(nextProps, ['configuraionts']))
			this.setState({
				calcAllStatuses: (nextProps.configurations || {}).getNested(['checklists', 'calcAllStatuses'], false),
			});
	}

	refreshTableDataDebouncer = AwesomeDebouncePromise((nextProps) => {
		this.refreshTableData(nextProps);
	}, 1000);

	lokiPostsListener(collectionName) {
		if (collectionName === 'QCAnalyticsPostsListener') {
			this.refreshTableDataDebouncer(this.props, false);
		}
	}

	addDuplicationsNoToTitle(originalRowData, props) {
		const { checklists } = props || this.props;
		let checklistDuplications = {};
		Boolean(originalRowData && originalRowData.groupByMetaData) &&
			originalRowData.groupByMetaData[3].loopEach((checklistId, checklist) => {
				let { originChecklist } = checklists.getNested([checklistId], {});
				if (originChecklist) {
					let title = originalRowData.groupByMetaData[3].getNested([checklistId, 'title']);
					if (!checklistDuplications[originChecklist]) {
						originalRowData.groupByMetaData[3] = originalRowData.groupByMetaData[3].setNested(
							[originChecklist, 'title'],
							`${title} - 1`,
						);
						checklistDuplications[originChecklist] = true;
					}
					let duplicationNo = checklists.getNested([checklistId, 'duplicationNo'], 1);
					originalRowData.groupByMetaData[3] = originalRowData.groupByMetaData[3].setNested(
						[checklistId, 'title'],
						`${title} - ${duplicationNo}`,
					);
				}
			});

		return originalRowData;
	}

	async refreshTableData(props) {
		const { currFilterSet = {} } = this.state;
		let originalRowData = await this.getChecklistReportData(props);
		originalRowData = this.addDuplicationsNoToTitle(originalRowData, props);
		let originalData = filterChecklist(originalRowData, currFilterSet.checklistItemIdFilter || null);
		let postsMap = this.getChecklistsIssues(originalData);
		this.setState({ originalData, postsMap });
	}

	async getChecklistReportData(props) {
		const { selectedProjectId } = props || this.props;
		const { selectedRange, selectedUser } = this.state;

		let startTS = null;
		let endTS = null;
		if (selectedRange) {
			startTS = selectedRange.startDate ? selectedRange.startDate.getTime() : null;
			endTS = selectedRange.endDate ? selectedRange.endDate.getTime() : null;
		}

		let userId = null;
		if (selectedUser) userId = selectedUser.id;

		let originalRowDataPromise = getChecklistReport(selectedProjectId, 'checklistItem', true, startTS, endTS, userId);
		let beforeRangeOriginalRowData = null;
		let afterRangeOriginalRowData = null;
		if (startTS || endTS) {
			let beforeRangeOriginalRowDataPromise = null;
			let afterRangeOriginalRowDataPromise = null;
			if (startTS)
				beforeRangeOriginalRowDataPromise = getChecklistReport(
					selectedProjectId,
					'checklistItem',
					true,
					null,
					startTS,
					userId,
				);
			if (endTS)
				afterRangeOriginalRowDataPromise = getChecklistReport(
					selectedProjectId,
					'checklistItem',
					true,
					endTS,
					Date.now(),
					userId,
				);

			if (beforeRangeOriginalRowDataPromise) beforeRangeOriginalRowData = await beforeRangeOriginalRowDataPromise;
			if (afterRangeOriginalRowDataPromise) afterRangeOriginalRowData = await afterRangeOriginalRowDataPromise;
		}

		let originalRowData = await originalRowDataPromise;
		// Add the before and after to each item
		if (beforeRangeOriginalRowData || afterRangeOriginalRowData)
			originalRowData = this.mergeChecklistsRanges(
				beforeRangeOriginalRowData,
				originalRowData,
				afterRangeOriginalRowData,
			);

		return originalRowData;
	}

	mergeChecklistsRanges(beforeData, inRangeData, afterData) {
		if (inRangeData.data)
			for (var i = 0; i < inRangeData.data.length; i++) {
				if (beforeData && beforeData.data && beforeData.data[i] && beforeData.data[i].values)
					inRangeData.data[i].beforeRange = beforeData.data[i].values;
				if (afterData && afterData.data && afterData.data[i] && afterData.data[i].values)
					inRangeData.data[i].afterRange = afterData.data[i].values;
			}
		return inRangeData;
	}

	async displaySelectedView(props = this.props, analyticsSubMenus) {
		const { selectedProjectId, setHeaderParams } = props;

		if (!selectedProjectId) return;

		this.setState({ defaultSubMenu: analyticsSubMenus });
		const filterMenuComponent = this.getFilterMenuComponent(props, this.state);
		this.reCalcHeader(Object.assign({}, filterMenuComponent, setHeaderParams));
		let originalRowData = this.state.originalRowData;
		if (!originalRowData) {
			originalRowData = await this.getChecklistReportData(props, true);
			originalRowData = this.addDuplicationsNoToTitle(originalRowData, props);
		}

		let defaultFilter;
		if (!(analyticsSubMenus && analyticsSubMenus.analytics && analyticsSubMenus.analytics.options)) return;

		if (props.getNested(['match', 'params', 'reportId'])) {
			let targetReport = analyticsSubMenus.analytics.options.filter(
				subm => subm.id == [[props.getNested(['match', 'params', 'reportId'])]],
			);
			if (targetReport && targetReport.length > 0) defaultFilter = targetReport[0];
		}

		if (!defaultFilter) defaultFilter = analyticsSubMenus.analytics.options[0];

		this.filtersHandler(defaultFilter.values, originalRowData, defaultFilter, props);

		// TODO: Put this only on some case of reload data
	}

	async handleSaveFilter(filterProps, isDelete) {
		const { selectedProjectId } = this.props;
		const { currFilterSet, selectedFilterSet, filters } = this.state;

		const newFilters = Object.assign({}, filters, currFilterSet);
		await saveMenus(
			selectedProjectId,
			newFilters,
			{
				label: selectedFilterSet.label,
				id: (filterProps || {}).id,
				num: parseInt(selectedFilterSet.num || '0'),
			},
			'qa',
			'analytics',
			null,
			isDelete,
		);
	}

	filterHandler(filtersParam, type, inOriginalRowData) {
		this.filtersHandler({ [type]: filtersParam }, inOriginalRowData);
	}

	filtersHandler(filtersParam, inOriginalRowData, filterSet, props = this.props) {
		const { checklists, setHeaderParams } = props;
		let recalculateData = false;
		let isFavChanged = Boolean(filterSet);

		let currFilterSet = this.state.currFilterSet;
		let newState = Object.assign({}, this.state);

		if (isFavChanged) {
			recalculateData = true;
			currFilterSet = filterSet.values;
		} else {
			if (
				filtersParam &&
				(filtersParam.filterBySubColumn || (!filtersParam.filterBySubColumn && currFilterSet.filterBySubColumn))
			)
				recalculateData = true;
			currFilterSet = Object.assign({}, currFilterSet, filtersParam);
		}

		if (inOriginalRowData) newState.originalRowData = inOriginalRowData;

		if (recalculateData) {
			Object.assign(newState, {
				selectedStatus: {},
				selectedRange: {},
				selectedUser: null,
				cleanSelectableUsers: Date.now(),
				selectedBadges: {
					[this.allBadgesArray[0].value]: this.allBadgesArray[0],
				},
				selectedViewType: {
					[this.allViewTypesArray[0].value]: this.allViewTypesArray[0],
				},
				selectedTrade: {},
				selectedSection: {},
				showLowerStatus: {},
			}); // TODO: in inner -> expandedColumns: null, , cleanSelectableUsers is a workaround

			let checklistItemIdFilter = {};
			Object.values(currFilterSet.columnVisibility || {}).forEach(columnFilter => {
				if (!columnFilter.subColumnVisibility) return;
				Object.entries(columnFilter.subColumnVisibility).forEach(([subColumnId, subColumnFilter]) => {
					if (!subColumnFilter.table) return;
					checklistItemIdFilter[subColumnId] = Boolean(subColumnFilter.table);
				});
			});
			currFilterSet.checklistItemIdFilter = currFilterSet.filterBySubColumn ? checklistItemIdFilter : null;
			newState.originalData = filterChecklist(newState.originalRowData, currFilterSet.checklistItemIdFilter);
		}

		if (newState.originalData != this.state.originalData)
			Object.assign(newState, {
				postsMap: this.getChecklistsIssues(newState.originalData),
			});

		const arrayToObject = (arr, limit) => {
			let fArr = arr;
			let ret = null;
			if (!Array.isArray(arr)) fArr = Object.values(arr);

			if (fArr) {
				if (limit != null && fArr.length) fArr = [...fArr].splice(0, limit);
				ret = {};
			}
			if (fArr) fArr.forEach(x => (ret[x.value] = x));
			return ret;
		};

		if (currFilterSet['status'] != null) newState.selectedStatus = arrayToObject(currFilterSet['status']);
		if (currFilterSet['badges'] != null) newState.selectedBadges = arrayToObject(currFilterSet['badges'], 1);

		if (currFilterSet['showLowerStatus'] != null) newState.showLowerStatus = currFilterSet['showLowerStatus'];

		if (currFilterSet['trade'] != null) newState.selectedTrade = arrayToObject(currFilterSet['trade']);
		if (currFilterSet['section'] != null) newState.selectedSection = arrayToObject(currFilterSet['section']);

		if (currFilterSet['viewType'] != null) newState.selectedViewType = arrayToObject(currFilterSet['viewType']);

		// If the change involve a param that need to recalculate the columns or not
		if (
			currFilterSet['trade'] ||
			currFilterSet['section'] ||
			currFilterSet['unitTypes'] ||
			currFilterSet['columnVisibility'] ||
			recalculateData
		) {
			const filterFunc = column => {
				let didFind = false;
				let originChecklist = checklists.getNested([column.checklistId, 'originChecklist']);
				if (
					currFilterSet['columnVisibility'] &&
					!currFilterSet.getNested(['columnVisibility', column.checklistId, 'table']) &&
					!currFilterSet.getNested(['columnVisibility', originChecklist, 'table'])
				)
					return false;

				if (
					currFilterSet.filterBySubColumn &&
					column.columnType === 2 &&
					!_.get(currFilterSet, [
						'columnVisibility',
						originChecklist || column.checklistId,
						'subColumnVisibility',
						column.checklistItemId,
						'table',
					])
				)
					return false;

				if (
					!newState.selectedTrade ||
					Object.values(newState.selectedTrade).length == 0 ||
					Object.keys(column.trades || {}).length == 0
				)
					didFind = true;
				else
					Object.keys(column.trades || {}).forEach(columnTradeKey => {
						if (newState.selectedTrade[columnTradeKey]) didFind = true;
					});

				return didFind;
			};

			const { columns, originalColumns, sectionsOptions } = this.createColumnComponents(
				newState.originalData,
				newState.selectedSection,
				filterFunc,
			);

			newState.originalColumns = originalColumns;
			newState.columns = columns;
			newState.sectionsOptions = sectionsOptions;
		}

		if (filterSet) newState.selectedFilterSet = filterSet;

		newState.currFilterSet = currFilterSet;
		newState.filtersSelectableObjects = {};
		newState.filtersSelectableObjects.selectedStatus = Object.values(
			newState.selectedStatus || this.state.selectedStatus || {},
		);
		newState.filtersSelectableObjects.selectedBadges = newState.selectedBadges || this.state.selectedBadges || {};
		newState.filtersSelectableObjects.selectedRange = newState.selectedRange || this.state.selectedRange || {};

		newState.filtersSelectableObjects.selectedViewType = newState.selectedViewType || this.state.selectedViewType || {};
		newState.filtersSelectableObjects.selectedTrade = Object.values(
			newState.selectedTrade || this.state.selectedTrade || {},
		);
		newState.filtersSelectableObjects.selectedSection = Object.values(
			newState.selectedSection || this.state.selectedSection || {},
		);
		newState.filtersSelectableObjects.filterVal = Object.values(newState.filterVal || this.state.filterVal || {});
		newState.filtersSelectableObjects.showLowerStatus =
			newState.showLowerStatus != null ? newState.showLowerStatus : Boolean(this.state.showLowerStatus);
		let filtersComponents = this.getFilterMenuComponent(props, newState);
		newState.adminFilterMenu = filtersComponents.adminFilterMenu;
		newState.filtersMenu = filtersComponents.filtersMenu;
		this.reCalcHeader(Object.assign({}, newState.filtersMenu, newState.filtersMenu, setHeaderParams));
		if (!inOriginalRowData) newState.sideBarOpen = false;
		this.setState(newState);
	}

	createColumnComponents(originalData, selectedSection, filterFunc) {
		const { rtl, trades } = this.props;

		if (!originalData || !originalData.columns || !originalData.columns.sort) {
			console.warn('Error: No data return');
			return {};
		}

		let sectionsOptions = {};
		let columnsObj = {};

		// Build the column by checklist
		originalData.columns
			.sort((a, b) => (a.order < b.order ? -1 : 1))
			.forEach(column => {
				if (filterFunc && !filterFunc(column)) return;

				let columnDescription = column.description;
				let columnSection = column.section;
				let columnSectionId = column.section.id;
				let columnSectionTitle = column.section.getCementoTitle();

				if (!sectionsOptions[columnSection])
					sectionsOptions[columnSection] = {
						label: columnSection,
						value: columnSection,
					};

				let subColumnsTrades = {};
				Object.values(column.subColumns || {}).forEach(subColumn => {
					if (!filterFunc || filterFunc(subColumn))
						Object.keys(subColumn.trades || {}).forEach(tradeId => {
							if (!subColumnsTrades[tradeId]) subColumnsTrades[tradeId] = true;
						});
				});

				if (!selectedSection || Object.values(selectedSection).length == 0 || selectedSection[columnSection]) {
					if (!columnsObj[columnSection]) {
						columnsObj[columnSection] = {
							original: column,
							style: {
								borderTopStyle: 'none',
								zIndex: theme.zIndexes.QCAnalyticsColumn,
								width: '100%',
								outlineOffset: -0.5,
								outline: '0.5px solid rgb(215, 215, 215)',
								position: 'sticky',
								top: 0,
							},
							Header: currColumn => (
								<div
									style={{
										borderLeftWidth: 2,
										borderRightWidth: 2,
										borderColor,
										display: 'flex',
										width: 'inherit',
										margin: 0,
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'flex-start',
										maxHeight: sectionHeight,
										height: sectionHeight,
									}}
								>
									<span
										title={currColumn.section}
										style={{
											padding: theme.margin,
											fontSize: 18,
											textAlign: 'center',
											color: theme.brandPrimary,
											fontWeight: 700,
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											textAlign: 'start',
											whiteSpace: 'nowrap',
											width: 'inherit',
										}}
									>
										{currColumn.section}
									</span>
								</div>
							),
							columns: [],
						};
					}

					let columnComponent = {
						mainColumnId: column.checklistId,
						columnType: column.columnType,
						original: column,
						order: column.order,
						trades: subColumnsTrades,
						style: { position: 'sticky', top: customTop },
						accessor: 'values.' + columnDescription + '.confirmed', // String-based value accessors!
						HeaderValue: columnDescription,
						maxWidth: 70,
						className: 'TableData',
						headerClassName: 'column header',
						subColumns: [],
						rowStyle: {
							height: cellHeight,
						},
						Header: (currSubColumn, extraProps) => (
							<TableColumnHeader
								key={'header-'.concat(currSubColumn.original ? currSubColumn.original.description : 'general')}
								marginRight={marginRight}
								trades={trades}
								currSubColumn={currSubColumn}
								rtl={rtl}
								{...extraProps}
							/>
						),
						Cell: (cell, extraProps) => (
							<QCTableCell
								key={'cell-'.concat(cell.row.id).concat(cell.column.original.description)}
								marginRight={marginRight}
								height={cellHeight}
								width={newColumnWidth}
								cell={cell}
								rtl={rtl}
								{...extraProps}
							/>
						),
					};

					[column].concat(Object.values(column.subColumns || {})).forEach(subColumn => {
						if (!filterFunc || filterFunc(subColumn))
							columnComponent.subColumns.push({
								mainColumnId: column.checklistId,
								subColumnId: subColumn.checklistItemId,
								columnType: subColumn.columnType,
								original: subColumn,
								order: subColumn.order,
								trades: subColumn.columnType == columnTypes.sub ? subColumn.trades : subColumnsTrades,
								style: {
									position: 'sticky',
									top: customTop,
									paddingTop: 0.5,
									width: 125,
								},
								accessor: 'values.' + subColumn.description + '.confirmed', // String-based value accessors!
								HeaderValue: subColumn.description,
								maxWidth: 70,
								className: 'TableData',
								headerClassName: 'column header',
								rowStyle: {
									height: cellHeight,
								},
								Header: (currSubColumn, extraProps) => (
									<TableColumnHeader
										key={'inner-header-'.concat(
											currSubColumn.original ? currSubColumn.original.description : 'general',
										)}
										innerItem={true}
										marginRight={marginRight}
										trades={trades}
										currSubColumn={currSubColumn}
										rtl={rtl}
										{...extraProps}
									/>
								),
								Cell: (cell, extraProps) => (
									<QCTableCell
										key={'inner-cell-'.concat(cell.row.id).concat(cell.column.original.description)}
										marginRight={marginRight}
										height={cellHeight}
										width={newColumnWidth}
										cell={cell}
										rtl={rtl}
										{...extraProps}
									/>
								),
							});
					});

					columnsObj[columnSection].columns.push(columnComponent);
				}
			});

		let columns = [];
		Object.values(columnsObj).forEach(x => {
			if (x.columns) x.columns.sort((a, b) => a.order - b.order);
			columns.push(x);
		});

		columns.sort((a, b) => a.columns[0].order - b.columns[0].order);
		let originalColumns = originalData.columns;

		return {
			columns,
			sectionsOptions: Object.values(sectionsOptions),
			originalColumns,
		};
	}

	firstColumnRenderFunc(cell, props) {
		const { row } = cell;
		let cellData = cell;
		if (!cellData.title) {
			cellData.title = { displayValue: cell.row.title, displayParams: {} };
		}
		return (
			<TableCollapsibleCell
				cellHeight={props.isFirstRow ? cellHeight - 1 : cellHeight}
				key={'firstColumn-'.concat(row.id)}
				id={row.id}
				locationId={row.id}
				type={row.type}
				title={row.title}
				cell={cellData}
				rowsLevel={3}
				dismissClickHandler={row.type === 'unit' ? true : false}
			/>
		);
	}

	getSideCardObject(cell, isAggregatedCell, inSelectedCell) {
		const { checklists, checklistItems, selectedProjectId } = this.props;
		const { selectedBadges, selectedRange, selectedUser } = this.state;
		// Get the posts on that checklistInstances
		let retPosts = [];
		let selectedCellValues = cell.row.values[cell.column.mainColumnId];
		if (selectedBadges && selectedBadges.docs)
			retPosts = retPosts.concat((selectedCellValues && selectedCellValues.issuesDetails) || []);
		if (selectedBadges && selectedBadges.issues)
			retPosts = retPosts.concat((selectedCellValues && selectedCellValues.docsDetails) || []);

		let selectedCell = Object.assign({}, inSelectedCell);
		selectedCell.posts = retPosts;

		let locationsDataType = cell.row.type;
		selectedCell.locationsData = {
			buildingId: cell.row.buildingId,
			floorId: cell.row.floorId,
			unitId: cell.row.unitId,
			type: locationsDataType,
		};

		selectedCell.isAggregatedCell = isAggregatedCell;

		if (cell.column.columnType == columnTypes.main) selectedCell.checklists = { [cell.column.mainColumnId]: true };
		else if (cell.column.columnType == columnTypes.sub) {
			selectedCell.checklists = { [cell.column.mainColumnId]: true };
			selectedCell.checklistItems = { [cell.column.subColumnId]: true };
		}

		let sideCardObject = null;

		let alert =
			(selectedRange || {}).startDate || (selectedRange || {}).endDate ? analyticsMessages.noRangeSyncAlert : null;
		if (!alert) alert = selectedUser ? analyticsMessages.noUserSyncAlert : null;

		if (selectedCell) {
			if (selectedCell.isAggregatedCell) sideCardObject = { type: 'checklists', props: { selectedCell, alert } };
			else {
				let chItemId = selectedCell.isAggregatedCell ? null : Object.keys(selectedCell.checklistItems || {})[0];
				let checklistId = selectedCell.checklists ? Object.keys(selectedCell.checklists || {})[0] : null;
				let checklist = checklistId ? checklists.getNested([checklistId], {}) : null;
				let checklistItem = null;
				let locationsData = selectedCell.locationsData;
				let locationId = locationsData[locationsData.type + 'Id'];
				if (chItemId) checklistItem = checklistItems.getNested([chItemId], {});

				sideCardObject = {
					type: 'checklistItem',
					alert,
					props: { projectId: selectedProjectId, checklist, checklistItem, locationId },
				};
			}
		}

		return sideCardObject;
	}

	reCalcHeader(props) {
		const { setHeaderParams, adminFilterMenu, filtersMenu } = props;

		let headerComponent = (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flex: 1,
					flexDirection: 'column',
					backgroundColor: 'white',
				}}
			>
				<div
					style={{
						marginRight: 3,
						marginLeft: 3,
						display: 'flex',
						justifyContent: adminFilterMenu ? 'space-between' : 'flex-end',
						height: 48,
					}}
				>
					{adminFilterMenu}
					{filtersMenu}
				</div>
			</div>
		);

		if (setHeaderParams)
			setHeaderParams({
				headerComponent,
				sideBarParams: { open: false, outerClickClose: true },
			});
	}

	getChecklistsIssues(originalData) {
		const { checklistInstancesArr, selectedProjectId } = this.props;

		// Build checklist detatils by instance
		let instancesMap = {};

		if (!checklistInstancesArr || !originalData || !originalData.columns) return {};

		if (!originalData.columns.forEach) {
			return {};
		}
		// Build the checklistItem and checklists relation based on the result from the server. for user who do not have the checklists localy due to lack of permissions
		let checklistItems = {};

		originalData.columns.forEach(column => {
			Object.values(column.subColumns).forEach(subColumn => {
				checklistItems[subColumn.checklistItemId] = {
					id: subColumn.checklistItemId,
					checklistIds: {
						[subColumn.checklistId]: { id: subColumn.checklistId },
					},
				};
			});
		});

		checklistInstancesArr.forEach(currChecklist => {
			let checklistIds = null;
			if (checklistItems[currChecklist.checklistItemId]) {
				checklistIds = currChecklist.checklistId
					? {
							[currChecklist.checklistId]: {
								id: currChecklist.checklistId,
							},
					  }
					: (checklistItems[currChecklist.checklistItemId] || {}).checklistIds;
			}

			instancesMap[currChecklist.id] = {
				itemId: currChecklist.checklistItemId,
				checklistIds,
			};
		});

		let allClosedIssuesOfChecklist = this.lokiPosts.cementoFind({
			projectId: selectedProjectId,
			checklistItemInstance: { $and: [{ $exists: true }, { $ne: null }] },
			issueState: { $in: [100] },
		});
		let allOpenIssuesOfChecklist = this.lokiPosts.cementoFind({
			projectId: selectedProjectId,
			checklistItemInstance: { $and: [{ $exists: true }, { $ne: null }] },
			issueState: { $in: [300, 200] },
		});
		let allDocsOfChecklist = this.lokiPosts.cementoFind({
			projectId: selectedProjectId,
			checklistItemInstance: { $and: [{ $exists: true }, { $ne: null }] },
			issueState: { $exists: false },
		});

		const getItemsMap = (itemsArray, instancesMap) => {
			let ret = {};

			itemsArray.forEach(currPost => {
				if (currPost.checklistItemInstance == null) {
					debugger;
				}
				if (!instancesMap[currPost.checklistItemInstance.id]) return null;

				let currChecklistId = instancesMap[currPost.checklistItemInstance.id].checklistIds;
				if (!currChecklistId) return;

				var ids = Object.keys(currChecklistId);

				let currChecklistItemId = instancesMap[currPost.checklistItemInstance.id].itemId;
				if (currChecklistItemId) ids.push(currChecklistItemId);

				ids.forEach((currId, index) => {
					let currChecklistId = ids[0];
					let currChecklistItemId = index ? currId : 'all';

					if (!ret[currChecklistId]) ret[currChecklistId] = {};

					if (!ret[currChecklistId][currChecklistItemId]) ret[currChecklistId][currChecklistItemId] = {};

					if (currPost.location && currPost.location.building && currPost.location.building.id) {
						if (!ret[currChecklistId][currChecklistItemId][currPost.location.building.id])
							ret[currChecklistId][currChecklistItemId][currPost.location.building.id] = {};
						ret[currChecklistId][currChecklistItemId][currPost.location.building.id][currPost.id] = currPost;
					}

					if (currPost.location && currPost.location.floor && currPost.location.floor.id) {
						if (!ret[currChecklistId][currChecklistItemId][currPost.location.floor.id])
							ret[currChecklistId][currChecklistItemId][currPost.location.floor.id] = {};
						ret[currChecklistId][currChecklistItemId][currPost.location.floor.id][currPost.id] = currPost;
					}

					if (currPost.location && currPost.location.unit && currPost.location.unit.id) {
						if (!ret[currChecklistId][currChecklistItemId][currPost.location.unit.id])
							ret[currChecklistId][currChecklistItemId][currPost.location.unit.id] = {};
						ret[currChecklistId][currChecklistItemId][currPost.location.unit.id][currPost.id] = currPost;
					}
				});
			});

			return ret;
		};

		let closedIssues = getItemsMap(allClosedIssuesOfChecklist, instancesMap);
		let issues = getItemsMap(allOpenIssuesOfChecklist, instancesMap);
		let docs = getItemsMap(allDocsOfChecklist, instancesMap);

		return { issues, docs, closedIssues };
	}

	handleUpdateSelectedFilterSet(selectedFilterSet) {
		let stateChanges = { selectedFilterSet };
		Object.assign(stateChanges, this.getFilterMenuComponent(this.props, Object.assign({}, this.state, stateChanges)));
		this.reCalcHeader(Object.assign({}, stateChanges, this.props.setHeaderParams));
		this.setState(stateChanges);
	}

	getFilterMenuComponent(props = this.props, nextState) {
		const { intl, classes, rtl, viewer, setHeaderParams, match, projectMembers, configurationMode } = props;
		const {
			originalData,
			originalColumns,
			filtersSelectableObjects,
			currFilterSet,
			selectedFilterSet,
			sectionsOptions,
			cleanSelectableUsers,
			selectedRange,
		} = nextState;

		let adminFilterMenu = null;
		let filtersMenu = (
			<div style={{ display: 'flex', marginLeft: theme.margin }}>
				<div style={filterStyle}>
					<DateRangePicker
						onChange={selectedRange => {
							const currSelectedRange = this.state.selectedRange || {};
							const nextSelectedRange = selectedRange || {};
							const nextStartDate = nextSelectedRange.start ? new Date(nextSelectedRange.start) : null;
							const nextEndDate = nextSelectedRange.end ? new Date(nextSelectedRange.end) : null;
							if (currSelectedRange.startDate != nextStartDate && currSelectedRange.endDate != nextEndDate)
								this.setState({ selectedRange: { startDate: nextStartDate, endDate: nextEndDate } }, () =>
									this.refreshTableData(props),
								);
						}}
						placeholder={intl.formatMessage(analyticsMessages.viewType.filterByDate)}
						inputStyle={{ minWidth: filterCompWidth }}
					/>
				</div>
				<div
					style={{
						...filterStyle,
						cursor: 'pointer',
						borderColor: '#dee0e5',
						color: '#838383',
						borderRadius: 4,
						marginTop: 6,
						marginBottom: 6,
						marginRight: 10,
						borderStyle: 'solid',
						borderWidth: 1,
						width: 150,
					}}
				>
					<SelectableUsers
						clean={cleanSelectableUsers}
						isClearable={true}
						placeholder={intl.formatMessage(analyticsMessages.viewType.filterByUser)}
						onChange={selectedUser => {
							this.setState({ selectedUser }, () => this.refreshTableData(props));
						}}
						users={projectMembers}
					/>
				</div>

				<div style={filterStyle}>
					<TradesSelector
						minWidth={filterCompWidth}
						options={(originalData || {}).entireTradesIds}
						selectedTrades={filtersSelectableObjects.selectedTrade}
						onChange={section => this.filterHandler(section, 'trade')}
					/>
				</div>

				<div style={filterStyle}>
					<Select
						rtl={rtl}
						isMulti={false}
						onChange={value => this.filterHandler([{ value: value.value }], 'viewType')}
						styles={shortSelectStyles}
						options={this.allViewTypesArray}
						placeholder={intl.formatMessage(analyticsMessages.viewType.viewType)}
						value={filtersSelectableObjects.selectedViewType ? filtersSelectableObjects.selectedViewType[0] : null}
					/>
				</div>

				<div style={filterStyle}>
					<Select
						rtl={rtl}
						isMulti={false}
						onChange={value => this.filterHandler([{ value: value.value }], 'badges')}
						styles={shortSelectStyles}
						options={this.allBadgesArray}
						placeholder={intl.formatMessage(analyticsMessages.badges)}
						value={filtersSelectableObjects.selectedBadges ? filtersSelectableObjects.selectedBadges[0] : null}
					/>
				</div>
			</div>
		);

		if (configurationMode == 1)
			adminFilterMenu = (
				<div style={{ display: 'flex' }}>
					<div style={filterStyle}>
						<Select
							rtl={rtl}
							isMulti={false}
							onChange={value =>
								this.filterHandler(
									[
										value,
										{
											label: intl.formatMessage(analyticsMessages.statusLabels.rejected),
											value: 'rejected',
										},
									],
									'status',
								)
							}
							styles={theme.selectStyles}
							options={this.allStatusArray}
							value={filtersSelectableObjects.selectedStatus ? filtersSelectableObjects.selectedStatus[0] : null}
						/>
						<span style={{ alignSelf: 'center' }}>סטטוס</span>
					</div>

					<div style={{ ...filterStyle, alignItems: 'center' }}>
						<span>הצג סטטוסים חלשים</span>
						<Checkbox
							onChange={val => {
								this.filterHandler(!filtersSelectableObjects.showLowerStatus, 'showLowerStatus');
							}}
							checked={Boolean(filtersSelectableObjects.showLowerStatus)}
							checkedIcon={<Check className={classes.checkedIcon} />}
							icon={<Check className={classes.uncheckedIcon} />}
							classes={{ checked: classes.checked }}
						/>
					</div>

					<div style={filterStyle}>
						<Select
							rtl={rtl}
							isMulti={true}
							isSearchable={true}
							onChange={section => this.filterHandler(section, 'section')}
							styles={theme.selectStyles}
							options={sectionsOptions}
							value={filtersSelectableObjects.selectedSection}
						/>
						<span style={{ alignSelf: 'center' }}>שלב</span>
					</div>

					<TableFilters
						filters={currFilterSet}
						selectedFilterSet={selectedFilterSet}
						updateSelectedFilterSet={this.handleUpdateSelectedFilterSet}
						originalColumns={originalColumns}
						handleSaveFilter={this.handleSaveFilter}
						updateFilters={newFilters => {
							this.setState({ filters: newFilters });
							this.filtersHandler(newFilters);
						}}
					/>
				</div>
			);

		if (this.state.filtersMenu !== filtersMenu || this.state.adminFilterMenu !== adminFilterMenu)
			this.reCalcHeader({ filtersMenu, adminFilterMenu, setHeaderParams });

		return { filtersMenu, adminFilterMenu };
	}

	render() {
		const { match, isDataLoading, loadingProgress } = this.props;
		const { originalData, selectedFilterSet, columns, defaultSubMenu } = this.state;

		if (isDataLoading || !originalData) return <LoadingIndicator loadingProgress={loadingProgress}/>
		
		return (
			<QCReportContext.Provider value={this.state}>
				<TableWrapper
					cellHeight={cellHeight}
					defaultSubMenu={defaultSubMenu} // Optional, menu to display on the side
					filtersHandler={this.filtersHandler} // Optional - Mandatory if defaultSubMenu supplied
					selectedFilterSet={selectedFilterSet} // Optional, recommended if defaultSubMenu supplied
					match={match}
					expandableTable={true}
					originalRows={originalData.rows} // Mandatory
					columns={columns} // Mandatory
					contentType={'qa'} // Mandatory
					getSideCardObject={this.getSideCardObject} // Optional
					firstColumnRenderFunc={this.firstColumnRenderFunc} // Mandatory
				/>
			</QCReportContext.Provider>
		);
	}
}

const styles = {
	textCenter: {
		textAlign: 'center',
		alignItems: 'center',
		alignContent: 'center',
		justify: 'center',
	},
	checkedIcon: {
		width: '20px',
		height: '20px',
		border: '1px solid rgba(0, 0, 0, .54)',
		borderRadius: '3px',
	},
	uncheckedIcon: {
		width: '0px',
		height: '0px',
		padding: '9px',
		border: '1px solid rgba(0, 0, 0, .54)',
		borderRadius: '3px',
	},
};

QCAnalytics = injectIntl(QCAnalytics);
QCAnalytics = withStyles(styles)(QCAnalytics);
QCAnalytics = withRouterHOC(QCAnalytics);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  withLoading((props) => {
	const { selectedProjectId } = props;

	const subjectPaths = [
		['projects', selectedProjectId, SUBJECTS.STAGES],
		['projects', selectedProjectId, SUBJECTS.CHECKLISTS],
		['projects', selectedProjectId, SUBJECTS.CHECKLIST_ITEMS],
		['projects', selectedProjectId, SUBJECTS.POSTS],
		['projects', selectedProjectId, `${SUBJECTS.CHECKLIST_ITEM_INSTANCES}/itemInstances`],
		['projects', selectedProjectId, `${SUBJECTS.PROPERTIES_TYPES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.CHECKLIST_ITEMS]}`],
		['projects', selectedProjectId, `${SUBJECTS.PROPERTIES_MAPPINGS}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.CHECKLIST_ITEMS]}`],
		['projects', selectedProjectId, `${SUBJECTS.PROPERTIES_INSTANCES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.CHECKLIST_ITEMS]}`],
		['projects', selectedProjectId, `${SUBJECTS.PROPERTIES_TYPES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.LOCATIONS]}`],
		['projects', selectedProjectId, `${SUBJECTS.PROPERTIES_MAPPINGS}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.LOCATIONS]}`],
		['projects', selectedProjectId, `${SUBJECTS.PROPERTIES_INSTANCES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.LOCATIONS]}`],
	]

	return subjectPaths
}),
  connect(
    (state) => ({
	configurationMode: state.app.configurationMode,
      rtl: state.app.rtl,
      trades: state.trades.map,
    }),
    { track}
  ),
  withChecklistItemInstances
);

export default enhance(QCAnalytics);
const filterCompWidth = 140;

const shortSelectStyles = {
	...theme.selectStyles,
	container: styles => ({
		...theme.selectStyles.container(styles),
		width: filterCompWidth,
		marginRight: 0,
	}),
};
const filterStyle = {
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'flex-start',
	paddingRight: theme.padding,
	alignItems: 'center',
};
