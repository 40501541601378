import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import _ from 'lodash';

// @material-ui/icons
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { PrintOutlined } from '@material-ui/icons';

import selectedTableIcon from '../../assets/img/header/table_view_selected.png';
import tableIcon from '../../assets/img/header/table_view.png';
import selectedCardIcon from '../../assets/img/header/cards_view_selected.png';
import cardIcon from '../../assets/img/header/cards_view.png';

// core components
import TableWrapper from '../Reports/TableWrapper';
import TableFilters from '../Reports/TableFilters';
import FilterMenuHOC from '../../components/CementoComponents/FilterMenu';

import TextFilter from '../Posts/TextFilter';
import Text from '../../components/CementoComponents/Text';
import HoverWrapper from '../../components/CementoComponents/HoverWrapper';

// actions
import { onDraftModeChange } from '../../../common/ui/actions';
import { endEmployeesListener } from '../../../common/employees/funcs';
import { endEquipmentListener } from '../../../common/equipment/funcs';
import { endFormsListener, upsertForm } from '../../../common/forms/funcs';
import {
	startToast,
	startLoading,
	hideLoading,
} from '../../../common/app/actions';
import { saveMenus } from '../../menus/funcs';
import { uploadPropertiesInstances } from '../../../common/propertiesInstances/actions';
import { exportFormPDF } from '../../../common/pdf/actions';
import { track } from '../../../common/lib/reporting/actions';

import theme from '../../assets/css/theme';

import { lokiInstance } from '../../../common/configureMiddleware';

import 'react-table/react-table.css';
import '../../assets/css/table.css';

// funcs
import { getPropertiesInfo, getMembers, getCompanies, getLocationsObjectsForTable } from '../Reports/funcs';
import {
	getTableWrapperProps,
	getPropertiesTableDataObject,
	ORIGINAL_VALUE_KEY_TERM,
} from '../Reports/tableWrapperHelpers';
import {
	getMembersProjectsTableDataObj,
	getSubcontractorProjectsTableDataObj,
	getLocationsGroupsTableDataObj,
	getEmployeesPresenceTableDataObj,
} from '../Reports/tableWrapperHelpers';
import { populateObject, getPropertiesTypesOfPostsInfo, parsePropInstanceFirebasePath, fetchPropertyInstanceById } from '../../../common/propertiesInstances/funcs';
import { convertCementoML, deepEqual, isEmptyValue, localTSToUTC, onError, safeFormatMessage } from '../../../common/app/funcs';
import {
	validatePropType,
	fillBusinessType,
	getPostGroupId,
	getAllMappedProperties,
} from '../../../common/propertiesTypes/funcs';
import { getFilteredRowsMap, getObjectsTitlesMap } from './funcs';
import { fetchQuasiStatics } from '../../../common/quasiStatics/funcs';
import { getAllLocationTitlesMap, setFlatLocationsTitlesMap } from '../Locations/funcs';
import { preProcessData, getSortFunc } from '../../../common/analytics/funcs';
import EditModeToggle from '../../components/CementoComponents/EditModeToggle';
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ImageCarousel from '../../components/CementoComponents/ImageCarousel';

// messages
import usersMessages from '../../../common/users/usersMessages';
import pdfMessages from '../../../common/app/pdfMessages';
import safetyMessages from '../../../common/safety/safetyMessages';
import propertiesMessages from '../../../common/propertiesTypes/propertiesMessages';
import siteControlMessages from '../../../common/siteControl/siteControlMessages';
import analyticsMessages from '../../../common/analytics/analyticsMessages';
import unitsMessages from '../../../common/units/unitsMessages';
import buildingsMessages from '../../../common/buildings/buildingsMessages';
import floorsMessages from '../../../common/floors/floorsMessages';
import newProjectMessages from '../../../common/projects/newProjectMessages';
import systemMessages from '../../../common/app/systemMessages';
import * as utils from '../../../common/lib/utils/utils';
import reportsMessages from '../../../common/reports/reportsMessages';
import { QCReportContext } from '../../../common/analytics/contexts';

import {
	getFormStatusParams,
	formStatusesArray,
	getStatusMessage,
	REPORT_STATE_AWAITING_APPROVAL,
} from '../../../common/forms/formsStatusStates';
import Calendar from '../../components/CementoComponents/Calendar/index';
import { getRoundedDate } from '../../../common/lib/utils/utils';
import Modal from '../../components/CementoComponents/Modal';
import { CardHeader } from '../../components';

import {
	getEmployeesPresence,
	startMonitorListener,
	getEmployeesTablesMetadata,
} from '../../../common/siteControl/funcs';
import { getCameras } from '../Cameras/funcs';
import StandardInput from '../../components/CementoComponents/StandardInput';
import { SUPPORTED_FILTER_TYPES } from '../../components/CementoComponents/FilterMenu/FilterMenuComponent';
import { POSTS_VIEW_TYPES, FILTER_URL_KEY, SUBJECTS_WITH__DISABLED_GROUPING, FILTER_MENU_PATH_DELIMETER } from '../../app/constants';
import moment from 'moment-timezone';
import { safeToJS } from '../../../common/permissions/funcs';
import MonthPicker from '../../../common/app/components/MonthPicker';
import Select from 'react-select';
import CamerasMonitor from '../../components/SiteControl/CamerasMonitor';
import { POSTS_TYPES } from '../../../common/app/constants';
import PostsAnalytics from './PostsAnalytics';
import issuesMessages from '../../../common/issues/issuesMessages';
import postsMessages from '../../../common/posts/newPostMessages';
import { ISSUE_STATUSES_ARRAY } from '../../../common/issues/issueStates';
import AddNewButton from '../../components/CementoComponents/AddNewButton';
import { withSavedMenus } from '../../components/Header/useSavedMenus';
import checklistsMessages from '../../../common/checklists/checklistsMessages';
import withRouterHOC from '../../components/Router/util/withRouterHOC';
import { CompaniesHOC } from '../../../common/companies/hooks/useCompanies';
import { getNewId } from '../../../common/lib/api';
import ViewSelector from '../../components/ViewSelector/ViewSelector';
import { FORM_TYPES, SUBJECTS } from '../../../common/dataManager/subjects';
import DataManagerInstance from '../../../common/dataManager/DataManager';
import { withLoading } from '../../../common/hooks/useLoading';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';


const injectMembersToPost = (rawPosts, members) => {
	return rawPosts.reduce((acc, post) => {
		const ownerMember = _.pick(members?.[post.owner?.id], ['id', 'displayName', 'companyId']);
		const assignToMember = _.pick(members?.[post.assignTo?.id], ['id', 'displayName', 'companyId']);
		const owner = _.merge(ownerMember, post.owner);
		const assignTo = _.merge(assignToMember, post.assignTo);
		acc.push({
			trade: { id: DEFAULT_TRADE_ID },
			...post,
			assignTo,
			owner,
		});
		return acc;
	}, []);
};

// Const
const cellHeight = 40;
const customWidth = 171;
const largeWidth = 22;
const smallWidth = 13;
const defaultSubMenuType = 'analytics';
const DEFAULT_TRADE_ID = '1300'

const CALENDAR_HEADER_HEIGHT = 48;

const SAFE_DELETE_KEYWORD = 'SAFE_DELETE';

const WITH_IDS = 'withIds';
const WITH_FILES = 'withFiles';
const WITH_FILE_URLS = 'withFileUrls';

const messagesTypesMap = {
	safety: safetyMessages,
	siteControl: siteControlMessages,
	properties: propertiesMessages,
	forms: reportsMessages,
	qa: checklistsMessages,
	issues: postsMessages,
	records: postsMessages,
};

const PAGE_CONFIGURATIONS = {
	subjectTypes: {
		forms: {
			shouldSetCounterRows: false,
		},
		employees: {
			shouldSetCounterRows: true,
		},
		equipment: {
			shouldSetCounterRows: true,
		},
		locations: {
			shouldSetCounterRows: true,
		},
	},
};

const CONTENT_TO_FEATURE_MAP = {
	safety: 'safety',
	info: 'spec',
	forms: 'dailyReport',
	siteControl: 'siteControl',
};

class PropertyAnalytics extends React.Component {
	constructor(props) {
		super(props);

		this.handleUpdateSelectedFilterSet = this.handleUpdateSelectedFilterSet.bind(this);
		this.handleUpdateFilters = this.handleUpdateFilters.bind(this);
		this.setComponentData = this.setComponentData.bind(this);
		this.calculateTableDataProps = this.calculateTableDataProps.bind(this);
		this.debouncedCalculateTableDataProps = this.debouncedCalculateTableDataProps.bind(this)
		this.displayMembersView = this.displayMembersView.bind(this);
		this.displayLocationGroupsView = this.displayLocationGroupsView.bind(this);
		this.propertiesToTableProps = this.propertiesToTableProps.bind(this);
		this.filtersHandler = this.filtersHandler.bind(this);
		this.getBusinessTypesColumn = this.getBusinessTypesColumn.bind(this);
		this.buildTradesList = this.buildTradesList.bind(this);
		this.handleCardChangeStatus = this.handleCardChangeStatus.bind(this);
		this.handleObjectCreate = this.handleObjectCreate.bind(this);
		this.handleObjectCreateCancel = this.handleObjectCreateCancel.bind(this);
		this.lokiListener = this.lokiListener.bind(this);
		this.handleSaveFilter = this.handleSaveFilter.bind(this);
		this.handleObjectDelete = this.handleObjectDelete.bind(this);
		this.getFilterMenuComponent = this.getFilterMenuComponent.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleFilterClear = this.handleFilterClear.bind(this);
		this.prepareDataForExport = this.prepareDataForExport.bind(this);
		this.prepareLocationsGroupDataForExport = this.prepareLocationsGroupDataForExport.bind(this);
		this.getPopulatedObjects = this.getPopulatedObjects.bind(this);
		this.onExcelImport = this.onExcelImport.bind(this);
		this.getUpdatedPropertiesFromExcel = this.getUpdatedPropertiesFromExcel.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.setEditMode = this.setEditMode.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleConfirmCancel = this.handleConfirmCancel.bind(this);
		this.handleConfirmSave = this.handleConfirmSave.bind(this);
		this.onSetHasModifications = this.onSetHasModifications.bind(this);
		this.onEditModeOff = this.onEditModeOff.bind(this);
		this.onLocalInstancesChange = this.onLocalInstancesChange.bind(this);
		this.exportRegistrationForm = this.exportRegistrationForm.bind(this);
		this.closeImageCarousel = this.closeImageCarousel.bind(this);
		this.onMemberUpdate = this.onMemberUpdate.bind(this);
		this.onCompanyUpdate = this.onCompanyUpdate.bind(this);
		this.onGroupUpdate = this.onGroupUpdate.bind(this);
		this.reCalcHeader = this.reCalcHeader.bind(this);
		this.displayEmployeesPresenceView = this.displayEmployeesPresenceView.bind(this);
		this.reCalcEmployeesPresence = this.reCalcEmployeesPresence.bind(this);
		this.handleGetEmployeesPresence = this.handleGetEmployeesPresence.bind(this);
		this.handleMonthPicker = this.handleMonthPicker.bind(this);
		this.onMonitorUpdated = this.onMonitorUpdated.bind(this);
		this.getUrlParams = this.getUrlParams.bind(this);

		const defaultDateRange = {
			startTS: moment().startOf('month').valueOf(),
			endTS: moment().endOf('month').valueOf(),
		};
		this.selectedRange = defaultDateRange;

		this.state = {
			reportId: this.props.getNested(['match', 'params', 'reportId']),
			isEditMode: false,
			hasModifications: false,
			cardIsDisabled: true,
			displayPdf: false,
			entireTradesIds: {},
			formType: 'dailyReport',
			formTemplateId: '-dailyReportForm',
			hasUnSavedChanges: false,
			viewType: POSTS_VIEW_TYPES.TILES,
			isPostRelated: false,
		};
	}

	getUrlParams = () => {
		const { location } = this.props;
		let urlParams = {};
		let urlQueryParams = new URLSearchParams(location.search);
		const isExpiredFilter = urlQueryParams.get('isExpired');
		const isActiveFilter = urlQueryParams.get('isActive');
		if (Boolean(isExpiredFilter) || Boolean(isActiveFilter)) {
			_.set(urlParams, ['activeOnly'], Boolean(isActiveFilter));
			_.set(urlParams, ['filterExpiration'], Boolean(isExpiredFilter));
		}

		if (urlQueryParams.get('itemType')) {
			_.set(urlParams, ['itemType'], urlQueryParams.get('itemType'));
		}

		return urlParams;
	};

	setHasUnSavedChanges = value => {
		this.setState({ hasUnSavedChanges: value });
	};

	hasUnSavedChanges = () => {
		return this.state.hasUnSavedChanges;
	};

	componentWillMount() {
		const { subjectType } = this.state;
		const { contentType, selectedProjectId: projectId } = this.props;
		this.lokiPropertyInstances = lokiInstance.getCollection('propertyInstances');
		this.lokiPropertyInstances.cementoOn('lokiInstancesObjectAnalytics', this.lokiListener);

		const isSiteControlMonitorListenerAlreadySet = Boolean(this.removeSiteControlMonitorListener);
		if (contentType === 'siteControl' && !isSiteControlMonitorListenerAlreadySet) {
			this.removeSiteControlMonitorListener = startMonitorListener({ projectId, callback: this.onMonitorUpdated });
		}

		this.setComponentData({ firstMount: true }, this.props, subjectType);
	}

	componentDidMount() {
		const { track, selectedProjectId, contentType } = this.props;
		track('enterPropertyAnalyticsPage', { projectId: selectedProjectId, contentType })
		this._isMounted = true;
	}

	async onMonitorUpdated() {
		this.shouldForceEmployeePresenceRefresh = true;
		this.shouldSkipLoadingOnEmployeePresenceFetching = true;
		this.debouncedCalculateTableDataProps(this.props, this.state,);
	}

	componentWillUnmount() {
		const { selectedProjectId } = this.props;
		const { formType, subjectType } = this.state;

		this._isMounted = false;

		this.unloadSubjectType(selectedProjectId, subjectType, formType);
		if (this.lokiPropertyInstances) this.lokiPropertyInstances.cementoOff('lokiInstancesObjectAnalytics');
		if (this.removeSiteControlMonitorListener) this.removeSiteControlMonitorListener();
	}

	componentWillReceiveProps(nextProps) {
		const { subjectType } = this.state;

		this.setComponentData(this.props, nextProps, subjectType);
	}

	shouldComponentUpdate(nextProps, nextState) {
		const { subjectType } = nextState;
		const prevSubjectType = this.state.subjectType === 'locationsGroupsManagement' ? 'locations' : this.state.subjectType;
		const nextSubjectType = subjectType === 'locationsGroupsManagement' ? 'locations' : subjectType;

		const shouldComponentUpdate =
			this.props.isValDiff(nextProps, ['loadingProgress']) ||
			this.props.isValDiff(nextProps, ['isDataLoading']) ||
			prevSubjectType !== nextSubjectType ||
			this.props.isValDiff(nextProps, ['selectedProjectId']) ||
			this.props.isValDiff(nextProps, ['viewer']) ||
			this.props.isValDiff(nextProps, ['configurations']) ||
			this.props.isValDiff(nextProps, ['buildings']) ||
			this.props.isValDiff(nextProps, ['location', 'search']) ||
			this.props.isValDiff(nextProps, ['configurationMode']) ||
			!deepEqual(_.omit(this.state, ['filtersMenu']), _.omit(nextState, ['filtersMenu']))

		return shouldComponentUpdate;
	}

	onTableDataUpdate = AwesomeDebouncePromise(async (props, state, skipSetState = false,) => {
		const mergedState = _.assign({}, state, this.getPopulatedObjects(props, state, true),);
		const calculateTableDataPropsFunc = (this._isMounted && !skipSetState)
			? this.debouncedCalculateTableDataProps
			: this.calculateTableDataProps;
		
		const calculateTableDataProps = await calculateTableDataPropsFunc(props, mergedState,);
		return calculateTableDataProps;
	}, 500);

	componentDidUpdate(prevProps, prevState) {
		const {
			subjectType,
			filters,
			updatedPropertiesMap,
			hasModifications,
			isEditMode,
			originalColumns,
			originalSections,
			selectedFilterSet,
			employeesPresenceData,
			viewType,
			allColumns,
			originalData,
		} = this.state;
		let newStateChanges = {};
		if (
			prevState.hasModifications !== hasModifications ||
			prevState.isEditMode !== isEditMode ||
			prevState.filters != filters ||
			prevState.selectedFilterSet != selectedFilterSet ||
			prevState.subjectType != subjectType ||
			prevState.originalSections != originalSections ||
			prevProps.menus != this.props.menus ||
			prevState.originalColumns != originalColumns ||
			prevState.viewType != viewType ||
			prevProps.isValDiff(this.props, ['isDataReady']) || 
			prevProps.isValDiff(this.props, ['selectedFav']) ||
			prevProps.isValDiff(this.props, ['configurationMode']) ||
			_.get(prevState, ['employeesPresenceData', 'monitor']) != _.get(employeesPresenceData, ['monitor'])
		) {
			let filtersMenu = this.getFilterMenuComponent(this.props, this.state);
			this.reCalcHeader(filtersMenu);
			newStateChanges.filtersMenu = filtersMenu;
		}

		if (!subjectType) {
			this.setState(newStateChanges);
			return;
		}

		if (
			!_.isEqual(prevState.updatedPropertiesMap, updatedPropertiesMap) ||
			(prevState.subjectType == subjectType &&
				(!_.isEqual(prevState.filters?.columnVisibility, filters?.columnVisibility) ||
					!_.isEqual(prevState.filters?.textFilter, filters?.textFilter)))
		) {
			this.onTableDataUpdate(this.props, _.assign({}, this.state, newStateChanges));
		}

		if (prevState.allColumns !== allColumns || prevState.originalData?.rows !== originalData?.rows) {
			_.assign(newStateChanges, { entireTradesIds: this.buildTradesList(allColumns, originalData?.rows || []) });
		}

		const shouldCalcRowsArray = !this.state.rowsArray && this.props.isDataReady
		
		if (!_.isEqual(_.omit(prevState.filters, ['columnVisibility']), _.omit(filters, ['columnVisibility'])) || shouldCalcRowsArray) {
			_.assign(newStateChanges, this.filtersHandler(filters, this.state));
		}

		if (!_.isEmpty(newStateChanges)) {
			this.setState(newStateChanges);
		}
	}

	lokiListener(collectionName, immediate) {
		const { subjectType } = this.state;
		if (!subjectType) return;
		console.log({
			collectionName,
			immediate
		})
		if (collectionName == 'propertyInstances' || collectionName == subjectType || immediate){
			this.onTableDataUpdate(this.props, this.state,);
		}
	}

	loadSubjectType = AwesomeDebouncePromise(async (nextProps, nextState, subjectType) => {
		const { selectedProjectId } = nextProps;

		if (!(selectedProjectId && subjectType)) return;

		this.connectToSubjects(nextProps, nextState, subjectType)

		let showRowsWithEmptyValue = false;

		let stateChanges = { showRowsWithEmptyValue };

		if (['employees', 'equipment', 'forms'].includes(subjectType)) {
			this.lokiObjectAnalytics = lokiInstance.getCollection(subjectType);
			this.lokiObjectAnalytics.cementoOn('lokiObjectAnalytics', this.lokiListener);

			showRowsWithEmptyValue = true;
		}

		stateChanges.showRowsWithEmptyValue = showRowsWithEmptyValue;

		this.setState(stateChanges);
	}, 5);

	unloadSubjectType(projectId, subjectType, formType) {
		if (!(projectId && subjectType)) return;

		if (['employees', 'equipment', 'forms'].includes(subjectType)) {
			lokiInstance.getCollection(subjectType).cementoOff('lokiObjectAnalytics');
			if (subjectType === 'employees') endEmployeesListener(projectId);
			else if (subjectType === 'equipment') endEquipmentListener(projectId);
			else if (subjectType === 'forms') endFormsListener(projectId, formType);
		}
	}

	connectToSubjects = (nextProps, nextState, subjectType) => {
		const { selectedProjectId, viewer } = nextProps;
		const { formType } = nextState;

		const basicParams = {
			scope: 'projects',
			scopeId: selectedProjectId,
			viewer
		}

		DataManagerInstance.loadAndConnect({
			...basicParams,
			subject: SUBJECTS.PROPERTIES_INSTANCES,
			queryParams: { subjectName: `${subjectType}Info` },
		});

		let extraParams = { subject: subjectType }
		if (subjectType === SUBJECTS.FORMS) {
			DataManagerInstance.loadAndConnect({
				...basicParams,
				subject: SUBJECTS.PROPERTIES_INSTANCES,
				queryParams: { subjectName: `${SUBJECTS.POSTS}Info` },
			});
			DataManagerInstance.loadAndConnect({ ...basicParams, subject: SUBJECTS.POSTS });
			extraParams.queryParams = { formType }
		} else if (subjectType === SUBJECTS.POSTS) {
			DataManagerInstance.loadAndConnect({
				...basicParams,
				subject: SUBJECTS.CHECKLIST_ITEM_INSTANCES,
			});
			DataManagerInstance.loadAndConnect({
				...basicParams,
				subject: SUBJECTS.PROPERTIES_INSTANCES,
				queryParams: { subjectName: propertyTypes.SUBJECT_NAMES[SUBJECTS.CHECKLIST_ITEMS] },
			});
		}

		if (subjectType !== 'locations') DataManagerInstance.loadAndConnect({ ...basicParams, ...extraParams });

	}

	setComponentData(props, nextProps, subjectType) {
		const { menus, viewer, configurations, customReportId, isDataReady } = nextProps;

		let newChanges = {};

		let subMenus = menus ? menus[nextProps.contentType] : null;
		let newSubjectType = subjectType;

		if (nextProps.contentType === 'siteControl') newSubjectType = 'employees';

		let reportId = this.state.reportId;
		let nextReportId = nextProps.getNested(['match', 'params', 'reportId']);

		let subMenusSectionTitle =
			nextProps.customSubMenuType || nextProps.getNested(['match', 'params', 'section']) || defaultSubMenuType;
		if (!nextReportId && subMenus && subMenus.getNested([subMenusSectionTitle, 'options'], []).length > 0) {
			let subjectDefaultMenu = subMenus
				.getNested([subMenusSectionTitle, 'options'], [])
				.filter(x => x.subjectType == subjectType);
			if (subjectDefaultMenu.length) nextReportId = subjectDefaultMenu[0].id;
			else if (customReportId) nextReportId = customReportId;
			else nextReportId = subMenus.getNested([subMenusSectionTitle, 'options'])[0].id;
		}

		// If reports did not load yet
		// TODO: remove this false
		if (
			false &&
			nextReportId &&
			(!subMenus ||
				!subMenus.getNested([subMenusSectionTitle, 'options'], []).filter(x => x.id == nextReportId).length > 0)
		)
			return;

		const isDiffSubjectType = subjectType !== newSubjectType;
		if (!this.state.didLoadReport) {
			newChanges.didLoadReport = true;
		}
		const isDiffSelectedFav = props.isValDiff(nextProps, ['selectedFav']);
		let selectedFav = nextProps.selectedFav;
		if (props.firstMount || isDiffSelectedFav || isDiffSubjectType) {
			newChanges.selectedFilterSet = selectedFav;
			newSubjectType = nextProps.selectedFav?.subjectType;
			newChanges.isPostRelated = ['posts'].includes(newSubjectType);
			newChanges.subjectType = newSubjectType;

			newChanges.filters =
				nextProps.selectedFav?.values || this.getDefaultFilter(Object.assign({}, this.state, newChanges), nextProps);
			const urlParams = this.getUrlParams();
			const shouldSetCounterRows = PAGE_CONFIGURATIONS.subjectTypes[newSubjectType]?.shouldSetCounterRows || null;
			newChanges.filters = { ...newChanges.filters, shouldSetCounterRows, ...urlParams };
		}

		const nextPropsContentType = _.get(nextProps, ['contentType']);
		const isDiffContentType = props.isValDiff(nextProps, ['contentType']);
		if (props.firstMount || isDiffContentType) {
			newChanges.featureName = CONTENT_TO_FEATURE_MAP[nextPropsContentType];
		}

		if (
			props.firstMount ||
			isDiffContentType ||
			props.isValDiff(nextProps, ['viewer', 'adminMode']) ||
			props.isValDiff(nextProps, ['configurations']) ||
			!_.isNil(newChanges.isPostRelated)
		) {
			const nextFeatureName = newChanges.featureName || this.state.featureName;
			const isCompanyPage = nextPropsContentType === 'settings';
			const isPostRelated = _.isUndefined(newChanges.isPostRelated) ? this.state.isPostRelated : newChanges.isPostRelated;
			const isFeatureActive = (
				!newSubjectType ||
				isCompanyPage ||
				isPostRelated ||
				viewer.adminMode == 1 ||
				configurations.getNested(['features', nextFeatureName, 'isActive'], false) ||
				(nextFeatureName == 'siteControl' && configurations.getNested(['cameras'], false))
			);

			if (isCompanyPage) {
				newChanges.noReportMenus = false;
				let allProjects = nextProps.getNested(['projects']);
				let allProjectIds = allProjects.filter?.(project => project.companyId === nextProps.selectedProjectId).map(project => project.id) || []

				allProjectIds.forEach(projectId => {
					DataManagerInstance.loadAndConnect({ scope: 'projects', scopeId: projectId, viewer, subject: SUBJECTS.COMPANIES });
				})
			}
			else
				newChanges.noReportMenus =
					!isFeatureActive ||
					subMenus
						?.getNested([subMenusSectionTitle, 'options'], [])
						.filter(option => !option.adminOnly || viewer.adminMode == 1).length == 0;
		}

		if (props.firstMount || reportId !== nextReportId) {
			newChanges.reportId = nextReportId;
		}

		if (subjectType !== newSubjectType) {
			newChanges.sideCardObject = null;
		}

		let subjectParams = {};
		if (subjectType === SUBJECTS.FORMS) subjectParams.formType = this.state.formType;
		
		if (
			DataManagerInstance.isProjectStorageLoaded(nextProps.selectedProjectId) &&
			DataManagerInstance.projectLokiLoaded[nextProps.selectedProjectId]
		) {
			if (
				!isDataReady ||
				newSubjectType && newSubjectType !== subjectType ||
				props.isValDiff(nextProps, ['selectedProjectId']) ||
				props.isValDiff(nextProps, ['configurations']) ||
				nextProps.cleanCacheRevokes && props.cleanCacheRevokes != nextProps.cleanCacheRevokes ||
				(nextProps.storageCleaned && props.isValDiff(nextProps, ['storageCleaned']))
			) {
				if (subjectType !== newSubjectType) {
					this.unloadSubjectType(props.selectedProjectId, subjectType, this.state.formType);
				}

				this.loadSubjectType(nextProps, Object.assign({}, this.state, newChanges), newSubjectType);
			}
		}

		const propertiesChanged =
			!_.isEqual(
				_.get(props, ['propertiesSections', newSubjectType + 'Info']),
				_.get(nextProps, ['propertiesSections', newSubjectType + 'Info']),
			) ||
			!_.isEqual(
				_.get(props, ['propertiesTypes', newSubjectType + 'Info']),
				_.get(nextProps, ['propertiesTypes', newSubjectType + 'Info']),
			) ||
			!_.isEqual(
				_.get(props, ['propertiesMappings', newSubjectType + 'Info']),
				_.get(nextProps, ['propertiesMappings', newSubjectType + 'Info']),
			) ||
			!_.isEqual(props.filteredPosts, nextProps.filteredPosts);

		if (
			newSubjectType &&
			nextProps.selectedProjectId &&
			nextProps.menus &&
			(propertiesChanged ||
				props.selectedProjectId !== nextProps.selectedProjectId ||
				newSubjectType !== subjectType ||
				props.menus != nextProps.menus ||
				!_.isEqual(
					props.getNested(['projects', props.selectedProjectId]),
					nextProps.getNested(['projects', nextProps.selectedProjectId]),
				))
		) {
			newChanges.defaultSubMenu = subMenus;
			this.onTableDataUpdate(nextProps, _.assign({}, this.state, newChanges));
		}

		if (!_.isEmpty(newChanges)) {
			this.setState(newChanges);
		}
	}

	

	getAdminFilterComponent = (props, state) => {
		const { propertiesSections, propertiesTypes, contentType, configurationMode } = props || this.props;
		const { subjectType, selectedFilterSet, filters, originalColumns, originalSections } = state || this.state;

		const adminMode = configurationMode;
		const isSafetyPosts = contentType == 'safety' && subjectType === 'posts';

		let component = null;

		if (!isSafetyPosts && adminMode) {
			const mockProperties =
				subjectType === 'posts'
					? {
							propertiesSections: _.merge(
								{ postsInfo: propertiesSections.postsInfo },
								propertyTypes.MOCK_PROPERTY_TYPES_SECTIONS,
							),
							propertiesTypes: {
								...propertiesTypes,
								postsInfo: getPropertiesTypesOfPostsInfo({
									postsInfo: _.merge(propertiesTypes.postsInfo, propertyTypes.MOCK_PROPERTY_TYPES.postsInfo),
									contentType: contentType,
								}),
							},
					  }
					: { propertiesSections, propertiesTypes };

			component = (
				<TableFilters
					filters={filters}
					subjectName={`${subjectType}Info`}
					selectedFilterSet={selectedFilterSet}
					updateSelectedFilterSet={this.handleUpdateSelectedFilterSet}
					originalSections={originalSections}
					originalColumns={originalColumns}
					handleSaveFilter={this.handleSaveFilter}
					updateFilters={this.handleUpdateFilters}
					showColumnSelector={true}
					{...mockProperties}
				/>
			);
		}

		return component;
	};

	reCalcHeader(filtersMenu, noReportMenusParam) {
		const { setHeaderParams } = this.props;
		const { noReportMenus, isPostRelated, viewType } = noReportMenusParam || this.state;

		const isOnPostTitlesView = isPostRelated && viewType === POSTS_VIEW_TYPES.TILES;
		if (!setHeaderParams || isOnPostTitlesView) return;

		let headerComponent = (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flex: 1,
					flexDirection: 'column',
					backgroundColor: 'white',
				}}
			>
				<div style={{ paddingInline: theme.paddingSize, display: 'flex', justifyContent: 'flex-end', height: 48 }}>
					{filtersMenu}
				</div>
			</div>
		);

		let sideBarParams = { open: false, outerClickClose: true };

		if (noReportMenus) {
			sideBarParams = { hidden: true };
			headerComponent = null;
		}

		setHeaderParams({ headerComponent, sideBarParams });
	}

	getPopulatedObjects(props, state, skipSetState = false) {
		const { location } = props;
		const { updatedPropertiesMap, subjectType } = state;
		let {
			selectedProjectId,
			propertiesTypes,
			propertiesMappings,
			intl,
			units,
			propertiesSections,
			filteredPosts,
			projectCompanies,
			contentType,
			subCategories,
		} = props;
		
		let newStateChanges = {};

		let inObjects = null;
		let inDefaultGroupId = null;

		let _propertiesSections = { ...propertiesSections };
		let _propertiesMappings = { ...propertiesMappings };
		let _propertiesTypes = { ...propertiesTypes };
		
		if (subjectType === 'locations')
			inObjects = Object.values(units.toJS ? units.toJS() : units)
				.map(o => Object.values(o))
				.flat();
		else if (subjectType == 'posts') {
			const searchParams = new URLSearchParams(location.search);
			inDefaultGroupId = searchParams.get('groupId') || getPostGroupId({ isIssue: state?.reportId === 'issues' });
			inObjects = injectMembersToPost(filteredPosts, props.members);
			let filledPostsInfoMockProperties = { ...propertyTypes.MOCK_PROPERTY_TYPES.postsInfo };
			if (subjectType === 'posts') {
				const assignToCompanyPropId = propertyTypes.MOCK_PROPERTY_TYPES.postsInfo.assignToCompany.id;
				if (filledPostsInfoMockProperties[assignToCompanyPropId]) {
					filledPostsInfoMockProperties[assignToCompanyPropId] = fillBusinessType(
						filledPostsInfoMockProperties[assignToCompanyPropId],
						{ companies: projectCompanies },
					);
				}
				const categoryPropId = propertyTypes.MOCK_PROPERTY_TYPES.postsInfo.category.id;
				if (filledPostsInfoMockProperties[categoryPropId]) {
					filledPostsInfoMockProperties[categoryPropId] = fillBusinessType(
						filledPostsInfoMockProperties[categoryPropId],
						{ subCategories },
					);
				}
			}
			// Set defaults in case we don't have proptypes
			_propertiesSections = {
				...propertiesSections,
				postsInfo: _.merge(propertiesSections.postsInfo, propertyTypes.MOCK_PROPERTY_TYPES_SECTIONS.postsInfo),
			};
			_propertiesMappings = {
				postsInfo: {},
				...(propertiesMappings?.safeToJS() || propertiesMappings || {}),
			};
			_propertiesTypes = {
				...propertiesTypes,
				postsInfo: getPropertiesTypesOfPostsInfo({
					postsInfo: _.merge(propertiesTypes.postsInfo, filledPostsInfoMockProperties),
					contentType: contentType,
				}),
			};
		}
		const populatedObject = populateObject({
			selectedProjectId,
			subjectType,
			propertiesSections:_propertiesSections,
			propertiesTypes:_propertiesTypes,
			inPropertiesMappings: _propertiesMappings,
			intl,
			inObjects,
			extraLocalInstances: updatedPropertiesMap && Object.values(updatedPropertiesMap),
			skipPopulatedObjects: false,
			includeMockOfDefaultPropertyType: true,
			inDefaultGroupId,
		});

		let { allProps } = populatedObject;

		// process data
		const processedDataForTables = preProcessData({
			allProps,
			propertiesTypes:_propertiesTypes,
			propertiesMappings:_propertiesMappings,
			subjectType,
			companiesMap: projectCompanies,
			lokiInstance,
			intl,
			scopeId: selectedProjectId,
		});
		newStateChanges.processedDataForTables = processedDataForTables;
		newStateChanges.allProps = processedDataForTables?.allProps;

		if (!skipSetState) this.setState(newStateChanges);

		return newStateChanges;
	}

	reCalcMember(members, inFilters, inTextFilter) {
		const { trades, rtl, titles, intl, companyProjects, projectCompanies } = this.props;
		const filters = inFilters || this.state.filters;
		const displayEmptyRows = true;
		const defaultSectionTitle = intl.formatMessage(usersMessages.titles.projects);
		let filteredMembers = {};
		let filteredProjectCompanies = {}
		let activeOnly = filters && filters.activeOnly;
		inTextFilter = inTextFilter?.trim().toLowerCase();
		if (inTextFilter) {
			let phone = null;

			if (inTextFilter[0] == 0) phone = inTextFilter.slice(1);

			Object.values(members).forEach(member => {
				const { user_metadata } = member;
				if (user_metadata) {
					if (
						(user_metadata.displayName || '').trim().toLowerCase().includes(inTextFilter) ||
						(user_metadata.phoneNumber || '').trim().includes(phone)
					) {
						filteredMembers[user_metadata.id] = members[user_metadata.id];
						filteredProjectCompanies[user_metadata.companyId] =
							projectCompanies[user_metadata.companyId] ||
							projectCompanies[Object.values(user_metadata?.projects || {})?.[0]?.companyId];
					}
				}
			});
		} else {
			filteredMembers = members;
			filteredProjectCompanies = projectCompanies
		}

		const tableDataObj = getMembersProjectsTableDataObj(
			filteredProjectCompanies || {},
			companyProjects || {},
			filteredMembers || {},
			trades || {},
			titles || {},
			activeOnly,
			defaultSectionTitle,
		);
		const tableWrapperProps = getTableWrapperProps({ tableDataObj, rtl, displayEmptyRows });

		return tableWrapperProps;
	}

	onMemberUpdate(member) {
		const { members } = this.state;
		if (members && member) {
			let newMembers = members.setNested([member.id, 'user_metadata'], member);
			let tableWrapperProps = this.reCalcMember(newMembers);

			this.setState({ members: newMembers, ...tableWrapperProps });
		}
	}

	reCalcCompany(companies, inFilters, inTextFilter) {
		const { trades, rtl, intl, companyProjects } = this.props;
		const { filters } = this.state;
		const defaultSectionTitle = intl.formatMessage(usersMessages.titles.projects);
		let activeOnly = filters && filters.activeOnly;
		let filteredCompanies = {};
		inTextFilter = inTextFilter?.trim().toLowerCase();
		if (inTextFilter) {
			Object.values(companies).forEach(company => {
				if ((company.name || '').trim().toLowerCase().includes(inTextFilter)) filteredCompanies[company.id] = companies[company.id];
			});
		} else filteredCompanies = companies;
		const tableDataObj = getSubcontractorProjectsTableDataObj(
			filteredCompanies || {},
			companyProjects,
			trades || {},
			activeOnly,
			defaultSectionTitle,
		);
		const tableWrapperProps = getTableWrapperProps({ tableDataObj, rtl });

		return tableWrapperProps;
	}

	onCompanyUpdate(company) {
		const { companies } = this.state;
		if (company) {
			let newCompanies = companies.setNested([company.id], company);
			let tableWrapperProps = this.reCalcCompany(newCompanies);

			this.setState({ companies: newCompanies, ...tableWrapperProps });
		}
	}

	async displayCompaniesView(props, inFilters, inTextFilter) {
		const relevantProjectIds = props.relevantProjectIds || [];
		const companies = await getCompanies(relevantProjectIds);
		let tableWrapperProps = this.reCalcCompany(companies, inFilters, inTextFilter);

		let newState = { ...tableWrapperProps, companies, rowsLevel: 1 };

		return newState;
	}

	async displayMembersView(props, inFilters, inTextFilter) {
		const relevantProjectIds = props.relevantProjectIds || [];
		const members = await getMembers(relevantProjectIds);
		let tableWrapperProps = this.reCalcMember(members, inFilters, inTextFilter);

		let newState = { ...tableWrapperProps, members, rowsLevel: 2 };

		return newState;
	}

	async displayLocationGroupsView(props, inFilters, inTextFilter) {
		let groupsArray = _.get(props, ['propertiesTypes', 'locationsInfo', 'groups', 'values']);
		let { groupsSubTypes } = await fetchQuasiStatics('groupsSubTypes');
		let groupsMap = {};
		(groupsArray || []).forEach(g => {
			_.set(groupsMap, g.id, { id: g.id, title: { [props.viewer.lang]: g.getCementoTitle() }, types: g.types });
		});

		let tableWrapperProps = this.reCalcGroups(groupsMap, groupsSubTypes, inFilters, inTextFilter);

		let newState = { ...tableWrapperProps, groupsMap, groupsSubTypes, rowsLevel: 2 };

		return newState;
	}

	async handleGetEmployeesPresence(currEmployeesPresenceParams) {
		const { projectId, startTS, endTS } = currEmployeesPresenceParams;
		const queryString = `${projectId}${startTS}${endTS}`;

		const {
			employeesPresenceData: prevEmployeesPresenceData,
		} = this.state;

		if (this.lastQueryString == queryString && !this.shouldForceEmployeePresenceRefresh) {
			return this.isGetEmployeesPresencePromiseInProgress
				? prevEmployeesPresenceData :
				this.getEmployeesPresencePromise;
		}


		this.lastQueryString = queryString;
		this.isGetEmployeesPresencePromiseInProgress = true;
		if (!this.shouldSkipLoadingOnEmployeePresenceFetching) this.setState({ loadingEmployeesPresence: true })
		this.getEmployeesPresencePromise = getEmployeesPresence({
			projectId,
			startTS,
			endTS,
			includeMissingDays: true,
			excludeEmptyLogs: true,
			populate: true,
			includeMonitor: true,
			silent: this.shouldSkipLoadingOnEmployeePresenceFetching,
			onError: () => {
				this.lastQueryString += '_failed';
				this.isGetEmployeesPresencePromiseInProgress = false;
			},
		});
		
		const getCamerasTitlePromise = getCameras(projectId, ['title']);

		let [employeesPresenceData, camerasTitles] = await Promise.all([this.getEmployeesPresencePromise, getCamerasTitlePromise]);

		for (const currCameraId in _.get(employeesPresenceData, ['monitor'])) {
			_.set(employeesPresenceData, ['monitor', currCameraId, 'title'], _.get(camerasTitles, [currCameraId, 'title']));
		}

		this.isGetEmployeesPresencePromiseInProgress = false;
		this.shouldForceEmployeePresenceRefresh = false;
		this.shouldSkipLoadingOnEmployeePresenceFetching = false;

		this.setState({ employeesPresenceData, loadingEmployeesPresence: false });

		return employeesPresenceData;
	}

	async handleMonthPicker(selectedRange) {
		if (selectedRange) {
			this.selectedRange = selectedRange;
			this.debouncedCalculateTableDataProps(this.props, this.state,);
		}
	}

	async displayEmployeesPresenceView(props, inFilters, inTextFilter) {
		const { selectedProjectId: projectId } = props;
		const { startTS, endTS } = this.selectedRange || {};

		const employeesPresenceParams = {
			projectId,
			startTS: moment(startTS).startOf('month').valueOf(),
			endTS: moment(endTS).endOf('day').valueOf(),
		};

		const employeesPresenceData = await this.handleGetEmployeesPresence(employeesPresenceParams);
		if (!employeesPresenceData) return

		const tableWrapperProps = this.reCalcEmployeesPresence(
			_.get(employeesPresenceData, ['employees']),
			inFilters,
			inTextFilter,
			startTS,
			endTS,
		);
		const newState = { ...tableWrapperProps, employeesPresenceData };

		return newState;
	}

	reCalcEmployeesPresence(employeesPresenceData, inFilters, inTextFilter, startTS, endTS) {
		const { rtl, intl } = this.props;
		const tablesMetadata = getEmployeesTablesMetadata(employeesPresenceData);
		const tableDataObj = getEmployeesPresenceTableDataObj({
			employeesPresenceData,
			intl,
			inFilters,
			inTextFilter,
			startTS,
			endTS,
			tablesMetadata,
		});
		const tableWrapperProps = getTableWrapperProps({ tableDataObj, rtl });
		return tableWrapperProps;
	}

	reCalcGroups(groupsMap, groupsSubTypes, inFilters, inTextFilter) {
		const { rtl, selectedProjectId, intl, viewer } = this.props;
		const locationsMap = getObjectsTitlesMap(this.props, 'locationsInfo');
		let groupsInstances = this.lokiPropertyInstances.cementoFind({
			'projectId': selectedProjectId,
			subjectName: 'locationsInfo',
			propId: 'groups',
		});
		_.forIn(groupsMap, x => {
			!x.types && (x.types = { '-units': true });
		}); // Init groups that have no type to be of type units by default

		const mainRows = {
			buildings: { id: '-buildings', title: intl.formatMessage(newProjectMessages.locationTypes.buildings) },
			floors: { id: '-floors', title: intl.formatMessage(newProjectMessages.locationTypes.floors) },
			units: { id: '-units', title: intl.formatMessage(newProjectMessages.locationTypes.units) },
		};
		let filteredGroups = {};

		if (inTextFilter) {
			Object.values(groupsMap).forEach(group => {
				if (_.get(group, ['title', viewer.lang], '').includes(inTextFilter))
					filteredGroups[group.id] = groupsMap[group.id];
			});
		} else {
			filteredGroups = groupsMap;
		}
		const tableDataObj = getLocationsGroupsTableDataObj(
			mainRows,
			filteredGroups,
			groupsInstances,
			locationsMap,
			groupsSubTypes,
		);
		const tableWrapperProps = getTableWrapperProps({ tableDataObj, rtl });

		return tableWrapperProps;
	}

	onGroupUpdate(group) {
		const { groupsMap, groupsSubTypes } = this.state;

		let newGroupsMap = { ...groupsMap };
		if (group) newGroupsMap = _.set(newGroupsMap, group.id, group);

		let tableWrapperProps = this.reCalcGroups(newGroupsMap, groupsSubTypes);

		this.setState({ ...tableWrapperProps, groupsMap: newGroupsMap });
	}

	getDefaultFilter = (_state, _props) => {
		const props = _props || this.props;
		const state = _state || this.state;
		const { subjectType, isPostRelated } = state || this.state;
		const { contentType, location, propertiesMappings, propertiesTypes } = props;

		const urlParams = this.getUrlParams();
		let filter = { ...urlParams };
		if (subjectType === 'posts' && props.contentType) {
			const isRecords = contentType === 'records' || urlParams.itemType === 'records';

			const searchParams = new URLSearchParams(location?.search);
			const groupId = searchParams.get('groupId') || (isRecords ? POSTS_TYPES.RECORD : POSTS_TYPES.ISSUE);

			let columnsProperties = _.keys(propertiesTypes?.postsInfo);

			let newPropertiesMappings = propertiesMappings

			// Mock properties mappings for posts info if they are not present
			if(!propertiesMappings.postsInfo){
				newPropertiesMappings = {
					...propertiesMappings,
					postsInfo: propertyTypes.MOCK_PROPERTY_MAPPINGS.postsInfo
				}
			}

			let mappedColumnProperties = getAllMappedProperties(
				newPropertiesMappings,
				'postsInfo',
				'groups',
				groupId
			);
			if (!isPostRelated || (isPostRelated && columnsProperties.length < mappedColumnProperties.length)) {
				columnsProperties = mappedColumnProperties;
			}

			_.forIn(columnsProperties, colId => {
				const isVisible = !(isRecords && propertyTypes.RECORDS_BLACKLIST_COLUMNS.includes(colId));
				_.set(filter, ['columnVisibility', colId], { 'table': isVisible, 'card': isVisible });
			});
		}

		return _.isEmpty(filter) ? null : filter;
	};

	async calculateTableDataProps(_props, _state, inFilters, inTextFilter) {
		const props = _props || this.props;
		const state = _state || this.state;

		const { rtl, intl, contentType } = props;
		let stateChanges = {};

		let { processedDataForTables, subjectType, noReportMenus } = state;
		if (noReportMenus != undefined && noReportMenus) return;
		
		const filters = inFilters || state.filters || this.getDefaultFilter(state, props);
		const textFilter = inTextFilter || _.get(filters, 'textFilter');

		if (['members', 'companies', 'locationsGroupsManagement'].includes(subjectType) || contentType === 'siteControl') {
			stateChanges = await this.dataToTableProps(props, subjectType, filters, textFilter);
		}
		else {
			let ret = this.propertiesToTableProps(props, state);
			const originalRowsData = ret.originalRowsData;
			const subjectProperties = ret.subjectProperties;

			if (!originalRowsData) {
				return stateChanges;
			}

			const subjectName = subjectType + 'Info';
			const tableDataObj = getPropertiesTableDataObject(
				originalRowsData,
				filters,
				subjectProperties,
				intl,
				subjectName,
				processedDataForTables,
			);
			const originalTableData = originalRowsData.groupByMetaData;
			stateChanges = getTableWrapperProps({ tableDataObj, rtl, originalTableData });
			const currentState = Object.assign({}, state, stateChanges);
			const filteredState = this.filtersHandler(filters, currentState, textFilter);

			stateChanges = { ...currentState, ...filteredState };
			stateChanges.originalColumns = originalRowsData.groupByMetaData.properties;
			stateChanges.originalSections = originalRowsData.groupByMetaData.sections;
			stateChanges.isPropertiesTable = true;
			stateChanges.categoryFilters = this.getTableDataObjectFilterMenuSet(tableDataObj);
			stateChanges.tableDataObject = tableDataObj;
			if (filters !== state.filters) {
				stateChanges.filters = filters;
			}
		}

		return stateChanges;
	}

	debouncedCalculateTableDataProps = AwesomeDebouncePromise(async (...args) => {
		const stateChanges = await this.calculateTableDataProps(...args);
		if (!_.isEmpty(stateChanges)) {
			this.setState(stateChanges);
		}
	}, 15);

	/**
	 *
	 * @param {import('../Reports/tableWrapperHelpers').TableDataObject} tableDataObj
	 * @returns
	 */
	getTableDataObjectFilterMenuSet(tableDataObj) {
		const { intl } = this.props;
		let categories = {};

		let fallbackOrdinalNo = 1;
		_.values(tableDataObj.sections).forEach(section => {
			_.values(section.columns).forEach(column => {
				if (
					column.id === 'groups' ||
					column.settings?.showInFilters === false ||
					(!tableDataObj.isSubTable && column.isDynamicallyGenerated) ||
					!SUPPORTED_FILTER_TYPES.includes(column.valuesType)
				)
					return;
				
				let category = {
					id: ['values', column.id, ORIGINAL_VALUE_KEY_TERM].join(FILTER_MENU_PATH_DELIMETER),
					ordinalNo: isEmptyValue(column.num) ? fallbackOrdinalNo : column.num,
					type: column.valuesType,
					title: column.title || column.getCementoTitle(),
					inputSettings: column.settings,
				};
				let shouldSetNoValueOption = false;
				if (column.universalId === propertyTypes.UNIVERAL_IDS.status) {
					category.type = propertyTypes.SELECTION_LIST;
					const relevantStatusOptions = formStatusesArray
						.filter(s => s !== REPORT_STATE_AWAITING_APPROVAL)
						.map(status => ({
							id: status,
							title: String(safeFormatMessage(intl, getStatusMessage(status).message)),
						}));

					category.options = relevantStatusOptions;
				} else if (column.universalId === propertyTypes.UNIVERAL_IDS.trade) {
					const originalValueByRow = _.values(column.rows).reduce((acc, row) => {
						const rowsToGoThrough = _.keys(row.subCells).length ? _.values(row.subCells) : [row];
						rowsToGoThrough.forEach(
							r =>
								!isEmptyValue(_.get(r, ['value', ORIGINAL_VALUE_KEY_TERM])) &&
								_.set(acc, r.value[ORIGINAL_VALUE_KEY_TERM], true),
						);
						return acc;
					}, {});
					const relevantOptions = _.values(column.valueOptions)
						.filter(o => originalValueByRow[o.id] && o.getCementoTitle())
						.reduce((acc, o) => _.set(acc, o.id, { id: o.id, title: o.getCementoTitle() }), {});

					category.options = _.values(relevantOptions);
				} else if (column.universalId === propertyTypes.UNIVERAL_IDS.issueState) {
					const relevantOptions = ISSUE_STATUSES_ARRAY.map(status => ({
						id: status,
						title: issuesMessages.counterStatus[status],
					}));

					category.options = relevantOptions;
				} else {
					shouldSetNoValueOption = true;
					switch (column.valuesType) {
						case propertyTypes.DATE: {
							let existingValues = {};

							_.values(column.rows).forEach(row => {
								const rowsToGoThrough = _.keys(row.subCells).length ? _.values(row.subCells) : [row];
								rowsToGoThrough.forEach(row => {
									const rowValue = _.get(row, ['value', ORIGINAL_VALUE_KEY_TERM]);
									if (rowValue && !existingValues[rowValue]) existingValues[rowValue] = rowValue;
								});
							});

							category.existingValues = existingValues;
							category.type = propertyTypes.DATE_RANGE;
							break;
						}

						case propertyTypes.SELECTION_LIST: {
							const originalValueByRow = _.values(column.rows).reduce((acc, row) => {
								const rowsToGoThrough = _.keys(row.subCells).length ? _.values(row.subCells) : [row];
								rowsToGoThrough.forEach(
									r =>
										!isEmptyValue(_.get(r, ['value', ORIGINAL_VALUE_KEY_TERM])) &&
										_.keys(r.value[ORIGINAL_VALUE_KEY_TERM]).forEach(vId => _.set(acc, vId, true))
								);
								return acc;
							}, {});
							const shouldFilterIrrelevantOptions = column.settings?.showAllValuesInFilters !== true;
							const relevantOptions = _.values(column.valueOptions).reduce((acc, option) => {
								if (!shouldFilterIrrelevantOptions || originalValueByRow[option.id]) {
									acc[option.id] = { id: option.id, title: option.getCementoTitle() };
								}

								return acc;
							}, {});
							category.options = _.values(relevantOptions);
							break;
						}

						case propertyTypes.BOOLEAN: {
							category.type = propertyTypes.SELECTION_LIST;
							category.options = [
								{ id: 'true', title: systemMessages.yes },
								{ id: 'false', title: systemMessages.no },
							];
							break;
						}

						case propertyTypes.NUMBER:
						case propertyTypes.STRING: {
							const relevantOptions = _.values(column.rows).reduce((acc, row) => {
								const rowsToGoThrough = _.keys(row.subCells).length ? _.values(row.subCells) : [row];

								rowsToGoThrough.forEach(r => {
									const rowValue = r.value[ORIGINAL_VALUE_KEY_TERM];
									const trimmedValue = _.trim(rowValue);
									if (!isEmptyValue(trimmedValue)) {
										acc[trimmedValue] = { id: rowValue, title: trimmedValue };
									}
								});

								return acc;
							}, {});

							category.type = propertyTypes.SELECTION_LIST;
							category.options = _.values(relevantOptions);
							break;
						}

						default:
							break;
					}
				}

				if (shouldSetNoValueOption && category.options?.length)
					category.options.push({ id: 'undefined', title: `(${safeFormatMessage(intl, propertiesMessages.empty)})` });
				categories[column.id] = category;
				fallbackOrdinalNo++;
			});
		});

		return [
			{
				id: 'defaultView',
				title: 'defaultView', // give real name if more than one filter set
				categories: _.values(categories),
			},
		];
	}

	async dataToTableProps(props, subjectType, inFilters, inTextFilter) {
		let tableProps = {};
		switch (subjectType) {
			case 'members':
				tableProps = await this.displayMembersView(props, inFilters, inTextFilter);
				break;
			case 'companies':
				tableProps = await this.displayCompaniesView(props, inFilters, inTextFilter);
				break;
			case 'locationsGroupsManagement':
				tableProps = await this.displayLocationGroupsView(props, inFilters, inTextFilter);
				break;
			case 'employees':
				tableProps = await this.displayEmployeesPresenceView(props, inFilters, inTextFilter);
				break;
			default:
				tableProps = {};
				break;
		}

		return tableProps
	}

	propertiesToTableProps(props, state) {
		let { updatedPropertiesMap, processedDataForTables, subjectType, formType, filters } = state;
		const { buildings, floors, units, intl, selectedProjectId, viewer, filteredPosts } = props;

		if (!subjectType || !selectedProjectId) return;

		let subjectName = subjectType + 'Info';
		let propertiesInstances = this.lokiPropertyInstances.cementoFind({ 'projectId': selectedProjectId, subjectName });
		if (propertiesInstances && updatedPropertiesMap) {
			let instancesMap = {};
			propertiesInstances.forEach(instance => (instancesMap[instance.id] = instance));
			Object.values(updatedPropertiesMap).forEach(prop => (instancesMap[prop.id] = { ...prop, subjectName }));
			propertiesInstances = Object.values(instancesMap);
		}
		let objectValues = null;
		let innerPropertiesTypes = _.get(processedDataForTables, ['propertiesTypes', subjectName]);

		if (['employees', 'equipment'].includes(subjectType))
			objectValues =
				props.employees ||
				(lokiInstance.getCollection(subjectType)
					? lokiInstance.getCollection(subjectType).cementoFind({ 'projectId': selectedProjectId })
					: []);
		else if (subjectType === 'forms')
			objectValues = lokiInstance.getCollection(subjectType)
				? lokiInstance.getCollection(subjectType).cementoFind({ 'projectId': selectedProjectId, 'type': formType })
				: [];
		else if (subjectType === 'locations') objectValues = getLocationsObjectsForTable(buildings, floors, units, intl);
		else if (subjectType == 'posts') {
			objectValues = injectMembersToPost(filteredPosts, props.members);
		}
		let fakePrimaryProp = null;
		if (subjectType === 'forms') {
			fakePrimaryProp = {
				id: `-formDate-${subjectType}Table`,
				settings: { dateFormat: intl.formatMessage(systemMessages.fullDateFormat), timezone: 'Asia/Jerusalem' },
				title: intl.formatMessage(analyticsMessages.dashboards.axesLabels.date),
				type: propertyTypes.DATE,
				pathInObject: 'reportDate',
				ordinalNo: 99999, // Needs ridiculous ordinalNo because we are assigning it a random sectionId and, in the case that this column comes first in the processing, we want its ordinalNo to be overwritten by a lower one from a real property in the section
			};
		} else if (subjectType === 'locations') {
			fakePrimaryProp = {
				id: `-locationName-${subjectType}Table`,
				title: intl.formatMessage(analyticsMessages.location),
				type: propertyTypes.STRING,
				pathInObject: 'title',
				ordinalNo: 99999, // Needs ridiculous ordinalNo because we are assigning it a random sectionId and, in the case that this column comes first in the processing, we want its ordinalNo to be overwritten by a lower one from a real property in the section
			};
		}

		let subjectMappings = props.getNested(['propertiesMappings', subjectName]);
		let subjectSections = safeToJS(props.getNested(['propertiesSections', subjectName]));

		if (subjectType === 'posts') {
			subjectSections = subjectSections || propertyTypes.MOCK_PROPERTY_TYPES_SECTIONS['postsInfo'];
			subjectMappings = subjectMappings || new Map();
		}

		if (fakePrimaryProp) {
			const randomSectionId = _.get(_.values(subjectSections), [0, 'id']);
			if (!randomSectionId) fakePrimaryProp = null;
			else {
				fakePrimaryProp.isPrimary = true;
				fakePrimaryProp.sectionId = randomSectionId; // It doesnt matter which section as this is the primary column and doesnt show within a section, just needs this so the rest of the functions can operate normally
			}
		}
		const isExpandedView = _.get(filters, 'isSubTable', false);
		let showColumnEvenWhenNoDataExists = Boolean(viewer && viewer.adminMode == 1);

		let originalRowsData = getPropertiesInfo({
			subjectProperties: innerPropertiesTypes,
			pathInObjectProperties: fakePrimaryProp ? { [fakePrimaryProp.id]: fakePrimaryProp } : null,
			subjectSections,
			subjectMappings,
			objects: objectValues,
			showColumnEvenWhenNoDataExists,
			allProps: processedDataForTables.allProps,
			isExpandedView,
			aggregateByPrimaryColumn: !SUBJECTS_WITH__DISABLED_GROUPING.includes(subjectType),
		});

		let subjectProperties = Object.assign(
			{},
			innerPropertiesTypes,
			Boolean(fakePrimaryProp) && { [fakePrimaryProp.id]: fakePrimaryProp },
		);
		subjectProperties = _.mapValues(subjectProperties, prop => {
			prop = { ...prop };
			_.set(prop, 'generalDisplayParams', _.get(prop, 'UIStruct.table[0].general'));
			_.set(
				prop,
				'columnSettings',
				_.get(prop, ['UIStruct', 'table', 0, isExpandedView ? 'expanded' : 'collapsed', 'settings']),
			);
			return prop;
		});

		return { originalRowsData, subjectProperties };
	}

	filtersHandler(filters, currentState, textFilter) {
		const { originalColumns, columns, rowsLevel, originalData } = currentState;

		let newRowsArray = originalData?.rows || [];
		const innerTextFilter = ((filters && filters.textFilter) || textFilter || '').trim().toLowerCase();

		if (innerTextFilter || (filters && (filters.activeOnly || filters.filterExpiration))) {
			let activeOnlyColumnId;
			if (filters.activeOnly) {
				const activeOnlyColumns = this.getBusinessTypesColumn(columns, null, 'isActive');
				activeOnlyColumnId = Object.keys(activeOnlyColumns || {}).length > 0 ? Object.keys(activeOnlyColumns)[0] : null;
			}

			let expirationColumns;
			if (filters.filterExpiration) {
				expirationColumns = this.getBusinessTypesColumn(columns, null, null, [
					{ path: ['type'], val: propertyTypes.CERTIFICATION },
					{ path: ['settings', 'isWarning'], val: true },
					{ path: ['settings', 'isExpiration'], val: true },
				]);
			}

			let textFilterColumnIds = [];
			if (innerTextFilter) {
				textFilterColumnIds = _.values(originalColumns).map(column => column.id);
			}

			newRowsArray = newRowsArray.filter(row => {
				let isRowMatch = true;

				if (isRowMatch && innerTextFilter && textFilterColumnIds.length) {
					isRowMatch = textFilterColumnIds.some(columnId => {
						const rowColValue = String(row.values[columnId]?.textValue || '');
						if (!rowColValue) return false;
						return rowColValue.trim().toLowerCase().includes(innerTextFilter);
					});
				}

				if (isRowMatch && filters.activeOnly) {
					if (activeOnlyColumnId) {
						isRowMatch = row.values[activeOnlyColumnId]?.displayValue;
					} else {
						isRowMatch = false;
					}
				}

				if (isRowMatch && filters.filterExpiration) {
					isRowMatch = Object.keys(expirationColumns || {}).some(columnId => {
						let cellValue = row.values[columnId];
						if (!cellValue) return false;

						return Boolean(cellValue.displayParams.isWarning || cellValue.displayParams.isExpired);
					});
				}

				return isRowMatch;
			});

			if (rowsLevel > 1 && newRowsArray.length) {
				const rowsById = _.keyBy(originalData.rows, 'id');
				let alreadyPushedParents = new Set();
				newRowsArray.forEach(row => {
					const parentRow = rowsById[row.parentId];
					if (parentRow && !alreadyPushedParents.has(row.parentId)) {
						newRowsArray.push(parentRow);
						alreadyPushedParents.add(row.parentId);
					}
				});
			}
		}

		let newState = { rowsArray: newRowsArray };
		return newState;
	}

	getBusinessTypesColumn(columns, businessType, universalId, props) {
		let resultColumns = {};
		(columns || []).forEach(section => {
			(section.columns || []).forEach(column => {
				if (
					businessType &&
					!resultColumns[column.original.id] &&
					column.original &&
					column.original.businessType == businessType
				)
					resultColumns[column.original.id] = column.original;
				if (
					universalId &&
					!resultColumns[column.original.id] &&
					column.original &&
					column.original.universalId == universalId
				)
					resultColumns[column.original.id] = column.original;
				if (props)
					props.forEach(propPath => {
						if (
							column.original &&
							!resultColumns[column.original.id] &&
							column.original.getNested(propPath.path) == propPath.val
						)
							resultColumns[column.original.id] = column.original;
					});
			});
		});
		return resultColumns;
	}

	buildTradesList(columns, rowsArray) {
		let tradesColumn = this.getBusinessTypesColumn(columns, 'trades');

		let entireTradesIds = {};
		Object.keys(tradesColumn || {}).forEach(tradeColumnId => {
			rowsArray.forEach(row => {
				Object.keys(row.values[tradeColumnId] || {}).forEach(tradeId => {
					if (!entireTradesIds[tradeId]) entireTradesIds[tradeId] = true;
				});
			});
		});

		return entireTradesIds;
	}
	handleObjectCreateCancel() {
		this.setState({ sideCardObject: null, sideStackObjectId: null });
	}

	handleObjectCreate(newObjectGroupId, newObjectId) {
		// gets called twice: once when click addWorker and once on save after the object is created;
		this.handleAddObjectClick(newObjectGroupId, null, newObjectId);
	}

	handleCardChangeStatus(isDisable) {
		this.setState({ cardIsDisabled: isDisable });
	}

	handleObjectDelete(wasDeleted, objectId) {
		const { updatedPropertiesMap } = this.state;
		if (!wasDeleted) return;

		if (updatedPropertiesMap && updatedPropertiesMap[wasDeleted]) {
			const newUpdatedProperties = Object.values(updatedPropertiesMap)
				.filter(prop => prop.parentId === objectId)
				.map(prop => (prop.isDeleted = true));
			this.onLocalInstancesChange(newUpdatedProperties);
		}

		this.setState({ sideCardObject: null, sideStackObjectId: null });
	}

	onLocalInstancesChange(newInstancesMap) {
		const { updatedPropertiesMap: prevUpdatedProperties, newPropInstanceIDs: prevNewPropInstanceIDs } = this.state;
		let updatedPropertiesMap = { ...prevUpdatedProperties };
		let newPropInstanceIDs = { ...prevNewPropInstanceIDs };

		Object.values(newInstancesMap).forEach(newInstance => {
			delete newInstance.valueScope;
			newInstance.updatedTS = Date.now();
			if (newInstance.isDeleted) {
				delete newPropInstanceIDs[newInstance.id];
				delete updatedPropertiesMap[newInstance.id];
			} else {
				if (!newPropInstanceIDs[newInstance.id]) newPropInstanceIDs[newInstance.id] = newInstance.id;

				updatedPropertiesMap[newInstance.id] = newInstance;
			}
		});
		this.setState({ updatedPropertiesMap, newPropInstanceIDs });
	}

	getRelevantLocalInstancesByPropId(updatedPropertiesMap, objectId) {
		let localInstancesByPropertyMap = {};

		Object.values(updatedPropertiesMap).forEach(propInstance => {
			if (propInstance.parentId !== objectId) return;
			localInstancesByPropertyMap[propInstance.propId] = propInstance;
		});

		return localInstancesByPropertyMap;
	}

	defineCardType(subjectType) {
		let cardType;

		switch (subjectType) {
			case 'forms':
				cardType = 'forms';
				break;
			case 'members':
				cardType = 'member';
				break;
			case 'companies':
				cardType = 'company';
				break;
			case 'locationsGroupsManagement':
				cardType = 'locationsGroup';
				break;
			case 'posts':
				cardType = 'post';
				break;
			default:
				cardType = 'connectedObjectProperties';
		}

		return cardType;
	}

	calcSideCardObject = (objectId, objectType, editMode = false, options) => {
		const {
			subjectType,
			filters,
			primaryColumn,
			updatedPropertiesMap = {},
			formType,
			formTemplateId,
			allProps,
			cardIsDisabled,
		} = this.state;
		const { startToast, contentType, location } = this.props;
		const {
			scrollTargetSectionId,
			objectExtraInfo,
			tableMethods,
			isCreation,
			isFiredByUser = false,
			callback,
			initialGroupId,
			employeesPresence,
			hideCertifications,
		} = options || {};

		let subjectName = subjectType + 'Info';
		let filteredProperties = null;
		const columnVisibility = _.get(filters, 'columnVisibility');
		if (columnVisibility) {
			filteredProperties = {};
			_.entries(columnVisibility).forEach(([propertyId, propertyShowOptions]) => {
				if (propertyShowOptions.card) filteredProperties[propertyId] = true;
			});
		}

		const itemType = new URLSearchParams(location?.search || '').get('itemType');
		const cardType = this.defineCardType(subjectType);
		const targetObject = utils.getTargetObject({ allProps, objectId, subjectType, options, contentType, itemType });

		const sideCardObject = {
			type: cardType,
			props: {
				tableMethods: tableMethods ? tableMethods : undefined,
				objectId,
				subjectName,
				subjectType,
				objectExtraInfo: Object.assign({ formType, formTemplateId }, objectExtraInfo),
				allProps,
				initialGroupId,
				scrollTargetSectionId,
				primaryPropId: primaryColumn ? primaryColumn.id : null,
				filteredProperties,
				localInstancesByPropertyMap: this.getRelevantLocalInstancesByPropId(updatedPropertiesMap, objectId),
				dataWasImported: Boolean(Object.keys(updatedPropertiesMap).length),
				objectType, // used only by UsersManagerCard
				employeesPresence,
				hideCertifications,

				isDisabled: isCreation ? false : !editMode,
				createObjectMode: isCreation ? subjectType : undefined,
				creation: Boolean(isCreation),

				onLocalInstancesChange: this.onLocalInstancesChange,
				onDisableChange: this.handleCardChangeStatus,
				onMemberUpdate: this.onMemberUpdate,
				onCompanyUpdate: this.onCompanyUpdate,
				onGroupUpdate: this.onGroupUpdate,
				disableEditMode: this.handleConfirmCancel,
				onObjectCreate: isCreation ? this.handleObjectCreate : undefined,
				onCancel: isCreation ? this.handleObjectCreateCancel : undefined,
				onSave: !isCreation ? this.handleSave : undefined,
				onDeleteObject: !isCreation ? this.handleObjectDelete : undefined,
				setHasUnSavedChanges: this.setHasUnSavedChanges,
				targetObject,
			},
		};

		if (this.state.sideCardObject && !cardIsDisabled && (isFiredByUser || isCreation)) {
			startToast({
				overlay: true,
				mandatory: true,
				title: systemMessages.manage.leaveWithoutSave,
				message: systemMessages.manage.changesHaveNotBeenSaved,
				actions: [
					{
						message: systemMessages.yes,
						color: 'success',
						onClick: () => {
							if (callback) callback(sideCardObject);
							this.setState({ sideCardObject });
						},
					},
					{ message: systemMessages.no },
				],
			});

			return null;
		} else this.setState({ sideCardObject, sideStackObjectId: objectId });

		return sideCardObject;
	};

	handleDisplayCarousel = (itemsArr, pdfMode) => {
		this.setState({ isCarouselVisible: true, isPDFCarousel: pdfMode, carouselItems: itemsArr });
	};

	handleCloseCarousel = () => {
		this.setState({ isCarouselVisible: false, isPDFCarousel: null, carouselItems: null });
	};

	getSideCardObject = (cellData, isAggregatedCell, selectedCell, tableMethods, callback, editMode = false) => {
		const objectId = cellData.row.id;
		const sideCardOptions = _.get(cellData, ['row', 'sideCardOptions'], {});
		const { subjectType } = this.state;
		if (subjectType === 'forms') {
			const form =
				((this.lokiObjectAnalytics && this.lokiObjectAnalytics.cementoFind({ id: objectId })) || [])[0] || null;
			const formTemplateId = _.get(form, 'formTemplateId');
			const formTemplate = this.props.getNested(['configurations', 'forms', formTemplateId], {});
			if (_.get(form, 'isDailyReportV1') || !formTemplate.hasProperties) {
				if (_.get(form,['uri'])) this.handleDisplayCarousel([{ src: form.uri }], true);
				else
					onError({
						errorMessage: `closed form is missing pdf uri`,
						methodMetaData: {
							args: { cellData },
							name: 'getSideCardObject',
						},
						errorMetaData: { objectId, subjectType, object: form, formTemplate },
						alertParams: {
							title: pdfMessages.missingPdf.title,
							message: pdfMessages.missingPdf.message,
							overlay: true,
							actions: [{ message: systemMessages.ok, color: 'success' }],
						},
					});

				return null;
			}
		}

		return this.calcSideCardObject(objectId, cellData.row.objectType, editMode, {
			...(sideCardOptions || {}),
			tableMethods,
			scrollTargetSectionId: cellData.getNested('column', 'mainColumnId'),
			callback,
		});
	};

	handleAddObjectClick = (initialGroupId, isFiredByUser, newObjectId, initialObjectData) => {
		const { contentType, selectedProjectId } = this.props;
		const { subjectType, formType, allProps } = this.state;
		if (newObjectId) {
			// show card of newly created object
			this.calcSideCardObject(newObjectId, contentType, false);
			return;
		}

		const formRequestedReportDate = _.get(initialObjectData, 'reportDate');
		let id = null;
		if (subjectType === 'employees') id = getNewId();
		else if (subjectType === 'equipment') id = getNewId();
		else if (subjectType === 'forms') {
			if (!formRequestedReportDate) {
				this.setState({ isShowSelectDateModal: true });
				return;
			} else {
				const existingForm = _.find(
					_.values(allProps),
					form => !_.get(form, 'isDeleted') && _.get(form, 'reportDate') === formRequestedReportDate,
				);
				if (_.get(existingForm, 'id')) {
					const formStatusParams = getFormStatusParams(existingForm);
					this.calcSideCardObject(existingForm.id, contentType, formStatusParams.isEditable);
					return;
				} else id = getNewId();
			}
		}

		this.calcSideCardObject(id, contentType, true, {
			isCreation: true,
			isFiredByUser,
			objectExtraInfo: { formInitStatus: 300, reportDate: formRequestedReportDate },
			initialGroupId,
		});
	};

	async handleSaveFilter(filterProps, isDelete) {
		const { selectedProjectId, contentType, setProjectCombinedMenus, location } = this.props;
		const { filters, selectedFilterSet, subjectType } = this.state;

		const searchParams = new URLSearchParams(location.search);
		const groupId = searchParams.get('groupId') || null;
		const subGroupId = searchParams.get('subGroupId') || null;

		let query = null;
		if (groupId) {
			query = `groupId=${groupId}`;
			if (subGroupId) {
				query += `&subGroupId=${subGroupId}`;
			}
		}

		await saveMenus(
			selectedProjectId,
			filters,
			{ label: selectedFilterSet.label, id: (filterProps || {}).id, num: parseInt(selectedFilterSet.num || '0'), query },
			contentType,
			'analytics',
			subjectType,
			isDelete,
		);
		await setProjectCombinedMenus(selectedProjectId, true);
	}

	handleUpdateFilters(filtersChange) {
		this.setState({ filters: filtersChange });
	}

	handleUpdateSelectedFilterSet(selectedFilterSet) {
		let stateChanges = { selectedFilterSet };
		this.setState(stateChanges);
	}

	handleFilterChange = text => {
		const { filters } = this.state;
		const newFilters = {
			...filters,
			textFilter: text,
		};
		this.setState({ filters: newFilters });
	};

	handleFilterClear() {
		this.handleFilterChange('');
	}

	closeImageCarousel() {
		this.setState({ displayPdf: false });
	}

	async exportRegistrationForm() {
		const { project, viewer, exportFormPDF, startLoading } = this.props;
		let displayPdf = false;
		try {
			startLoading({ title: systemMessages.loadingMessage, overlay: true });
			let id = getNewId()
			let form = { id, formTemplateId: '-certificatesList', type: 'others' };
			await upsertForm(project.id, viewer, form, form.type);
			let res = await exportFormPDF({ viewer, project, formId: id, formType: form.type, isListenerMode: true });
			if (res && res.uri) displayPdf = res.uri;
		} catch (error) {
			console.log('failed to create registration form :', error);
		} finally {
			if (this.state.displayPdf != displayPdf) this.setState({ displayPdf });
		}
	}
	getExcelExportComponent = (additionalItems = []) => {
		const { configurationMode } = this.props;
		const { subjectType, isEditMode } = this.state;
		let isAdmin = configurationMode;
		const importSettings = {
			importMethods: [
				{ id: 'default', label: 'Import', viewerPermissions: [{ adminMode: 1 }] },
				{ id: WITH_IDS, label: 'Import with IDs', viewerPermissions: [{ adminMode: 1 }] },
				{ id: WITH_FILES, label: 'Import with Files', viewerPermissions: [{ adminMode: 1 }] },
				{
					id: `${WITH_FILES}_${WITH_IDS}`,
					label: 'Import with Files and IDs',
					viewerPermissions: [{ adminMode: 1 }],
				},
			],
		};
		const exportSettings = {
			exportMethods: [
				{
					id: 'default',
					label: systemMessages.manage.excelExport,
					getDataToExportFunc:
						subjectType == 'locationsGroupsManagement'
							? this.prepareLocationsGroupDataForExport
							: () => this.prepareDataForExport(null, true),
				},
				...additionalItems,
				...(additionalItems.length || subjectType === 'locationsGroupsManagement'
					? []
					: [
							{
								id: WITH_IDS,
								label: 'Export with IDs',
								getDataToExportFunc: () => this.prepareDataForExport(WITH_IDS),
								viewerPermissions: [{ adminMode: 1 }],
							},
							{
								id: `${WITH_FILES}`,
								label: 'Export with Files',
								getDataToExportFunc: () => this.prepareDataForExport(`${WITH_FILES}`),
								viewerPermissions: [{ adminMode: 1 }],
							},
							{
								id: `${WITH_FILES}_${WITH_IDS}`,
								label: 'Export with File DB Paths',
								getDataToExportFunc: () => this.prepareDataForExport(`${WITH_FILES}_${WITH_IDS}`),
								viewerPermissions: [{ adminMode: 1 }],
							},
							{
								id: `${WITH_FILE_URLS}`,
								label: 'Export with File URLs',
								getDataToExportFunc: () => this.prepareDataForExport(WITH_FILE_URLS),
								viewerPermissions: [{ adminMode: 1 }],
							},
				]),
			],
		};

		return (
			<>
				{Boolean(isAdmin || ['locations', 'forms', 'posts'].includes(subjectType)) && (
					<StandardInput
						type={'Excel'}
						containerStyle={{ flex: 0 }}
						label={systemMessages.manage.exportTitle}
						settings={exportSettings}
						innerStyle={{ marginRight: theme.margin - 5 }}
					/>
				)}
				{Boolean(isEditMode && isAdmin) && (
					<StandardInput
						type={'Excel'}
						innerStyle={{ flex: 0 }}
						containerStyle={{ flex: 0 }}
						settings={importSettings}
						onChange={this.onExcelImport}
					/>
				)}
			</>
		);
	};
	getFilterMenuComponent(nextProps, nextState) {
		const { intl, classes, configurationMode, rtl, contentType, lang } = nextProps;
		const { subjectType, filters } = nextState;
		const { textFilter } = filters || {};
		const { isEditMode, hasModifications, columns, categoryFilters, employeesPresenceData } = nextState;
		const projectCreationDate = nextProps.getNested(['projects', nextProps.selectedProjectId, 'createdAt']);
		const isSiteControlTable = contentType === 'siteControl';
		const isLocationsPropertiesTable = subjectType === "locations";
		const urlParams = this.getUrlParams();

		let filtersMenu = null;
		let excelImportExport = null;
		let editModeToggle = null;
		let siteControlHeaderComponents = null;
		let filterStyle = {
			display: 'flex',
			flexDirection: 'row-reverse',
			justifyContent: 'flex-start',
			[rtl ? 'marginLeft' : 'marginRight']: theme.verticalMargin,
			alignItems: 'center',
		};
		let isAdmin = configurationMode;
		const isShowEditButton = !nextState.isPostRelated && !['settings', 'forms'].includes(contentType);
		const isShowAddButton = Boolean(subjectType) && !(_.get(filters, ['isSubTable']) || isSiteControlTable || isLocationsPropertiesTable);
		const textFilterComponent = (
			<TextFilter
				containerStyle={{ maxWidth: 250 }}
				defaultValue={textFilter}
				onChange={this.handleFilterChange}
				clearFilterVal={this.handleFilterClear}
			/>
		);

		if (isAdmin && !isSiteControlTable) {
			filtersMenu = this.getAdminFilterComponent(nextProps, nextState);
			editModeToggle = (
				<EditModeToggle
					onClickEdit={() => this.setEditMode(true)}
					onClickSave={this.handleSave}
					onClickCancel={this.handleCancel}
					hasModifications={hasModifications}
					isEditMode={isEditMode}
					mainContainerStyle={{ margin: '0 ' + theme.margin + 'px' }}
				/>
			);
		}
		excelImportExport = this.getExcelExportComponent();

		if (isSiteControlTable) {
			editModeToggle = null;
			const monthPickerRenderFunc = _props => {
				const options = _.map(_props.options, o => ({ value: o.id, label: o.title }));
				const selectedValueId = _.head(_.values(_props.value));
				const value = _.head(_.filter(options, option => option.value === selectedValueId));
				const props = {
					..._props,
					options,
					value,
					styles: {
						...theme.selectStyles,
						container: styles => ({
							...theme.selectStyles.container(styles),
							marginRight: 0,
						}),
					},
				};

				return <Select {...props} />;
			};

			return (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						flex: 1,
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<CamerasMonitor monitorData={_.get(employeesPresenceData, ['monitor'])} lang={lang}/>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
						{textFilterComponent}
						<div
							style={{
								display: 'flex',
								flexDirection: 'row-reverse',
								justifyContent: 'flex-start',
								paddingLeft: theme.padding,
								paddingRight: theme.padding,
								alignItems: 'center',
								width: 200,
							}}
						>
							<MonthPicker
								shouldHideTitle
								minTS={projectCreationDate}
								value={_.get(this.selectedRange, ['startTS'])}
								onChange={this.handleMonthPicker}
								placeholder={intl.formatMessage(analyticsMessages.viewType.filterByDate)}
								renderFunc={monthPickerRenderFunc}
							/>
						</div>
					</div>
				</div>
			);
		}

		if (subjectType && subjectType != 'locations') {
			const hasIsActiveColumn = _.values(this.getBusinessTypesColumn(columns, null, 'isActive')).length === 1;
			const isShowActiveOnlyFilter = hasIsActiveColumn;

			const isShowFilterExpiration =
				!['settings', 'forms', 'issues', 'records'].includes(contentType) &&
				!['tasks', 'records'].includes(urlParams.itemType);
			filtersMenu = (
				<div
					style={{
						display: 'flex',
						flex: 1,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-end',
					}}
				>
					{textFilterComponent}
					<FilterMenuHOC
						hideEmptyCategory
						filters={categoryFilters || []}
						buttonStyle={{ margin: theme.margin }}
						pathDelimeterOverwrite={FILTER_MENU_PATH_DELIMETER}
						filterUrlKey={FILTER_URL_KEY}
					/>
					{Boolean(isShowActiveOnlyFilter) && (
						<div style={{ ...filterStyle, alignItems: 'center' }}>
							<span>{intl.formatMessage(safetyMessages.activeOnly)}</span>
							<Checkbox
								onChange={() => {
									let newFilters = Object.assign({}, filters, { activeOnly: !(filters && filters.activeOnly) });
									this.setState({ filters: newFilters });
								}}
								checked={Boolean(filters && filters.activeOnly)}
								checkedIcon={<Check className={classes.checkedIcon} />}
								icon={<Check className={classes.uncheckedIcon} />}
								style={{ cursor: 'pointer' }}
								classes={{ checked: classes.checked }}
							/>
						</div>
					)}
					{Boolean(isShowFilterExpiration) && (
						<>
							<div style={{ ...filterStyle, alignItems: 'center' }}>
								<span>{intl.formatMessage(safetyMessages.expiredSoon)}</span>
								<Checkbox
									onChange={() => {
										let newFilters = Object.assign({}, filters, {
											filterExpiration: !(filters && filters.filterExpiration),
										});
										this.setState({ filters: newFilters });
									}}
									checked={Boolean(filters && filters.filterExpiration)}
									icon={<Check className={classes.uncheckedIcon} />}
									checkedIcon={<Check className={classes.checkedIcon} />}
									style={{ cursor: 'pointer' }}
									classes={{ checked: classes.checked }}
								/>
							</div>
						</>
					)}
					{filtersMenu}
					{nextState.isPostRelated ? 
						<ViewSelector 
							key={'PropAnalytics_ViewSelector'} 
							activeViewType={this.state.viewType} 
							onChange={(viewType) => this.setState({ viewType })} 
						/> : null}
				</div>
			);
		}
		return (
			<>
				{Boolean(subjectType == 'employees' && !isSiteControlTable && lang == "he") && (
					<HoverWrapper
						props={{ color: 'inherit' }}
						hoverProps={{ color: theme.brandPrimary }}
						renderItem={({ color }) => (
							<div
								style={{
									display: 'flex',
									flex: 1,
									alignItems: 'center',
									justifyContent: 'flex-end',
									cursor: 'pointer',
								}}
								onClick={this.exportRegistrationForm}
							>
								<PrintOutlined style={{ fontSize: 18, lineHeight: '18px', margin: 5, color }} />
								<Text style={{ fontWeight: 700, color }}>{pdfMessages.exportRegistrationForm}</Text>
							</div>
						)}
					/>
				)}
				{filtersMenu}
				{Boolean(contentType !== 'settings' && excelImportExport) && excelImportExport}
			
				{Boolean(isShowAddButton) && (
					<AddNewButton
						values={{ contentType: safetyMessages.objectsNames[subjectType] }}
						onClick={() => this.handleAddObjectClick(null, true)}
						title={
							nextState.isPostRelated
								? postsMessages.button?.[(contentType === 'issues' || urlParams.itemType === 'tasks') ? 'newIssue' : 'newRecord']
								: subjectType === 'forms'
									? reportsMessages.newReport.title
									: systemMessages.addObject
						}
						style={{
							fontWeight: theme.strongBold,
							margin: `0 ${theme.verticalMargin}px`,
						}}
					/>
				)}
				{Boolean(isShowEditButton && isAdmin && editModeToggle) && editModeToggle}
				{Boolean(siteControlHeaderComponents) && siteControlHeaderComponents}
			</>
		);
	}

	setEditMode(bool) {
		this.setState({ isEditMode: bool });

		if (!bool) this.onEditModeOff();
	}

	onEditModeOff() {
		let stateChanges = {};

		stateChanges.hasModifications = false;
		stateChanges.updatedPropertiesMap = {};
		stateChanges.newPropInstanceIDs = {};

		this.setState(stateChanges, () => this.onSetHasModifications(false));
	}

	onSetHasModifications(bool) {
		const { onDraftModeChange } = this.props;
		onDraftModeChange(bool);
	}

	handleSave() {
		const { startToast } = this.props;
		const { hasModifications } = this.state;
		const { title, content, yes, no } = systemMessages.confirmSaveChangesAlert;

		if (hasModifications) {
			startToast({
				overlay: true,
				mandatory: true,
				title: title,
				message: content,
				actions: [{ message: yes, onClick: this.handleConfirmSave, color: 'success' }, { message: no }],
			});
			return;
		}

		this.handleConfirmSave();
	}

	handleConfirmSave() {
		const { selectedProjectId, startLoading, hideLoading, uploadPropertiesInstances, startToast, intl } = this.props;
		const { updatedPropertiesMap, hasModifications, subjectType } = this.state;

		if (hasModifications) {
			// TODO: change for support of employees and equipment
			const updatedPropertiesArr = Object.values(updatedPropertiesMap);
			let propertiesHaveValidTypes = true;
			updatedPropertiesArr.forEach(prop => {
				if (!propertiesHaveValidTypes) return;

				if (!validatePropType(prop.propType)) propertiesHaveValidTypes = false;
			});

			if (!propertiesHaveValidTypes) {
				startToast({ title: systemMessages.errorOnSave, type: 'error' });
				return;
			}

			const subjectName = subjectType + 'Info';
			const callback = success => {
				hideLoading();
				if (!success) {
					startToast({ title: systemMessages.errorOnSave, type: 'error' });
					return;
				}

				startToast({
					title: systemMessages.objectSavedSuccessfully,
					type: 'success',
					values: { objectName: intl.formatMessage(systemMessages.properties) },
				});
			};
			startLoading({ title: systemMessages.loadingMessage, overlay: true });
			// setTimeout otherwise startLoading doesnt start;

			setTimeout(() => {
				uploadPropertiesInstances(selectedProjectId, updatedPropertiesArr, subjectName, callback);
			}, 1);
		}

		this.setEditMode(false);
	}

	handleCancel() {
		const { startToast } = this.props;
		const { hasModifications } = this.state;
		const { title, content, yes, no } = systemMessages.confirmCancelChangesAlert;

		if (hasModifications) {
			startToast({
				overlay: true,
				mandatory: true,
				title: title,
				message: content,
				actions: [{ message: yes, onClick: this.handleConfirmCancel, color: 'success' }, { message: no }],
			});
			return;
		}

		this.handleConfirmCancel();
	}

	handleConfirmCancel() {
		this.setEditMode(false);
	}

	prepareLocationsGroupDataForExport() {
		const { tableWrapperProps } = this.state;
		const { selectedProjectId, buildings, floors, units, intl, rtl } = this.props;
		const excelSheetStructure = {
			id: { name: 'ID', types: ['String'], style: { width: largeWidth } },
			description: { name: 'Description', types: ['String'], style: { width: largeWidth } },
			type: { name: 'Type', types: ['String'], style: { width: smallWidth } },
			locations: { name: 'Locations', types: ['String'], style: { width: 60 } },
		};
		const exportLocationsTitle = setFlatLocationsTitlesMap(buildings, floors, units, intl);

		let data = {
			locations: {
				sheetStructure: excelSheetStructure,
				rowsData: [],
				sheetView: [{ state: 'frozen', xSplit: 2, ySplit: 1, rightToLeft: rtl }],
			},
		};

		let originalRows = _.get(tableWrapperProps, ['originalData', 'rows'], []);
		let tableColumns = _.get(tableWrapperProps, ['columns', 0, 'columns'], []);
		let groupsMap = {};

		tableColumns.forEach(column => {
			let { original } = column;
			if (column.id !== 'locations-group-table-locationsColumn') return;
			Object.values(_.get(original, ['rows'], {})).forEach(row => {
				if (Object.keys(row.subRows || {}).length) {
					Object.values(row.subRows || {}).forEach(subRow => {
						let locationsArray = subRow.title.split(',');
						let exportTitleArray = [];
						_.forEach(locationsArray, loc => {
							let location = _.find(
								exportLocationsTitle[row.id],
								exportLocation => exportLocation.title.trim() == loc.trim(),
							);
							if (location) exportTitleArray.push(location.exportTitle);
						});
						_.set(groupsMap, [subRow.id, 'locations'], String(exportTitleArray) || subRow.title);
					});
				}
			});
		});

		originalRows.forEach(row => {
			if (row.rowLevel === 1) return;
			data.locations.rowsData.push({
				id: row.id,
				description: row.title,
				type: row.parentId,
				locations: _.get(groupsMap, [row.id, 'locations'], ''),
			});
		});

		return { fileName: `locations_groups_${selectedProjectId}`, data };
	}

	filterForExport = (rowsMap) => {
		const { filters, subjectType } = this.state;

		let filteredRowsMap = rowsMap;

		const textFilter = filters?.textFilter?.trim().toLowerCase();
		if (textFilter) filteredRowsMap = this.filterRowsByText(filteredRowsMap, textFilter);

		const isActiveFilter = filters.activeOnly;
		if (isActiveFilter) {
			filteredRowsMap = _.pickBy(filteredRowsMap, (row => {
				const propIds = Object.keys(row.values || {});
	
				for (const propId of propIds) {
					const universalId = this.props.getNested(['propertiesTypes', subjectType + 'Info', propId, 'universalId']);
					if (universalId === propertyTypes.UNIVERAL_IDS.isActive && row.values[propId]?.displayValue === true) {
						return true;
					}
				}
			}));
		}

		const isFilterExpration = filters.filterExpiration;
		if (isFilterExpration) filteredRowsMap = _.pickBy(filteredRowsMap, (row => {
			const values = row.values || []
			return Object.values(values).some(val => val.displayParams?.isWarning || val.displayParams?.isExpired)
		}))

		return filteredRowsMap
	}

	prepareDataForExport(mode, isFromTableDataObject = false) {
		const { subjectType, selectedFilterSet, tableDataObject, allProps, originalData, primaryColumn, filters } = this.state;
		const { buildings, floors, units, intl, rtl, project, selectedProjectId, location, trades, contentType } = this.props;

		const sortFunc = getSortFunc({
			subjectType,
			contentType,
			primaryColumn,
			trades,
		});

		let filteredRowsMap = getFilteredRowsMap({
			searchQuery: location?.search,
			originalDataRows: originalData.rows,
			delimeter: FILTER_MENU_PATH_DELIMETER,
		});


		filteredRowsMap = this.filterForExport(filteredRowsMap)

		if (isFromTableDataObject && !mode) {
			const result = this.getExportDataFromTableObject(tableDataObject, filteredRowsMap, sortFunc);
			return result;
		}

		mode = mode || '';
		const isWithIdsMode = mode.indexOf(WITH_IDS) !== -1;
		const isWithFilesMode = mode.indexOf(WITH_FILES) !== -1;
		const isWithFileUrlsMode = mode.indexOf(WITH_FILE_URLS) !== -1;
		const sheetName = 'Properties';

		let relevantPopulatedObjects = _.values(allProps || {}).filter(
			o => filteredRowsMap[o.id] && !_.isNil(_.get(o, ['props', 'groups', 'data'])),
		);

		if (sortFunc)
			relevantPopulatedObjects.sort((obj1, obj2) => {
				return sortFunc({...obj1, order: _.get(filteredRowsMap, [obj1.id, 'order'])}, {...obj2, order: _.get(filteredRowsMap, [obj2.id, 'order'])});
			});

		let primaryColumns = [];
		let columnsToExport = {};
		if (subjectType === 'locations') {
			primaryColumns = [
				intl.formatMessage(buildingsMessages.building),
				intl.formatMessage(floorsMessages.floor),
				intl.formatMessage(unitsMessages.unit),
			];

			let selectedMenuColumns = _.get(selectedFilterSet, ['values', 'columnVisibility'], {});
			Object.entries(selectedMenuColumns).forEach(([colId, options]) => {
				if (_.get(options, ['table'], false)) columnsToExport = _.set(columnsToExport, colId, true);
			});
		} else {
			if (isWithIdsMode) primaryColumns = ['ID'];
			else {
				primaryColumns = relevantPopulatedObjects.reduce((acc, populatedObject) => {
					const currPrimaryColName = populatedObject.getNested(['primaryProp', 'propTitle'], null);
					if (!currPrimaryColName || acc.includes(currPrimaryColName)) return acc;

					return [...acc, currPrimaryColName];
				}, []);
			}
		}

		let data = {
			[sheetName]: {
				rowsData: [],
				sheetStructure: {},
				sheetView: [{ state: 'frozen', xSplit: primaryColumns.length, ySplit: 1, rightToLeft: rtl }],
			},
		};

		primaryColumns.forEach(
			colName => (data = data.setNested([sheetName, 'sheetStructure', colName], { name: colName })),
		);
		const titlesMap = subjectType === 'locations' ? getAllLocationTitlesMap(buildings, floors, units, intl) : null;

		const getInstanceDataPath = instanceId =>
			`properties/instances/projects/${selectedProjectId}/${subjectType + 'Info'}/${instanceId}/data`;

		let dynamicSheetStructurePerSection = {};
		relevantPopulatedObjects.forEach(populatedObject => {
			const { props: properties, id } = populatedObject;
			let rowData = {};

			if (isWithIdsMode) rowData['ID'] = id;

			if (subjectType === 'locations') {
				const { buildingTitle, unitTitle, floorTitle } = titlesMap[id];
				[buildingTitle, floorTitle, unitTitle].loopEach(
					(index, value) => (rowData = rowData.setNested([primaryColumns[index]], value)),
				);
			}

			Object.values(properties || {})
				.sort(
					(propA, propB) => (propA.fullProp && propA.fullProp.ordinalNo) - (propB.fullProp && propB.fullProp.ordinalNo),
				)
				.forEach(property => {
					let propId = property.fullProp && property.fullProp.id;
					if (propId === 'groups' || (subjectType === 'locations' && !_.get(columnsToExport, [propId], false))) return;

					let { data: propData, dataText, section, propTitle, type, lastCertExpirationTS, instanceId } = property;

					if (!_.isNil(propData))
						switch (type) {
							case propertyTypes.DATE:
								dataText = new Date(propData);
								break;

							case propertyTypes.PICTURE:
							case propertyTypes.VIDEO:
							case propertyTypes.PDF:
							case propertyTypes.FILES_ARRAY:
							case propertyTypes.DRAWINGS_ARRAY:
								if (isWithFilesMode) dataText = getInstanceDataPath(instanceId);
								else if (isWithFileUrlsMode) {
									let filesData = propData;
									if (type === propertyTypes.PICTURE || type === propertyTypes.VIDEO) filesData = [{ uri: propData }];
									else if (type === propertyTypes.PDF) filesData = [propData];

									filesData = (Array.isArray(filesData) ? filesData : [filesData]).filter(Boolean);

									dataText = filesData.length
										? filesData
												.filter(fileMeta => Boolean(fileMeta))
												.map(fileMeta => fileMeta.uri)
												.filter(Boolean)
												.join(', ')
										: null;
								} else {
									let filesData = (Array.isArray(propData) ? propData : [propData]).filter(Boolean);

									dataText = filesData.length
										? intl.formatMessage(systemMessages.fileCount, { filesCounter: filesData.length })
										: null;
								}
								break;

							case propertyTypes.CERTIFICATION:
								if (isWithFilesMode) dataText = getInstanceDataPath(instanceId);
								else if (isWithFileUrlsMode) {
									let lastCert = Array.isArray(propData) && _.last(propData);
									if (lastCert && lastCert.attachments)
										dataText = lastCert.attachments
											.map(fileMeta => fileMeta.data || fileMeta.uri)
											.filter(Boolean)
											.join(', ');
									else dataText = null;
								} else dataText = lastCertExpirationTS ? new Date(lastCertExpirationTS) : null;
								break;

							case propertyTypes.BOOLEAN:
								dataText = !_.isNil(propData) ? Boolean(propData) : null;
								break;

							case propertyTypes.STRING:
								dataText = propData
									? convertCementoML(
											propData,
											string => string,
											string => string,
											'\n',
									  )
											.filter(Boolean)
											.join('')
									: null;
								break;

							default:
								break;
						}

					const sectionTitle = section.getNested(['title'], '').split('\n').join(' ').trim();
					const isPrimaryColumn = primaryColumns.includes(propTitle);
					let columnName = null;
					let richColName = [];
					if (isPrimaryColumn) {
						columnName = propTitle;
					} else {
						columnName = `${sectionTitle}\n${propTitle.split('\n').join(' ').trim()}`;
						richColName.push({ text: sectionTitle + '\n', style: { color: theme.brandPrimary, bold: true } });
					}

					richColName.push({ text: propTitle.split('\n').join(' ').trim(), style: { bold: true } });
					rowData[columnName] = dataText;

					if (!dynamicSheetStructurePerSection.getNested([sectionTitle, columnName], false))
						dynamicSheetStructurePerSection = dynamicSheetStructurePerSection.setNested([sectionTitle, columnName], {
							name: richColName,
							style: { alignment: { wrapText: true } },
							ordinalNo: isPrimaryColumn ? 0 : property.fullProp.ordinalNo,
						});
				});

			data[sheetName].rowsData.push(rowData);
		});

		let orderedSheetStructure = {};
		dynamicSheetStructurePerSection.loopEach((sectionTitle, sheetStructure) => {
			orderedSheetStructure = {
				...orderedSheetStructure,
				['emptyCol' + sectionTitle]: {
					name: '',
					style: {
						width: 2,
						backgroundColor: '#d9d9d9',
					},
					ordinalNo: _.chain(orderedSheetStructure).values().last().get('ordinalNo').value(), // these are styling columns, we give same ordinalNo as last column of the section so they stick together later when they get ordered
				},
				...sheetStructure,
			};
		});
		data = data.setNested([sheetName, 'sheetStructure'], {
			...data[sheetName].sheetStructure,
			...orderedSheetStructure,
		});

		return {
			fileName: `${(project || {}).title || selectedProjectId}_${
				subjectType === 'locations' ? 'units' : subjectType
			}_properties`,
			data,
		};
	}

	filterRowsByText = (filteredRowsMap, textFilter) => {
		const { originalColumns} = this.state;
		let newFilteredRowsMap = {};

		let textFilterColumnIds = [];
		if (textFilter) {
			textFilterColumnIds = _.values(originalColumns).map(column => column.id);
			Object.values(filteredRowsMap).forEach(row => {
				if (!row) return;
				return textFilterColumnIds.some(columnId => {
					const rowColValue = String(row.values[columnId]?.textValue || '');
					if (!rowColValue) return;
					if (rowColValue.trim().toLowerCase().includes(textFilter)) newFilteredRowsMap[row.id] = row;
				});
			})
		}
		
		return newFilteredRowsMap;
	}

	onExcelImport(excelObj, mode) {
		// 'default || withIds'
		const { updatedPropertiesMap = {}, newPropInstanceIDs = {} } = this.state;
		const { startLoading, hideLoading, startToast } = this.props;

		startLoading({ title: systemMessages.loadingMessage, overlay: true });
		// setTimeout because the process of importing takes over and startLoading doesn't start otherwise;
		setTimeout(async () => {
			try {
				let prevNewPropInstanceIDs = { ...newPropInstanceIDs };
				let prevUpdatedPropertiesMap = { ...updatedPropertiesMap };
				let { updatedPropertiesMap: newMap, newPropInstanceIDs: newInstanceIDs } =
					await this.getUpdatedPropertiesFromExcel(excelObj, mode);
				newMap.loopEach((propId, prop) => {
					if (prop.isDeleted && newPropInstanceIDs[prop.id]) {
						delete newMap[prop.id];
						delete prevUpdatedPropertiesMap[prop.id];
						delete prevNewPropInstanceIDs[prop.id];
					}
				});

				let newStateChanges = {};
				newStateChanges.updatedPropertiesMap = { ...prevUpdatedPropertiesMap, ...newMap };
				newStateChanges.newPropInstanceIDs = { ...prevNewPropInstanceIDs, ...newInstanceIDs };
				newStateChanges.hasModifications = true;
				this.setState(newStateChanges, () => {
					hideLoading();
					this.onSetHasModifications(true);
				});
				startToast({ title: systemMessages.importSuccess, type: 'success' });
			} catch (err) {
				hideLoading();
				startToast({
					title: systemMessages.importError,
					message: err,
					overlay: true,
					mandatory: true,
					actions: [{ message: systemMessages.ok }],
				});
			}
		}, 1);
	}

	/**
	 *
	 * @param {import('../Reports/tableWrapperHelpers').TableDataObject} tableDataObj
	 */
	getExportDataFromTableObject = (tableDataObject, filteredRowsMap, sortFunc) => {
		const { subjectType, selectedFilterSet } = this.state;
		const { buildings, floors, units, intl, rtl, project, selectedProjectId, trades } = this.props;
		const tradesMap = trades.toJS();
		tableDataObject = tableDataObject || this.state.tableDataObject;
		const sheetName = 'Properties';
		let primaryColumns = [];
		let columnsToExport = {};
		let locationTitlesMap = null;
		const shouldAddLocationColums = ['locations', 'posts'].includes(subjectType);
		if (shouldAddLocationColums) {
			primaryColumns = [
				intl.formatMessage(buildingsMessages.building),
				intl.formatMessage(floorsMessages.floor),
				intl.formatMessage(unitsMessages.unit),
			];

			locationTitlesMap = getAllLocationTitlesMap(buildings, floors, units, intl);

			let selectedMenuColumns = _.get(selectedFilterSet, ['values', 'columnVisibility'], {});
			Object.entries(selectedMenuColumns).forEach(([colId, options]) => {
				if (_.get(options, ['table'], false)) columnsToExport = _.set(columnsToExport, colId, true);
			});
		}

		const shouldAutoSelectPrimaryColumns = primaryColumns.length === 0;

		let data = {
			[sheetName]: {
				rowsData: [],
				sheetStructure: {},
				sheetView: [{ state: 'frozen', xSplit: primaryColumns.length, ySplit: 1, rightToLeft: rtl }],
			},
		};

		if (!shouldAutoSelectPrimaryColumns)
			primaryColumns.forEach(colName => _.set(data, [sheetName, 'sheetStructure', colName], { name: colName }));

		let dynamicSheetStructurePerSection = {};
		let rowsData = {};
		const { isCollapsableTable, sections } = tableDataObject;
		_.values(sections).forEach(section => {
			const { title: sectionTitle } = section;
			_.values(section.columns)
				.sort(({ isPrimary = false } = {}) => (isPrimary ? -1 : 0))
				.forEach(column => {
					if(subjectType === propertyTypes.SUBJECT_TYPES.posts) {
						column = {...column, isPrimary: false};
					}
					const { id: columnId, rows, isPrimary: isPrimaryColumn, title: columnTitle, num: columnOrdinalNo } = column;
					if (columnId === 'groups' || (subjectType === 'locations' && !_.get(columnsToExport, [columnId], false)))
						return;

					let columnNameId = null;
					let richColName = [];
					if (isPrimaryColumn) columnNameId = columnTitle;
					else {
						columnNameId = `${sectionTitle}\n${columnTitle.split('\n').join(' ').trim()}`;
						richColName.push({ text: sectionTitle + '\n', style: { color: theme.brandPrimary, bold: true } });
					}

					richColName.push({ text: columnTitle.split('\n').join(' ').trim(), style: { bold: true } });

					if (shouldAutoSelectPrimaryColumns && isPrimaryColumn && !primaryColumns.includes(columnNameId))
						primaryColumns.push(columnNameId);

					if (!isPrimaryColumn || shouldAutoSelectPrimaryColumns)
						_.set(dynamicSheetStructurePerSection, [sectionTitle, columnNameId], {
							name: richColName,
							style: {
								alignment: { wrapText: true },
								bold: true,
							},
							ordinalNo: isPrimaryColumn ? 0 : columnOrdinalNo,
						});
					_.values(rows).forEach(row => {
						/** @type {import('../Reports/tableWrapperHelpers').TableCell[]} */
						const rowsToGoThrough = isCollapsableTable ? _.values(row.subCells) : [row];

						rowsToGoThrough
							.forEach(row => {
							if (!filteredRowsMap[row.id]) return;
							const { value, id: rowId, ordinalNum } = row;

							if (shouldAddLocationColums) {
								_.set(rowsData, [rowId, 'order'], ordinalNum);
								try {
									const locationId =
										subjectType === 'locations'
											? rowId
											: _.get(_.keys(this.state.allProps[rowId]?.locationId), 0, null);
									const { buildingTitle = '', unitTitle = '', floorTitle = '' } = locationTitlesMap[locationId] || {};
									
									[buildingTitle, floorTitle, unitTitle].forEach((title, index) => {
										if (!_.get(rowsData, [rowId, primaryColumns[index]]))
											_.set(rowsData, [rowId, primaryColumns[index]], title);
									});
								} catch (error) {
									console.log('Error getting location id for row:', rowId, error)
								}
								if (column.isPrimary) return;
							}

							const { displayType, displayValue, displayParams } = value;

							let stringValue = null;
							switch (displayType) {
								case propertyTypes.DATE:
									if (displayValue) stringValue = moment(displayValue).format(intl.formatMessage(systemMessages.fullDateFormat));
									break;

								case 'Status': {
									stringValue = safeFormatMessage(intl, displayParams.items?.[0]?.message);
									break;
								}

								case propertyTypes.STRING:
									stringValue = displayValue;
									break;

								case 'Trade':
									stringValue = tradesMap[displayValue]?.getCementoTitle() || '-';
									break;

								case 'Files':
									if (!isEmptyValue(displayValue) && displayValue != 0)
										stringValue = intl.formatMessage(systemMessages.fileCount, { filesCounter: String(displayValue) });
									break;

								case propertyTypes.NUMBER:
								default:
									stringValue = displayValue;
									break;
							}

							if (!isEmptyValue(stringValue)) _.set(rowsData, [rowId, columnNameId], stringValue);
						});
					});
				});
		});

		_.set(data, [sheetName, 'sheetView', 0, 'xSplit'], primaryColumns.length);

		const stylingColumnTemplate = {
			name: '',
			style: {
				width: 2,
				backgroundColor: '#d9d9d9',
			},
		};
		let isPrimaryColumnStyleColumnSet = false;
		let orderedSheetStructure = {};
		let columnsOrdinalNo = 1

		const sortFuncForColumns = (colA, colB) => {
			let colAMinOrdinal = _.minBy(Object.values(colA[1]), 'ordinalNo').ordinalNo;
			let colBMinOrdinal = _.minBy(Object.values(colB[1]), 'ordinalNo').ordinalNo;
			
			return colAMinOrdinal - colBMinOrdinal;
		  };
				
		

		Object.entries(dynamicSheetStructurePerSection).sort(sortFuncForColumns).forEach(([sectionTitle, sectionSheetStructure]) => {
			let orderedSectionSheetStructure = {}
			
			Object.entries(sectionSheetStructure).sort((a, b) => a[1].ordinalNo - b[1].ordinalNo).forEach(([sheetColumnTitle, sheetColumnValue]) => {
				orderedSectionSheetStructure[sheetColumnTitle] = {
					...sheetColumnValue,
					ordinalNo: columnsOrdinalNo++
				}
			})
	
			// Set styling columns
			orderedSheetStructure = Object.assign(
				{},
				orderedSheetStructure,
				{
					['emptyCol' + sectionTitle]: {
						...stylingColumnTemplate,
						ordinalNo:
							(_.chain(orderedSectionSheetStructure).values().maxBy('ordinalNo').get('ordinalNo').value() || 0) + 0.00000001, // these are styling columns, we give same ordinalNo as last column of the section so they stick together later when they get ordered
					},
				},
				orderedSectionSheetStructure,
			);
			if (!isPrimaryColumnStyleColumnSet)
				_.values(sectionSheetStructure).forEach(column => {
					if (primaryColumns.indexOf(_.get(column, ['name', 0, 'text'])) === primaryColumns.length - 1) {
						// if it is the last of the primary columns
						isPrimaryColumnStyleColumnSet = true;
						orderedSheetStructure['emptyCol' + sectionTitle + column.name[0].text] = {
							...stylingColumnTemplate,
							ordinalNo: (column.ordinalNo || 0) + 0.00000001,
						};
					}
				});
		});

		let arrRowsData = _.values(rowsData);
		if (sortFunc) {
			arrRowsData.sort(sortFunc);
		}

		data[sheetName].rowsData = arrRowsData;
		data[sheetName].sheetStructure = Object.assign({}, data[sheetName].sheetStructure, orderedSheetStructure);

		return {
			fileName: `${(project || {}).title || selectedProjectId}_${
				subjectType === 'locations' ? 'units' : subjectType
			}_properties`,
			data,
		};
	};

	/**
	 *
	 * @param {import('../../components/CementoComponents/ImportExportExcel').CustomWorkbook} excelObj
	 * @param {string} mode
	 * @returns
	 */
	async getUpdatedPropertiesFromExcel(excelObj, mode) {
		const { subjectType, allProps } = this.state;
		const { intl, buildings, floors, units, selectedProjectId } = this.props;
		const { populatedObjectsTitlesMapByParentId, allExistingSectionsAndProps } =
			this.getMapPopulatedObjectsPropsToTitlesMapByParentId();

		const concatNames = (...namesArr) => namesArr.join('');

		mode = mode || '';
		const isWithIdsMode = mode.indexOf(WITH_IDS) !== -1;
		const isWithFilesMode = mode.indexOf(WITH_FILES) !== -1;
		let primaryColNames = [];
		let parentIdByIdentifier = {};
		if (subjectType === 'locations') {
			const titlesMap = getAllLocationTitlesMap(buildings, floors, units, intl);
			titlesMap.loopEach((locId, locInfo) => {
				const { buildingTitle, floorTitle, unitTitle, data } = locInfo;

				if (!unitTitle) return;

				parentIdByIdentifier = parentIdByIdentifier.setNested(
					[concatNames(buildingTitle, floorTitle, unitTitle)],
					data.getNested(['id']),
				);
			});

			primaryColNames = [
				intl.formatMessage(buildingsMessages.building),
				intl.formatMessage(floorsMessages.floor),
				intl.formatMessage(unitsMessages.unit),
			];
		} else {
			if (isWithIdsMode) {
				primaryColNames = ['ID'];
				_.values(allProps).forEach(populatedObject =>
					_.set(parentIdByIdentifier, [populatedObject.id], populatedObject.id),
				);
			} else
				_.values(allProps).forEach(populatedObject => {
					const objId = populatedObject.id;
					const objTitle = populatedObject.getNested(['primaryProp', 'data'], null); // if more than one primary prop, change this to be concat of all the primary column names;

					if (!objId || !objTitle) {
						console.warn('getUpdatedPropertiesFromExcel -> no objId or objTitle!!!', {
							objId,
							objTitle,
							propInfo: populatedObject,
						});
						// throw `Internal error. Please contact support ( a.k.a. Ilann ;) ).`; // TODO: changed for production translate
						return;
					}

					parentIdByIdentifier = parentIdByIdentifier.setNested([objTitle], objId);

					let primaryPropTitle = populatedObject.getNested(['primaryProp', 'propTitle']);
					if (!primaryColNames.includes(primaryPropTitle)) primaryColNames.push(primaryPropTitle);
				});
		}
		let parentObjIdentifierName;
		switch (subjectType) {
			case 'locations':
				parentObjIdentifierName = 'location';
				break;
			case 'employees':
				parentObjIdentifierName = 'employee';
				break;
			case 'equipments':
				parentObjIdentifierName = 'equipment';
				break;
			default:
				break;
		}

		let newPropInstanceIDs = {};
		let updatedPropertiesMap = {};
		let pastParentIds = {};
		await Promise.all(
			excelObj.eachSheet(async (sheetName, sheet) => {
				await Promise.all(
					sheet.eachRow(async (row, rowNumber) => {
						const parentIdentifier =
							subjectType === 'locations'
								? concatNames(...primaryColNames.map(colName => row.getValueInColumn(colName)))
								: row.getValueInColumn(primaryColNames[0]); // TODO: if more than 1 primary prop, change this to be concat of all the primary columns

						const currParentId = parentIdByIdentifier[parentIdentifier]; // TODO: get primary column values to get the id of the item we need to modify

						if (!currParentId) {
							console.error('getUpdatedPropertiesFromExcel -> primVal0)), primVal1), primVal2', {
								primVal0: row.getValueInColumn(primaryColNames[0]),
								primVal1: row.getValueInColumn(primaryColNames[1]),
								primVal2: row.getValueInColumn(primaryColNames[2]),
								cellFromGetCell0: row.getCellInColumn(primaryColNames[0]),
								cellFromGetCell1: row.getCellInColumn(primaryColNames[1]),
								cellFromGetCell2: row.getCellInColumn(primaryColNames[2]),
								row,
								rowNumber,
								parentIdByTitle: parentIdByIdentifier,
								currParentId,
								parentIdentifier,
								concat: concatNames(
									row.getValueInColumn(primaryColNames[0]),
									row.getValueInColumn(primaryColNames[1]),
									row.getValueInColumn(primaryColNames[2]),
								),
							}); // TODO: remove on production
							throw `Could not resolve ${parentObjIdentifierName}. Please check the primary column(s) in row ${rowNumber}`; // TODO: translate
						}

						if (!pastParentIds[currParentId]) pastParentIds[currParentId] = { rowNumber };
						else {
							console.error(
								`Found twice the same ${parentObjIdentifierName} in row ${pastParentIds[currParentId].rowNumber} and ${rowNumber}.`,
								{ pastParentIds, currParentId, rowNumber },
							);
							throw `Found twice the same ${parentObjIdentifierName} in row ${pastParentIds[currParentId].rowNumber} and ${rowNumber}. Please remove one and try again.`; // TODO: translate
						}

						await Promise.all(
							row.eachCell(async (cell, colName) => {
								if (primaryColNames.includes(colName)) return;

								const splitName = colName
									.split('\n')
									.map(n => n.trim())
									.filter(n => Boolean(n));
								const [sectionTitle, propTitle] = splitName;
								if (splitName.length !== 2) {
									console.error('getUpdatedPropertiesFromExcel -> splitName', splitName);
									throw `Column "${sectionTitle} ${propTitle}" does not have a section and/or property name`; // TODO: translate
								}

								if (!allExistingSectionsAndProps.getNested([sectionTitle]))
									throw `Could not find section matching "${sectionTitle}"`; // TODO: make it intl

								if (!allExistingSectionsAndProps.getNested([sectionTitle, propTitle]))
									throw `Could not find property matching "${propTitle}" in section "${sectionTitle}"`; // TODO: make it intl

								let newDataVal = cell.value;
								if (_.isNil(newDataVal)) {
									return;
								}

								if (!allExistingSectionsAndProps.getNested([sectionTitle, propTitle, currParentId])) {
									console.error('getUpdatedPropertiesFromExcel -> newDataVal', cell.value);
									throw `This object cannot be assigned the property "${propTitle}" (row ${rowNumber})`; // TODO: translate
								}

								const currPopulatedObj = populatedObjectsTitlesMapByParentId.getNested([
									currParentId,
									sectionTitle,
									propTitle,
								]);
								if (!currPopulatedObj) {
									console.error('getUpdatedPropertiesFromExcel -> currPopulatedObj', {
										currPopulatedObj,
										currParentId,
										sectionTitle,
										propTitle,
									});
									throw `Internal error. Please contact support ( a.k.a. Ilann ;) )`; // TODO: change for production
								}

								const { type, data, instanceId } = currPopulatedObj;
								let { fullProp } = currPopulatedObj;
								fullProp = fullProp && fullProp.toJS ? fullProp.toJS() : fullProp;
								if (!fullProp) {
									console.error('getUpdatedPropertiesFromExcel -> currPopulatedObj', {
										fullProp,
										currPopulatedObj,
										currParentId,
										sectionTitle,
										propTitle,
									});
									throw `Internal error. Please contact support ( a.k.a. Ilann ;) )`; // TODO: change for production
								}

								const propId = fullProp.getNested(['id']);

								const deleteProperty = newDataVal === SAFE_DELETE_KEYWORD;
								if (deleteProperty) newDataVal = data;
								else {
									switch (type) {
										case propertyTypes.STRING: {
											newDataVal = String(newDataVal);
											break;
										}

										case propertyTypes.BOOLEAN: {
											cell.checkValType([type]);
											newDataVal = Boolean(newDataVal);
											break;
										}

										case propertyTypes.NUMBER: {
											cell.checkValType([type]);
											newDataVal = Number(newDataVal);
											break;
										}

										case propertyTypes.DATE: {
											if (newDataVal && !(newDataVal instanceof Date)) {
                        throw `Unable to validate the date entered in column "${sectionTitle} ${propTitle}" row ${rowNumber}.\nPlease make sure it is properly formatted.`; // TODO: intl it
                      } else if (newDataVal) {
                        newDataVal = localTSToUTC(newDataVal);
                      }
											break;
										}

										case propertyTypes.SELECTION_LIST: {
											newDataVal = String(newDataVal);
											let options = fullProp.getNested(['values']);
											if (!options)
												throw `Could not find any options related to the property "${propTitle}" in section "${sectionTitle}"`;

											let optionId;
											let availableOptionsTitles = [];
											options.loopEach((i, option) => {
												const optionTitle = option.getCementoTitle();
												availableOptionsTitles.push(optionTitle);

												if (newDataVal.trim().toLowerCase() !== optionTitle.trim().toLowerCase()) return;

												optionId = option.id;
											});

											if (newDataVal && !optionId) {
												console.error('getUpdatedPropertiesFromExcel -> no optionId', {
													optionId,
													newDataVal,
													currPopulatedObj,
													propId,
													fullProp: fullProp.toJS ? fullProp.toJS() : fullProp,
												});
												throw `Could not find option "${newDataVal}" for property "${propTitle}" in section "${sectionTitle}".\nAvailable options are:\n${availableOptionsTitles.join(
													'\n',
												)}`; // TODO: translatte on col ... on row
											}

											newDataVal = { [optionId]: optionId };
											break;
										}

										// For now other types are not supported
										case propertyTypes.PDF:
										case propertyTypes.PICTURE:
										case propertyTypes.VIDEO:
										case propertyTypes.CERTIFICATION:
										case propertyTypes.FILES_ARRAY:
										case propertyTypes.DRAWINGS_ARRAY:
										case 'PredefinedComponent': { // TODO: add to constants once defined
											if (!isWithFilesMode)
												throw `We do not yet support file imports. Please, remove the value located in column "${sectionTitle} ${propTitle}", row ${rowNumber} and try again.`; // TODO: translate

											const instanceInfo = parsePropInstanceFirebasePath(newDataVal);
											const instance = await fetchPropertyInstanceById(instanceInfo?.id)
											newDataVal = instance?.data;
											if (!newDataVal)
												throw `We were unable to find the data that you requested at path "${newDataVal}". Please, make sure it exists and try again. (section: "${sectionTitle}; column: ${propTitle}"; row ${rowNumber})`; // TODO: translate

											break;
										}

										default:
											console.error('getUpdatedPropertiesFromExcel -> type', {
												type,
												sectionTitle,
												propTitle,
												rowNumber,
												newDataVal,
												cell,
												currParentId,
												currPopulatedObj,
												propId,
											});
											throw `Could not match the property type. Please contact support ( a.k.a. Ilann ;) )`; // TODO: change for production
									}

									if (_.isEqual(newDataVal, data || '')) {
                    return;
                  }
								}

								let newProperty = {
									id: instanceId,
									data: newDataVal,
									parentId: currParentId,
									propId,
									propType: type,
									updatedTS: Date.now(),
									isDeleted: deleteProperty ? true : null,
								};

								if (!newProperty.id && !newProperty.isDeleted) {
									const newInstanceId = getNewId();
									newProperty.id = newInstanceId;
									newProperty.createdTS = Date.now();
									newPropInstanceIDs[newProperty.id] = newProperty.id;
								}

								if (newProperty.id) updatedPropertiesMap[newProperty.id] = newProperty;
							}),
						);
					}),
				);
			}),
		);

		return { updatedPropertiesMap, newPropInstanceIDs };
	}

	getMapPopulatedObjectsPropsToTitlesMapByParentId() {
		const { allProps } = this.state;
		let allExistingSectionsAndProps = {};
		let populatedObjectsTitlesMapByParentId = {};
		_.values(allProps).forEach(populatedObject => {
			const { id: parentId, props } = populatedObject;

			if (!props) return;

			props.loopEach((propId, propInfo) => {
				if (propId === 'groups') {
					populatedObjectsTitlesMapByParentId = populatedObjectsTitlesMapByParentId.setNested2(
						[parentId, 'groups'],
						propInfo,
					);
					return;
				}

				let { propTitle = '', section: { title: sectionTitle = '' } = {} } = propInfo;
				sectionTitle = sectionTitle.split('\n').join(' ').trim();
				propTitle = propTitle.split('\n').join(' ').trim();
				allExistingSectionsAndProps = allExistingSectionsAndProps.setNested2([sectionTitle, propTitle, parentId], true);
				populatedObjectsTitlesMapByParentId = populatedObjectsTitlesMapByParentId.setNested2(
					[parentId, sectionTitle, propTitle],
					propInfo,
				);
			});
		});

		return { populatedObjectsTitlesMapByParentId, allExistingSectionsAndProps };
	}

	handleSelectDateDone = dateTS => {
		this.handleAddObjectClick(null, true, undefined, { reportDate: dateTS });
		this.setState({ isShowSelectDateModal: false });
	};

	onSideClose = () => {
		this.setState({ sideCardObject: null, sideStackObjectId: null, cardIsDisabled: true });
	};

	render() {
		const { contentType, match, selectedProjectId, location, buildings, filteredPosts, trades, setHeaderParams, isDataLoading, loadingProgress } =
		this.props;
		const {
			rowsLevel,
			columns = [],
			showRowsWithEmptyValue,
			defaultSubMenu,
			subjectType,
			selectedFilterSet,
			primaryColumn,
			sideCardObject,
			noReportMenus,
			displayPdf,
			firstColumnRenderFunc,
			isShowSelectDateModal,
			allProps,
			isCarouselVisible,
			carouselItems,
			isPDFCarousel,
			categoryFilters,
			filters,
			rowsArray,
			sideStackObjectId,
			loadingEmployeesPresence
		} = this.state;

		if (isDataLoading || !rowsArray || loadingEmployeesPresence) return <LoadingIndicator loadingProgress={loadingProgress}/>

		if (noReportMenus) {
			return (
				<div
					style={{ justifyContent: 'start', alignItems: 'center', display: 'flex', flex: 1, flexDirection: 'column' }}
				>
					<div style={{ flexDirection: 'column', margin: theme.margin * 3 }}>
						<Text style={{ fontWeight: 'bold', fontSize: theme.fontSizeH3, color: theme.brandPrimary }}>
							{_.get(messagesTypesMap, [contentType], propertiesMessages).noReport.title}
						</Text>
						<br />
						<Text style={{ fontSize: theme.fontSizeH4, lineHeight: 1.4 }}>
							{_.get(messagesTypesMap, [contentType], propertiesMessages).noReport.content}
						</Text>
					</div>
				</div>
			);
		}
		const expandableTable = Boolean(rowsLevel > 1)
		const columnCount = Math.min(Math.max(columns ? columns.length : 0, 10), 100);
		const paginationStep = Math.min(Math.round(1000 / columnCount), expandableTable ? 25 : Infinity)
		const sortFunc = getSortFunc({
			subjectType,
			primaryColumn,
			trades,
		});
		const firstLevelRowsCount = rowsArray.filter((row) => row.rowLevel === 1).length;

		const TableComponent = (
			<TableWrapper
				key='Table'
				showRowsWithEmptyValue={showRowsWithEmptyValue}
				defaultSubMenu={defaultSubMenu}
				selectedFilterSet={selectedFilterSet}
				secRowHeight={75}
				paginationStep={paginationStep}
				forcePagination={firstLevelRowsCount > 100}
				subjectType={subjectType}
				match={match}
				infoCardMode={['forms'].includes(subjectType) ? 'modal' : 'side'}
				rowsLevel={rowsLevel}
				contentType={contentType}
				cellHeight={cellHeight}
				getSideCardObject={this.getSideCardObject}
				onSideClose={this.onSideClose}
				sideCardObject={sideCardObject}
				sideStackObjectId={sideStackObjectId}
				customWidth={contentType === 'settings' && customWidth}
				firstColumnTitle={primaryColumn && primaryColumn.original.title}
				firstColumnRenderFunc={firstColumnRenderFunc}
				filterPathDelimeter={FILTER_MENU_PATH_DELIMETER}
				hasUnSavedChanges={this.hasUnSavedChanges}
				setHasUnSavedChanges={this.setHasUnSavedChanges}
				sortFunc={sortFunc}
				columns={columns}
				originalRows={rowsArray}
				primaryColumn={primaryColumn}
				expandableTable={Boolean(rowsLevel > 1)}
				shouldExpandSingleFirstLevel={true}
				filterUrlKey={FILTER_URL_KEY}
			/>
		);
		return (
			<QCReportContext.Provider value={noValue}>
				{Boolean(isCarouselVisible && _.get(carouselItems, 'length')) && (
					<ImageCarousel
						pdfMode={isPDFCarousel}
						onClose={this.handleCloseCarousel}
						initialSlide={0}
						items={carouselItems}
					/>
				)}
				{Boolean(isShowSelectDateModal) && (
					<Modal
						open
						onClose={() => this.setState({ isShowSelectDateModal: false })}
						style={{ zIndex: theme.zIndexes.propertyAnalyticsDatePicker }}
					>
						<div style={{ height: '100%', width: '100%' }}>
							<CardHeader
								style={{
									width: '100%',
									height: CALENDAR_HEADER_HEIGHT,
									backgroundColor: theme.backgroundColor,
									borderBottom: theme.borderLineHeaderInfo,
								}}
							>
								<Text
									style={{
										fontSize: theme.fontSizeH6,
										fontWeight: theme.strongBold,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										width: '100%',
										height: '100%',
									}}
								>
									{reportsMessages.newReport.title}
								</Text>
							</CardHeader>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									padding: `0 ${theme.padding * 4}px`,
								}}
							>
								<Text style={{ fontSize: theme.fontSizeH6, margin: theme.padding }}>{reportsMessages.selectADate}</Text>
								<div style={{ margin: `${theme.padding}px 0` }}>
									<Calendar
										markedDates={
											!_.keys(allProps).length
												? null
												: _.values(allProps).reduce((acc, form) => {
														if (form && form.reportDate && form.status) {
															if (!acc[form.reportDate]) acc[form.reportDate] = { colors: [] };

															acc[form.reportDate].colors.push(getFormStatusParams(form).color);
														}

														return acc;
												  }, {})
										}
										onDone={this.handleSelectDateDone}
										initialValue={getRoundedDate().timestamp}
										doneButtonTitle={reportsMessages.buttons.createNewReport}
										maxTS={Date.now()}
									/>
									<style>{`
										.rdtDays {
											width: 260px;
										}
									`}</style>
								</div>
							</div>
						</div>
					</Modal>
				)}
				{this.state.isPostRelated ? (
					<PostsAnalytics
						match={match}
						location={location}
						selectedProjectId={selectedProjectId}
						contentType={contentType}
						filteredPosts={filteredPosts}
						buildings={buildings}
						viewType={this.state.viewType}
						filterPathDelimeter={FILTER_MENU_PATH_DELIMETER}
						sortFunc={sortFunc}
						categoryFilters={categoryFilters}
						textFilter={filters?.textFilter}
						setHeaderParams={setHeaderParams}
						handleFilterChange={this.handleFilterChange}
						handleFilterClear={this.handleFilterClear}
						originalDataRows={rowsArray}
						getExcelExportComponent={this.getExcelExportComponent}
						InjectedHeaderComponents={[
							this.getAdminFilterComponent(),
							<ViewSelector
								key={'PostsAnalytics_ViewSelector'} 
								activeViewType={this.state.viewType} 
								onChange={(viewType) => this.setState({ viewType })} 
							/>
						]}
						TableComponent={TableComponent}
					/>
				) : (
					TableComponent
				)}
				{Boolean(displayPdf) && (
					<ImageCarousel
						toolbar={true}
						pdfMode={true}
						onClose={this.closeImageCarousel}
						initialSlide={0}
						items={[{ src: displayPdf }]}
					/>
				)}
			</QCReportContext.Provider>
		);
	}
}
const noValue = {};

const styles = {
	textCenter: {
		textAlign: 'center',
		alignItems: 'center',
		alignContent: 'center',
		justify: 'center',
	},
	checkedIcon: {
		color: theme.brandPrimary,
		width: '20px',
		height: '20px',
		border: '1px solid rgba(0, 0, 0, .54)',
		borderRadius: '3px',
	},
	uncheckedIcon: {
		color: theme.brandPrimary,
		width: '0px',
		height: '0px',
		padding: '9px',
		border: '1px solid rgba(0, 0, 0, .54)',
		borderRadius: '3px',
	},
};

export const loadingSubjectsMapper = (subject, projectId) => {
	switch (subject) {
		case SUBJECTS.FORMS: return [
			['projects', projectId, `${SUBJECTS.FORMS}/${FORM_TYPES.DAILY_REPORT}`],
			['projects', projectId, SUBJECTS.POSTS],
			['projects', projectId, `${SUBJECTS.PROPERTIES_TYPES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.FORMS]}`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_TYPES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.POSTS]}`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_MAPPINGS}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.FORMS]}`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_MAPPINGS}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.POSTS]}`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_INSTANCES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.FORMS]}`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_INSTANCES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.POSTS]}`],
		]
		case SUBJECTS.POSTS: return [
			['projects', projectId, SUBJECTS.POSTS],
			['projects', projectId, SUBJECTS.BUILDINGS],
			['projects', projectId, SUBJECTS.FLOORS],
			['projects', projectId, SUBJECTS.UNITS],
			['projects', projectId, `${SUBJECTS.PROPERTIES_TYPES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.POSTS]}`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_MAPPINGS}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.POSTS]}`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_INSTANCES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.POSTS]}`],
			['projects', projectId, `${SUBJECTS.CHECKLIST_ITEM_INSTANCES}/itemInstances`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_INSTANCES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.CHECKLIST_ITEMS]}`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_INSTANCES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.CHECKLIST_ITEMS]}`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_INSTANCES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.CHECKLIST_ITEMS]}`],
		
		]
		case SUBJECTS.EMPLOYEES: return [
			['projects', projectId, SUBJECTS.EMPLOYEES],
			['projects', projectId, `${SUBJECTS.PROPERTIES_TYPES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.EMPLOYEES]}`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_MAPPINGS}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.EMPLOYEES]}`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_INSTANCES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.EMPLOYEES]}`],
		]
		case SUBJECTS.EQUIPMENT: return [

			['projects', projectId, SUBJECTS.EQUIPMENT],
			['projects', projectId, `${SUBJECTS.PROPERTIES_TYPES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.EQUIPMENT]}`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_MAPPINGS}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.EQUIPMENT]}`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_INSTANCES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.EQUIPMENT]}`],
		
		]
		case SUBJECTS.LOCATIONS: return [
			['projects', projectId, SUBJECTS.BUILDINGS],
			['projects', projectId, SUBJECTS.FLOORS],
			['projects', projectId, SUBJECTS.UNITS],
			['projects', projectId, `${SUBJECTS.PROPERTIES_TYPES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.LOCATIONS]}`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_MAPPINGS}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.LOCATIONS]}`],
			['projects', projectId, `${SUBJECTS.PROPERTIES_INSTANCES}/${propertyTypes.SUBJECT_NAMES[SUBJECTS.LOCATIONS]}`]
		]
		case SUBJECTS.COMPANIES: return [];
		default: return [];
	}
}

export const getSubjectMapper = ({ selectedProjectId, contentType, selectedFav }) => {
  let subjectType = null;

  if (contentType === 'settings') subjectType = SUBJECTS.COMPANIES;
  else if (contentType === 'siteControl') subjectType = SUBJECTS.EMPLOYEES;
  else subjectType = selectedFav.subjectType;

  const subjectPaths = loadingSubjectsMapper(subjectType, selectedProjectId);

  return subjectPaths;
};

const enhance = compose(
	injectIntl,
	withStyles(styles),
	withRouterHOC,
	withSavedMenus,
	connectContext(ProjectContext.Consumer),
	CompaniesHOC,
	withLoading(getSubjectMapper),
	connect(
		state => ({
			rtl: state.app.rtl,
			storageCleaned: state.app.storageCleaned,
			titles: state.titles.map,
			trades: state.trades.map,
			members: state.members?.map?.toJS(),
			subCategories: state.quasiStatics.subCategoriesMap,
			configurationMode: state.app.configurationMode,
		}),
		{
			startLoading,
			hideLoading,
			startToast,
			onDraftModeChange,
			uploadPropertiesInstances,
			exportFormPDF,
			track,
		},
	),
);


export default enhance(PropertyAnalytics)