import { useMemo } from 'react';
import _ from 'lodash';
import useIntl from '../../intl/useIntl';
import { getNextGenValue } from '../utils';

const useSmartObjects = (objects, propTypes) => {
  const intl = useIntl();

  return useMemo(() => {
    return objects.map((obj) => {
      let cachedConversions = {};
      return new Proxy(obj, {
        get: (target, property) => {
          if (cachedConversions[property]) {
            return cachedConversions[property];
          }
          const prop = propTypes[property];
          let ret = null;
          if (prop) {
            ret = getNextGenValue(target, prop, intl);
          } else {
            ret = target[property];
          }
          cachedConversions[property] = ret;
          return ret;
        },
      });
    });
  }, [objects, propTypes]);
};

export default useSmartObjects;
