import moment from 'moment';
import momentTZ from 'moment-timezone';
import { platformActions } from '../platformActions';
import { getAppState, realmInstance, lokiInstance } from '../configureMiddleware';
import theme from '../app/theme';
import safetyMessages from '../safety/safetyMessages';
import systemMessages from '../app/systemMessages';
import * as propertyTypes from '../propertiesTypes/propertiesTypes';
import _ from 'lodash';
import { getFullLocationDetailsByIdNoProps, getLocationTitleNoProps } from '../locations/func';
import { subscribeToLastUpdates, unsubscribeToLastUpdates, writeLogOnce } from '../lib/utils/utils';
import { debugParams, replaceMaxUpdateTSIfNeeded, notifyLongFunctionDuration } from '../lib/utils/utils';
import { schemasInfo, uploadObjectsDispatcher } from '../lib/offline-mode/config';
import * as propertiesTypes from '../propertiesTypes/propertiesTypes';
import trackPropertiesInstances, { PROPERTIES_INSTANCES_EVENTS } from './trackPropertiestInstances';
import serverSDK from '@cemento-sdk/server';
import trackEmployees, { EMPLOYEES_EVENTS } from '../employees/trackEmployees';
import { isEmptyValue, safeFormatMessage } from '../app/funcs';

const saveToLoki = (propertiesInstances = {}, subjectName, lastUpdateTS, scopeId, lokiInstance, ignoreTimestamp, cleanAll) => {
  let propertiesInstancesValuesArray = Object.values(propertiesInstances || {});
  if (!cleanAll && propertiesInstancesValuesArray.length == 0)
    return
  
  let allInstances = [];
  let allDeletedIds = [];

  propertiesInstances = Object.values(propertiesInstances).sort((propInstanceA, propInstanceB) => ((propInstanceA.updatedTS || 0) > (propInstanceB.updatedTS || 0) ? -1 : 1));


  (propertiesInstances).forEach(propInstance => {
    propInstance.isDeleted
      ? allDeletedIds.push(propInstance.id)
      : allInstances.push({ ...propInstance.realmToObject(), subjectName: subjectName, projectId: scopeId });
  });

  if (cleanAll) {
    lokiInstance.getCollection('propertyInstances').cementoDelete({ projectId: scopeId, subjectName: subjectName });
    }  

  if (allDeletedIds.length)
    lokiInstance.getCollection('propertyInstances').cementoDelete({ id: { '$in': allDeletedIds } });

  lokiInstance.getCollection('propertyInstances').cementoUpsert(allInstances);
}

const saveToRealm = (propertiesInstances, subjectName, lastUpdateTS, scopeId, realmInstance, ignoreTimestamp, cleanAll, platformActions) => {
  let instancesWithoutPropId = new Set();
  let instancesWithoutParentId = new Set();
  if (propertiesInstances) propertiesInstances = _.pickBy(propertiesInstances, (x) => {
    if (x.isDeleted)
      return true;

    if (!x.propId) {
      instancesWithoutPropId.add(x.id);
    }
    if (!x.parentId) {
      instancesWithoutParentId.add(x.id);
    }

    return Boolean(x.propId && x.parentId);
  });

  if (instancesWithoutPropId.size) {
    platformActions.sentry.notify('propInstances without propId ', { instancesWithoutPropId: Array.from(instancesWithoutPropId) });
  }
  if (instancesWithoutParentId.size) {
    platformActions.sentry.notify('propInstances without propId ', { instancesWithoutPropId: Array.from(instancesWithoutPropId) });
  }

  if (!cleanAll && Object.keys(propertiesInstances || {}).length == 0)
    return;

  let propertiesInstancesValuesArray = Object.values(propertiesInstances || {});
  if (!cleanAll && propertiesInstancesValuesArray.length == 0)
    return;

  propertiesInstances = propertiesInstancesValuesArray.sort((propInstanceA, propInstanceB) => (propInstanceA.updatedTS || 0) > (propInstanceB.updatedTS || 0) ? -1 : 1);
  let currBatchMaxLastUpdateTS = _.get(propertiesInstances, [0 , 'updatedTS'], 0);
  let realm = realmInstance.propertyInstances;

  const deletedIdList = []

  realm.beginTransaction();
  try {
    if (cleanAll) {
      let allLocPropsInstances = realm.objects('propertyInstance1').filtered(`projectId = "${scopeId}" AND subjectName = "${subjectName}"`);
      realm.delete(allLocPropsInstances);
    }

    (propertiesInstances).forEach(propInstance => {

      let stringData = propInstance && propInstance.data !== null && propInstance.data !== undefined ? JSON.stringify(propInstance.data) : null;

      if (propInstance && propInstance.id) {
        let valueScope = propInstance.valueScope;
     
        if (!propInstance.isDeleted) {
          let propInstanceObj = { ...propInstance.realmToObject(), data: stringData, subjectName: subjectName, projectId: scopeId, valueScope, isLocal: (propInstance.isLocal ? true : null) };
          realm.create('propertyInstance1', propInstanceObj, 'modified');
        }
        else {
          deletedIdList.push(propInstance.id)
        }
           
      }
      else
        platformActions.sentry.notify('propertyInstance missing ID', propInstance);
    });
    
    if (deletedIdList?.length) {
			try {
				const allDeletedInstances = realm
					.objects('propertyInstance1')
					.filtered(`projectId = "${scopeId}" AND id IN {"${deletedIdList.join('", "')}"}`);
        
        realm.delete(allDeletedInstances);
			} catch (error) {
				console.log('____ERRROR', error);
			}
		}

    replaceMaxUpdateTSIfNeeded(currBatchMaxLastUpdateTS, realm, 'propertyInstance1', `projectId = "${scopeId}" AND subjectName = "${subjectName}"`, subjectName);

    realm.commitTransaction();
  } catch (e) {
    realm.cancelTransaction();
    throw e;
  }
}

const setPropertiesInstancesValues = async (propertiesInstances, subjectName, scopeId, realmInstance, lokiInstance, platformActions, cleanAll) => {
  const propInstanceToSave = _.values(propertiesInstances);

  if (propInstanceToSave.length || cleanAll) {
    if (platformActions.app.getPlatform() == "web") {
      saveToLoki(propertiesInstances, subjectName, undefined, scopeId, lokiInstance, null, cleanAll);
    } else
      await notifyLongFunctionDuration(() => saveToRealm(propertiesInstances, subjectName, undefined, scopeId, realmInstance, null, cleanAll, platformActions),
        `saveToRealm ~ The function took too long`, `propertiesInstances`)
  }
}


const getLastUpdateTS = (realmInstance, lokiInstance, scopeId, subjectName) => {
  let lastUpdateTS = 0;
  if (platformActions.app.getPlatform() == "web") {
    let lastUpdateTSObj = {};
    let lastUpdateTSObjArr = lokiInstance.getCollection('propertyInstances').chain().find({projectId: scopeId, subjectName}).simplesort("updatedTS", true).limit(1).data();
    if (lastUpdateTSObjArr.length) lastUpdateTSObj = lastUpdateTSObjArr[0];
    lastUpdateTS = lastUpdateTSObj.updatedTS;
  }
  else {
    lastUpdateTS = realmInstance.propertyInstances.objects('propertyInstance1').filtered(`projectId == "${scopeId}" AND subjectName == "${subjectName}"`).max('updatedTS');
  }

  return lastUpdateTS || 0;
}

export const startPropertiesInstancesListener = async (viewer, scopeId, subjectName, cleanAll, queryParams = {}) => {
  const scope = 'projects';
  if (cleanAll) {
    await setPropertiesInstancesValues([], subjectName, scopeId, realmInstance, lokiInstance, platformActions, cleanAll);
  }

  let didDispatch = false;
  const saveFunc = (_data, isRevoke) => {
    if (debugParams.disableFetchByTSSave)
        return;
    
    setPropertiesInstancesValues(_data, subjectName, scopeId, realmInstance, lokiInstance, platformActions, isRevoke).then(() => {
      if (!didDispatch) {
        didDispatch = true;
      }
    });
  }

  trackPropertiesInstances(PROPERTIES_INSTANCES_EVENTS.START_PROPERTIES_INSTANCES_LISTENER, { scope, scopeId, subjectName, queryParams });

  subscribeToLastUpdates(
    viewer,
    {
      scope,
      scopeId,
    },
    {
      subject: 'propertiesInstances',
      queryParams: { ...queryParams, subjectName },  
      getLastUpdateTS: () => getLastUpdateTS(realmInstance, lokiInstance, scopeId, subjectName),
      getData: (lastUpdateTS) => {
        trackPropertiesInstances(PROPERTIES_INSTANCES_EVENTS.FETCH_PROPERTIES_INSTANCES, { scope, scopeId, subjectName, queryParams, lastUpdateTS });
        return serverSDK.properties.getInstances({
          scope,
          scopeId,
          subject: subjectName,
          lastUpdateTS,
        });
      },
      isObjectUseTransactionalDB: true,
    },
    saveFunc
  );
}

const IS_EXPELLED_PROP_ID = '-isExpelled';
const ID_NUMBER_PROP_ID = '-idNumber';
export const saveInstances = async (projectId, subjectName, instances, originalInstances = undefined) => {
  let success = null;
  if (projectId && subjectName && Object.keys(instances || {}).length) {
    instances = Object.values(instances)
                  .filter(instance => _.get(instance, 'data') !== undefined)
                  .map(instance => {
                    if (instance && instance.data && instance.propType === propertiesTypes.STRING) {
                      instance.data = instance.data.trim();
                    }

                    if (instance && isEmptyValue(instance.data)){
                      instance.isDeleted = true;
                    };

                    return { ...instance, subjectName, projectId };
                  });

    const actionRes = await uploadObjectsDispatcher({
      projectId,
      objectsToSave: instances,
      originalObjects: originalInstances,
      schemaInfo: schemasInfo.propertyInstances
    });

    success = actionRes.success;
    const employeeExpelledPropInstances = instances.filter(instance => instance.propId === IS_EXPELLED_PROP_ID);
    if (subjectName === propertiesTypes.SUBJECT_NAMES.employees && employeeExpelledPropInstances.length) {
      let filterQuery;
      const isNative = platformActions.app.isNative();
      if (isNative) {
        const parentIdConditions = employeeExpelledPropInstances.map(instance => `parentId == "${instance.parentId}"`).join(" OR ");
        filterQuery = `
        projectId == "${projectId}" AND 
        subjectName == "${subjectName}" AND 
        propId == "${ID_NUMBER_PROP_ID}" AND
        (${parentIdConditions})
      `;
      } else {
        filterQuery = {
          projectId,
          subjectName,
          propId: ID_NUMBER_PROP_ID,
          parentId: { $in: employeeExpelledPropInstances.map(instance => instance.parentId) },
        };
      }
      const localDB = platformActions.localDB.getCementoDB();
      const data = _.groupBy(localDB.get('propertyInstances', filterQuery) || [], 'parentId');
      let expelledEmployeesIds = [];
      let unexpelledEmployeeIds = [];
      employeeExpelledPropInstances.forEach(instance => {
        const parentId = _.get(instance, ['parentId']);
        const idNumber = _.get(data, [parentId, 0, 'data']);
        if (instance.data) {
          expelledEmployeesIds.push(idNumber);
        } else {
          unexpelledEmployeeIds.push(idNumber);
        }
      });
      
      if (expelledEmployeesIds.length) {
        trackEmployees(EMPLOYEES_EVENTS.EXPELLED_EMPLOYEES, { projectId, employeeIds: expelledEmployeesIds });
        platformActions.mixpanel.trackWithProperties("Expelled employees", { expelledEmployeesIds });
      }

      if (unexpelledEmployeeIds.length) {
        trackEmployees(EMPLOYEES_EVENTS.UNEXPELLED_EMPLOYEES, { projectId, employeeIds: unexpelledEmployeeIds });
        platformActions.mixpanel.trackWithProperties("Unexpelled employees", { unexpelledEmployeeIds });
      }
    }
  }

  trackPropertiesInstances(PROPERTIES_INSTANCES_EVENTS.UPSERT_PROPERTIES_INSTANCES, { scope: 'projects', scopeId: projectId, subjectName, numInstances: _.size(instances), success });

  return {
    projectId,
    subjectName,
    instances,
    success,
  };
}

export function endPropertiesInstancesListener({ scopeId, subjectName, queryParams = {} }) {	
  unsubscribeToLastUpdates({
    scope: 'projects',
    scopeId,
  }, {

    subject: 'propertiesInstances',
    queryParams: { ...queryParams, subjectName },
  });

  trackPropertiesInstances(PROPERTIES_INSTANCES_EVENTS.END_PROPERTIES_INSTANCES_LISTENER, { scope: 'projects', scopeId, subjectName, queryParams });
}

// ---------------------------------------------------------------------------------------------
export function getRelevantPropertyIds(propertiesMappings, subjectName, objectGroupId, objectInstances, includeMockOfDefaultPropertyType, subGroupsPropId, objectSubGroupId) {
  let maps = _.get((propertiesMappings && propertiesMappings.toJS) ? propertiesMappings.toJS?.() : propertiesMappings, subjectName);
  let ret = new Set();
  let relevantPropertyIds = _.values(_.get(maps, ['groups', objectGroupId, 'properties']));
  if (includeMockOfDefaultPropertyType && propertyTypes.MOCK_PROPERTY_TYPES[subjectName]) {
    ret = new Set(Object.keys(propertyTypes.MOCK_PROPERTY_TYPES[subjectName] || {}));
  }
  if (subGroupsPropId && objectSubGroupId){
    relevantPropertyIds.push(..._.values(_.get(maps, [subGroupsPropId, objectSubGroupId, 'properties'])))
  }

  let alreadyAddedPropIds = {};



  while (relevantPropertyIds.length) {
    const currPropId = relevantPropertyIds.shift();
    ret.add(currPropId);
    alreadyAddedPropIds[currPropId] = true;

    const propMap = _.get(maps, currPropId);
    const propInstance = _.get(objectInstances, currPropId);

    if (currPropId !== 'groups' && propMap && propInstance) {
      let currInstanceData;
      try { currInstanceData = JSON.parse(propInstance.data); }
      catch (err) { currInstanceData = propInstance.data; }
      currInstanceData = (propInstance.propType === propertyTypes.SELECTION_LIST) ? _.keys(currInstanceData) : [currInstanceData];

      currInstanceData.forEach(currInstanceDataObj => {
        let newRelevantPropertyIds = _
          .get(propMap, [currInstanceDataObj, 'properties'], [])
          .filter(newPropId => !alreadyAddedPropIds[newPropId]);
        relevantPropertyIds.push(...newRelevantPropertyIds);
      })
    }
  }

  return Array.from(ret);
}

export const getRelevantPropertyIdsByData = (propertiesMappings, subjectName, objectGroupId, instancesData, types, objectSubGroupId) => {
  const instances = _.keys(instancesData).reduce((acc, key) => {
    acc[key] = {
      data: instancesData[key],
      propType: types?.[key]?.type,
    }

    return acc;
  }, {});

  return getRelevantPropertyIds(propertiesMappings, subjectName, objectGroupId, instances, false, objectSubGroupId);
}

function andOrBuilder(ids, idField) {
  if (!ids) return '';

  let extraQuery = ' ';

  if (_.isArray(ids) && ids.length == 0)
    extraQuery += `LIMIT(0)`;
  else {
    extraQuery = 'AND ( ';
    extraQuery += ids.map(id => `(${idField}  = "${id}")`).join(" OR ");
    extraQuery += ')';
  }

  return extraQuery;
}

export const statusColorOrdinalNo = {
  [theme.brandRealDanger]: 1,
  [theme.brandDanger]: 2,
  [theme.brandWarning]: 3,
  [theme.brandSuccess]: 4,
  [theme.darkSeparatorColor]: 5,
};

export function populateObject({
  selectedProjectId,
  subjectType,
  propertiesSections,
  propertiesTypes,
  inPropertiesMappings,
  intl,
  instancesDataUpdatesByPropId,
  objectsIds,
  seperateFullProp = false,
  keepOnlyLastCertificate = false,
  inAllProps = {},
  inSpecificProps,
  inObjects = null,
  extraLocalInstances = null,
  inInstances = null,
  extraLocalObjects = null,
  skipPopulatedObjects = false,
  groupedObjectsColorMapper: injectedGroupColorMapper,
  includeMockOfDefaultPropertyType,
  inDefaultGroupId,
  shouldPopulateAllProperties,
}) {
  let isWeb = platformActions.app.getPlatform() == 'web';

  let specificProps = null;
  if (inSpecificProps) {
    specificProps = {};
    inSpecificProps.forEach(x => {specificProps[x] = true});
  } 

  let propertiesMappings = inPropertiesMappings && Boolean(inPropertiesMappings.toJS) ? inPropertiesMappings.toJS?.() : inPropertiesMappings;
    //todayTS = moment(moment().format("YYYY-MM-DD")).utc().valueOf();
    let subjectName = subjectType + 'Info';
    let sectionsMap = propertiesSections && propertiesSections[subjectName] || {};

    let objects = inObjects || [];
    let instances = inInstances || [];
    let primaryPropId = null;
    let universalsPropsIds = {};
    let instancesByParentAndProp = {};
    let allProps = inAllProps || {};
    if (objectsIds && allProps) {
      objectsIds.forEach(x => {delete allProps[x]});
      inSpecificProps = null;
    }
    
    if (isWeb) {
      let objectQuery =    {'projectId' : selectedProjectId };
      let instancesQuery = {'projectId' : selectedProjectId, subjectName: subjectName };

      if (objectsIds && objectsIds.length) {
        objectQuery['id'] = {'$in' : objectsIds };
        instancesQuery['parentId'] = {'$in' : objectsIds };
      }

      if (subjectType == 'projects')
        objects = [{ id: selectedProjectId }];
      else if (subjectType === 'companies')
        objects = inObjects || [];
      else if (!inObjects)
        objects = lokiInstance.getCollection(subjectType) ? lokiInstance.getCollection(subjectType).cementoFind(objectQuery) : []; // TODO: Add filter on requested objectId
      if (!inInstances)
        instances = lokiInstance.getCollection('propertyInstances').cementoFind(instancesQuery);

    } else {
      let realmSchemaName = subjectType == 'employees' ? 'employee1' : subjectType == 'equipment' ? 'equipment1' : null;
      if (subjectType == 'projects')
        objects = [{ id: selectedProjectId }];
      else if (subjectType === 'companies')
        objects = inObjects || [];
      else if (!inObjects) 
        objects = realmInstance[subjectType].objects(realmSchemaName).filtered('projectId = "' + selectedProjectId + '"' + andOrBuilder(objectsIds, "id"));
      if (!inInstances)
        instances = realmInstance.propertyInstances.objects('propertyInstance1').filtered(`projectId = "${selectedProjectId}" AND subjectName = "${subjectName}"  ${andOrBuilder(objectsIds, "parentId")}`);
    }

    if (extraLocalInstances && instances)
      instances = [
        ...instances,
        ...extraLocalInstances
          .filter(instance => !instance.isDelete)
          .map(instance => instance.setNested2(['subjectName'], subjectName)),
      ]

    if (extraLocalObjects && objects)
      objects = [...objects, ...Object.values(extraLocalObjects).filter(obj => !obj.isDeleted)];

    instances.forEach(inst => {  
      if (!instancesByParentAndProp[inst.parentId]) 
        instancesByParentAndProp[inst.parentId] = {};
      instancesByParentAndProp[inst.parentId][inst.propId] = inst;
    });
 
    let originalSubjectProperties = (propertiesTypes||{})[subjectName];
    let subjectProperties = {};
    (originalSubjectProperties||{}).loopEach((id, prop) => { 
      if (prop.isPrimary) 
        primaryPropId = prop.id;
      if (prop.universalId)
        universalsPropsIds[prop.id] = prop.universalId;

      subjectProperties[prop.id] = prop;
    })
    let cachedSectionsMap = {};
    objects.forEach(currRealmObject => {
      const currObject = currRealmObject.realmToObject()
      if (currObject.isDeleted)
        return null;

      let objectId = currObject.id;
      let currRelevantPropTypesIdsArray = [];
      let objectInstances = instancesByParentAndProp.getNested2([objectId], {});
      let objectGroupData = (instancesDataUpdatesByPropId || {}).getNested2(['groups']) || objectInstances.getNested2(['groups', 'data'], null);
      
      if (objectGroupData && platformActions.app.getPlatform() != 'web' && typeof objectGroupData === 'string')
        try { objectGroupData = JSON.parse(objectGroupData); } catch (error) { console.log(`funcs.js ~ line 160 ~ objects.loopEach ~ JSON.parse error`, error); }
      
      let objectGroupId = inDefaultGroupId || null;
      if (objectGroupData) 
        objectGroupId = Object.keys(objectGroupData)[0]; 

      currRelevantPropTypesIdsArray = getRelevantPropertyIds(propertiesMappings, subjectName, objectGroupId, objectInstances, includeMockOfDefaultPropertyType);

      if (!allProps[objectId])
        allProps[objectId] = Object.assign({}, currObject);
      let allPropsObject = allProps[objectId];
      let isMissingRequirementArray = [];
      const relevantPropIds = shouldPopulateAllProperties ? _.keys(subjectProperties) : currRelevantPropTypesIdsArray;
      relevantPropIds.forEach(propId => {
        let currProp = subjectProperties[propId];// propertiesTypes.getNested2([subjectName, propId]);
        
        if (!currProp)
          return null;

        if (specificProps && !specificProps[currProp.type] && !specificProps[currProp.universalId] && !(specificProps["isPrimary"] && currProp.id == primaryPropId))
          return null;

        let currInstance = objectInstances[currProp.id];
        if (!currInstance || currInstance && currInstance.isDeleted)
          currInstance = null;

        let isPropRequired = currProp.settings && Boolean(currProp.settings.isRequired);
        if (currInstance == null && isPropRequired)
          isMissingRequirementArray.push(currProp);
        
        let data;
        if (currProp.isLocalMock && currProp.universalId) {
          data = _.get(allPropsObject, currProp.universalId);
          if (
            ['assignTo.companyId', 'owner.companyId', 'severity', 'subCategory.id'].includes(currProp.universalId) &&
            !_.isObject(data) &&
            !isEmptyValue(data)
          ) {
            data = { [data]: data };
					}
        }
        else
          data = (instancesDataUpdatesByPropId || {}).getNested2([currProp.id], (!_.isNil((currInstance || {}).data) ? (currInstance || {}).data : null));
        if (!_.isNil(data) && platformActions.app.getPlatform() != 'web' && typeof data === 'string')
          try { data = JSON.parse(data); }
           catch (error) { 
             if (process.env.NODE_ENV != "production")
               console.log(`funcs.js ~ line 203 ~ currRelevantPropTypesIdsArray.loopEach ~ JSON.parse`, error); 
            }
        
        if (data === null && !_.isNil(currProp.getNested2(['settings', 'defaultVal'])))
          data = currProp.getNested2(['settings', 'defaultVal']);

        if (currProp.type == propertyTypes.CERTIFICATION && keepOnlyLastCertificate)
          data = !_.isNil(data) ? [_.last(data)] : data;

        let populatedProp = { 
          propId: currProp.id,
          data,
          type: currProp.type,
          isRequired: isPropRequired,
          instanceId: currInstance ? currInstance.id : null,
          businessType: currProp.businessType,
        };

        if (!seperateFullProp) {
          populatedProp.fullProp = currProp;
        }

        let propTitle = currProp.getCementoTitle();
        if (currProp.type == propertyTypes.CERTIFICATION) {
          let currSignatureBehaviour = _.last(data)?.signatureBehaviour;
          let signatureBehaviourText = currProp?.settings?.signatureBehaviour?.[currSignatureBehaviour]?.text;
          if (signatureBehaviourText) propTitle += ` (${signatureBehaviourText})`;
        }
        populatedProp.propTitle = propTitle;

        if (currProp.sectionId && sectionsMap[currProp.sectionId]) {
          let section = cachedSectionsMap[currProp.sectionId];
          if (!section) {
            section = { id: sectionsMap[currProp.sectionId], title: (sectionsMap?.[currProp.sectionId] || {}).getCementoTitle() };
            cachedSectionsMap[currProp.sectionId] = section;
          }

          populatedProp.section = section;
        }
          

        let dataText = instanceDataToString(currProp, populatedProp.data, intl, true);
        if (currProp.type == propertyTypes.DRAWINGS_ARRAY || currProp.type == propertyTypes.FILES_ARRAY || currProp.type == propertyTypes.PICTURE || currProp.type == propertyTypes.VIDEO || currProp.type == propertyTypes.PDF) {
          populatedProp.uri = dataText;
        } else if ((currProp.type == propertyTypes.CERTIFICATION) ||
                   (currProp.type == propertyTypes.DATE && (currProp.settings && (currProp.settings.isExpiration || currProp.settings.isWarning)))) {
          if (populatedProp.data) {
            let info = checkPropSettings(currProp, populatedProp.data, intl)
            populatedProp.dataText = dataText;
            populatedProp = Object.assign(populatedProp, info);
            if (info.isCanceled) {
              _.set(allPropsObject, ['canceledList', currProp.id], true);
            } else if (info.isExpired || info.isWarning) {
              const listType = info.isExpired ? 'expiredList' : 'warningList';
              if (!allPropsObject[listType])
                allPropsObject[listType] = {};
              allPropsObject[info.isExpired ? 'expiredList' : 'warningList'][currProp.id] = populatedProp;
              if (info.isExpired)
                allPropsObject.isExpired = info.isExpired;
              if (info.isWarning){
                allPropsObject.isWarning = info.isWarning;
              }
              
            }
          }
        }
        else 
          populatedProp.dataText = dataText;

        if (currProp.type == propertyTypes.CERTIFICATION && !populatedProp.statusColor)
          populatedProp.statusColor = theme.brandSuccess;
        if (!allPropsObject.props) {
          allPropsObject.props = {};
        }
        allPropsObject.props[currProp.id] = populatedProp;

        if (currProp.id == primaryPropId)
          allPropsObject.primaryProp = populatedProp;
        if (universalsPropsIds[currProp.id]) {
          if (!allPropsObject.universal)
            allPropsObject.universal = {};
          allPropsObject.universal[currProp.universalId] = populatedProp;
        }
      });
      // Check per object if - isMissingRequirement;
      isMissingRequirementArray.forEach(missingReq => {
        _.set(allProps, [objectId, 'props', missingReq.id, 'isExpired'], true);
        _.set(allProps, [objectId, 'props', missingReq.id, 'statusColor'], theme.brandRealDanger);
        _.set(allProps, [objectId, 'isExpired'], true);
      });
    });

    let objectsToReturn = { subjectProperties, universalsPropsIds, allProps };
    
    // Run over all items and do last changes

  const defaultGroupColorMapper = x => {
    if (x.isExpired && x.isWarning)
      delete x['isWarning'];

    if (x.isWarning)
      x.color = theme.brandWarning;

    if (x.missingRequirementList)
      x.color = theme.brandRealDanger;

    if (x.isExpired)
      x.color = theme.brandRealDanger;

    if (!x.color)
      x.color = theme.brandSuccess;
    return x;
  };


  if (!skipPopulatedObjects) {
    let populatedObjects = Object.values(allProps).map(_.isFunction(injectedGroupColorMapper) ? injectedGroupColorMapper : defaultGroupColorMapper);

      populatedObjects.sort((b, a) => {
        let aPriority = a.isExpired ? 3 : a.missingRequirementList ? 2 : (a.isWarning ? 1 : 0);
        let bPriority = b.isExpired ? 3 : b.missingRequirementList ? 2 : (b.isWarning ? 1 : 0);
        return (aPriority - bPriority);
      });

      objectsToReturn.populatedObjects = populatedObjects;
    }
    return objectsToReturn;
}

const checkDate = (() => {
  let lastTodayTS = null;
  let cachedRet = {};
  return (settings, ts, intl) => {
    let ret = {}
    if (!ts)
      return ret;

    const todayTS = moment().startOf('day').utc().valueOf();
    if (todayTS !== lastTodayTS) {
      lastTodayTS = todayTS;
      cachedRet = {};
    }

    const jsonSettings = JSON.stringify(settings || {}, Object.keys(settings || {}).sort()); // second param is so that the order of the keys in settings json will always be the same
    const cacheKey = `${ts}_${jsonSettings}`;
    if (cachedRet[cacheKey]) {
      return cachedRet[cacheKey];
    }

    let daysToWarning = null;
    let isWarning = false;
    let isExpired = false; 
    let value = parseInt(ts);
    
    let isDailyExpiration = Boolean(_.get(settings, ['certificationDaysTTL']) == 1);
    if (_.has(settings, 'isWarning'))
      daysToWarning = isDailyExpiration ? 1 : Number(_.get(settings, ['daysToWarning'], 30));

    let startOfToday = moment(todayTS).startOf('day').valueOf();
    let startOfExpirationPeriod = moment(value).startOf('day').add(1, 'day').valueOf();
    let startOfWarningPeriod = moment(startOfExpirationPeriod).subtract(daysToWarning, 'days').valueOf();
    
    if (_.has(settings, 'isWarning'))
      isWarning = startOfToday >= startOfWarningPeriod;
    if (_.has(settings, 'isExpiration'))
      isExpired = startOfToday >= startOfExpirationPeriod
    
    let dateColor   = isExpired ? theme.brandRealDanger : (isWarning ? theme.brandWarning : theme.brandInfo);
    let statusColor = isExpired ? theme.brandRealDanger : (isWarning ? theme.brandWarning : theme.brandSuccess);

    if (isExpired || isWarning) {
      let daysGap = Math.round(Math.abs(todayTS - value)/(24 * 60 * 60 * 1000));
      ret.dataText = intl.formatMessage(isExpired ? safetyMessages.expiredXDaysAgo : safetyMessages.validForXMoreDays, {value:daysGap});
    }

    ret = Object.assign(ret, {isExpired, isWarning, color: dateColor, statusColor, lastCertExpirationTS: value});
    cachedRet[cacheKey] = ret;

    return ret;
  }
})();

export const getDateText = (() => {
  let cachedResults = {};
  return (intl, settings, ts) => {
    if (!ts) {
      return '';
    }

    const jsonSettings = JSON.stringify(settings || {}, Object.keys(settings || {}).sort()); // second param is so that the order of the keys in settings json will always be the same
    const cacheKey = `${ts}_${jsonSettings}`;
    if (cachedResults[cacheKey]) {
      return cachedResults[cacheKey];
    }

    const dateFormat = safeFormatMessage(intl, settings?.dateFormat || systemMessages.fullDateFormat);
    let momentTS = momentTZ(ts);
    if (settings?.timezone)
      momentTS = momentTS.tz(settings.timezone);

    const res = momentTS.format(dateFormat);
    cachedResults[cacheKey] = res;

    return res;
  }
})();
export function checkPropSettings(prop, data, intl) {
  let info = {};
  if (prop && prop.type == propertyTypes.DATE) {
    info = checkDate(prop.settings, data, intl);
    info.dataText = getDateText(intl, prop.settings, data);
  } else if (prop && prop.type == propertyTypes.CERTIFICATION) {
    let certArray = data || [];
    if (certArray.length) {
      //certArray = certArray.sort((a,b) => a.certificationTTL - b.certificationTTL);
      let latestCert = certArray[certArray.length - 1]
      info.isCanceled = latestCert.isCanceled;
      if (!info.isCanceled) info = checkDate(prop.settings || {isExpiration: true, isWarning: true}, latestCert.certificationTTL, intl);
    } else {
      if (_.get(prop, ['settings', 'isRequired']))
        info.missingRequirementList = true;
    }
  }

  return info;
}
export const instanceDataToStringNoIntl = (prop, data, withURI) => {
  const appIntl = getAppState().getNested(['app', 'intl']);
  return instanceDataToString(prop, data, appIntl, withURI);
}
export function instanceDataToString(prop, data, intl, withURI) {
  let textToRet = ''; 
  if (_.isNil(prop) || (_.isNil(data) && _.isNil(_.get(prop, ['settings', 'defaultVal']))))
    return textToRet;

  if (isEmptyValue(data) && !isEmptyValue(prop.settings?.defaultVal)) {
    data = prop.settings.defaultVal;
  }

  if (prop.type == propertyTypes.SELECTION_LIST && prop.values) {
    let selectedId;
    if (data && typeof data === 'object') {
      selectedId = Object.keys(data)[0];
    } else if (data && ['number','string'].includes(typeof data)) {
      selectedId = data;
    }
   
    const selectedOption = selectedId && prop.values?.find?.(o => o.id === selectedId);
   
    if (selectedOption) {
      textToRet = selectedOption.getCementoTitle();
    }
  } else if (prop.type == propertyTypes.STRING)
    textToRet = data;
  else if (withURI && (prop.type == propertyTypes.DRAWINGS_ARRAY || prop.type == propertyTypes.FILES_ARRAY) && data.length) {
    textToRet = ((data[0].type) && data[0].type.toLowerCase() == propertyTypes.PDF.toLowerCase()) ? null : data[0].uri;
  } else if (withURI && (prop.type == propertyTypes.PICTURE || prop.type === propertyTypes.VIDEO)) {
    textToRet = data.uri;
  } else if (prop.type == propertyTypes.NUMBER) {
    textToRet = String(data);
  } else if (withURI && (prop.type == propertyTypes.PDF)) {
    textToRet = data.uri;
  } else if (prop.type == propertyTypes.BOOLEAN || prop.type == propertyTypes.SIGNATURE) {
    textToRet = intl.formatMessage(data ? systemMessages.yes : systemMessages.no);
  }
  else if (prop.type == propertyTypes.DATE || prop.type == propertyTypes.CERTIFICATION) {
    let info = checkPropSettings(prop, data, intl);
    if (info.dataText)
      textToRet = info.dataText;
  }
  else if (data && prop.type === propertyTypes.LOCATION && typeof getAppState === 'function') {
    let locationString = null;

    const firstId = _.head(_.keys(data)); // TODO: support multi
    const fullLocationDetails = getFullLocationDetailsByIdNoProps(firstId); // TODO: support multi
    if (fullLocationDetails.locationFound)
      locationString = getLocationTitleNoProps({ intl, locationObj: fullLocationDetails.locationIds, separator: ' | ' });
    
    return locationString;
  }

  return textToRet
}

export function instancesDataToString (subjectPropertiesTypes, dataByPropId, intl, withURI) {
  const dataStringByPropId = {};
  
  Object.values(dataByPropId).forEach(([propId, data]) => {
    const prop = (subjectPropertiesTypes || {}).getNested([propId]);
    if (prop)
      dataStringByPropId[propId] = instanceDataToString(prop, data, intl, withURI);
  });

  return dataStringByPropId;
}

export async function getPropInstancesPerProject(companyProjects) {
  const firebase = platformActions.firebase.getFirebase();
  let instancesPerProject = {};
  
  await Promise.all(Object.keys(companyProjects).map(async currProjectId => {
    const projectInstances = (await firebase.database().ref(`properties/instances/projects/${currProjectId}`).once('value')).val() || null;
    writeLogOnce('info', 'getPropInstancesPerProject - listener Firebase', {
      scope: 'projects',
      scopeId: currProjectId,
      type: 'properties_instances',
    });
    if (!projectInstances) return;
    
    instancesPerProject[currProjectId] = projectInstances;
  }));
  
  return instancesPerProject;
}

export function getObjectsPrimaryProperty(objectsIds, subjectName, projectId, intl) {

  if (platformActions.app.getPlatform() == 'web')
    return;

  let primaryProp;
  let propTypes = _.isFunction(getAppState) && getAppState().getNested(['propertiesTypes', 'projectProperties', projectId]);

  propTypes = (propTypes && propTypes.toJS) ? propTypes.toJS() : propTypes;

  _.forIn(_.get(propTypes, [subjectName]), prop => {
    if (prop.isPrimary)
      primaryProp = prop;
  });

  if (!primaryProp || !primaryProp.id)
    return;

  let objectsPrimaryProperty = {};

  let query = `projectId = "${projectId}" AND subjectName = "${subjectName}" AND propId = "${primaryProp.id}" ${andOrBuilder(objectsIds, "parentId")}`;
  let instances = realmInstance.propertyInstances.objects('propertyInstance1').filtered(query);
  
  _.forIn(instances.realmToObject(), inst => {
    let data = inst.data;
    try { data = JSON.parse(data); }
    catch (err) { }
    _.set(objectsPrimaryProperty, [inst.parentId], instanceDataToString(primaryProp, data, intl));
  });

  return objectsPrimaryProperty;

}

const safeParseData = (_data) => {
  let data = _data;
  if (_.isString(data)) {
    try {
      data = JSON.parse(_data);
    }
    catch (err) {
      // data is not stringified object			
    }
  }
  return data;
};

export const getLocalFilesPaths = (_data) => {
  let paths = [];

  const data = safeParseData(_data);
  _.forIn(data, val => {
    const uri = val?.uri;
    const isLocal = !String(uri).startsWith('http');
    if (isLocal)
      paths.push(uri);
  });
  
  return paths;
};


const checkForExistingLocalFiles = async (data) => {
  for (const key in data) {
    const val = data[key];
    const uri = val?.uri;
    const isLocal = !String(uri).startsWith('http');
    if (isLocal && uri) {
      const path = uri?.replace('file:/', '');
      const isExist = await platformActions.fs.isPathExists(path);
      if (isExist) {
        return true;
      }
    }
  }
  return false;
};

export const isContainExistingLocalFiles = async (_data) => {
  const data = safeParseData(_data);
  return await checkForExistingLocalFiles(data);
};

export const getPropertiesTypesOfPostsInfo = ({ postsInfo, contentType }) => {
	if (postsInfo) {
		let _postsInfo = postsInfo;
		if (['records', 'safety'].includes(contentType) ) {
			_postsInfo = Object.entries(postsInfo).reduce((acc, [key, value]) => {
				if (!propertyTypes.RECORDS_BLACKLIST_COLUMNS.includes(value.id)) {
					acc[key] = value;
				}
				return acc;
			}, {});
		} else if (contentType !== 'safety') {
			_postsInfo = Object.entries(postsInfo).reduce((acc, [key, value]) => {
				if (!propertyTypes.NOT_SAFETY_POSTS_BLACKLIST_COLUMNS.includes(value.id)) {
					acc[key] = value;
				}
				return acc;
			}, {});
		}
		return _postsInfo;
	}
	return postsInfo;
};

export const parsePropInstanceFirebasePath = (path) => {
  const regex = /projects\/([^/]+)\/([^/]+)\/([^/]+)\/data/;
  const match = path.match(regex);

  if (!match) {
    return null;
  }

  const [_full, projectId, subjectName, id] = match;

  return { projectId, subjectName, id };
};


export const fetchPropertyInstanceById = id => serverSDK.properties.getInstance({ id });
