import React, { useContext, useState } from 'react';
import CreateMenuModal from './CreateMenuModal';
import useSavedMenus from '../../../../web/components/Header/useSavedMenus';
import { ObjectsWrapperManipulationContext } from '../../ObjectsWrapper';
import { safeFormatMessage } from '../../../app/funcs';
import useIntl from '../../../intl/useIntl';
import { saveMenus } from '../../../../web/menus/funcs';
import { ProjectContext } from '../../../projects/contexts';
import { useLocation } from 'react-router-dom';
import { hideLoading, startLoading, startToast } from '../../../app/actions';
import { useDispatch } from 'react-redux';
import { VISIBLE_COLUMNS_URL_KEY } from '../../../../web/app/constants';
import { encodeValueToSearch } from '../../../../web/app/funcs';

const CreateMenuControls = () => {
  const { setProjectCombinedMenus, selectedProjectId } = useContext(ProjectContext);

  const intl = useIntl();

  const { contentType, subjectType, columnDefinitions } = useContext(ObjectsWrapperManipulationContext);

  const selectedFav = useSavedMenus({ section: 'analytics', contentType }).selectedFav;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen((isModalOpen) => !isModalOpen);
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const handleDone = async ({ id, name, ordinalNo }, isDelete) => {
    const operationId = 'saveMenus_' + id;
    try {
      dispatch(
        startLoading({
          operationId,
          title: {
            defaultMessage: `${isDelete ? 'Deleting' : id ? 'Updating' : 'Creating'} menu, please wait...`,
            id: 'savingmenu',
          },
        })
      );

      let queryStr = location.search;
      // We init the visibleColumns query if none present to prevent other columns showing on the table if not explicitely set if props are added in the future
      if (!isDelete && !new URLSearchParams(queryStr).has(VISIBLE_COLUMNS_URL_KEY)) {
        const visibleColumns = columnDefinitions.filter((c) => c.isVisible).map((c) => c.key);
        queryStr = encodeValueToSearch(VISIBLE_COLUMNS_URL_KEY, visibleColumns, queryStr);
      }

      await saveMenus(
        selectedProjectId,
        selectedFav.values,
        { label: name, id, num: ordinalNo, query: queryStr },
        contentType,
        'analytics',
        subjectType,
        isDelete
      );
      await setProjectCombinedMenus(selectedProjectId, true);
      dispatch(
        startToast({ title: `Menu ${isDelete ? 'deleted' : id ? 'updated' : 'created'} successfully`, type: 'success' })
      );
      toggleModal();
    } catch (err) {
      dispatch(
        startToast({
          title: `Failed to ${
            isDelete ? 'delete' : id ? 'update' : 'create'
          } the menu, please try again later or grab the nearest dev and beg him for help`,
          type: 'error',
        })
      );
    } finally {
      dispatch(hideLoading(operationId));
    }
  };

  return (
    <>
      <div onClick={toggleModal} className='GroupsControls_header-button'>
        {'Save Menu'}
      </div>
      <CreateMenuModal
        open={isModalOpen}
        menuId={selectedFav.id}
        menuName={safeFormatMessage(intl, selectedFav.label)}
        menuOrdinalNo={selectedFav.num}
        onClose={() => setIsModalOpen(false)}
        onDone={handleDone}
      />
    </>
  );
};

export default CreateMenuControls;
