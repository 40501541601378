import { useMemo } from 'react';
import { filterByQuery } from '../../permissions/funcs';
import { FILTER_MENU_PATH_DELIMETER, FILTER_URL_KEY } from '../../../web/app/constants';
import { getCementoStringValue } from '../utils';

const useProcessedObjects = (objects, options) => {
  const { search, filters, sortBy, skip, step } = options;

  const filteredObjects = useMemo(() => {
    if (!objects?.length || !filters?.newSearch) {
      return objects;
    }
    const filteredObjects = filterByQuery(objects, filters.newSearch, FILTER_URL_KEY, FILTER_MENU_PATH_DELIMETER);
    const res = Object.values(filteredObjects);
    return res;
  }, [objects, filters?.newSearch]);

  const searchedObjects = useMemo(() => {
    if (!filteredObjects?.length || !search) {
      return filteredObjects;
    }

    return filteredObjects.filter((object) => {
      return Object.values(object).some((value) => {
        const stringValue = getCementoStringValue(value)?.toLowerCase?.();
        const searchLC = search.toLowerCase();
        const isFound = typeof stringValue === 'string' ? stringValue.includes(searchLC) : false;
        return isFound;
      });
    });
  }, [filteredObjects, search]);

  const sortedObjects = useMemo(() => {
    return sortObjects(searchedObjects, sortBy);
  }, [searchedObjects, sortBy]);

  const paginatedObjects = useMemo(() => {
    if (!sortedObjects?.length || typeof skip !== 'number' || typeof step !== 'number') {
      return sortedObjects;
    }

    return sortedObjects.slice(skip, skip + step);
  }, [sortedObjects, skip, step]);

  return paginatedObjects;
};

const sortObjects = (objects, sortBy) => {
  if (!objects?.length || !sortBy) {
    return objects;
  }

  const sortedObjects = [...objects].sort((a, b) => {
    const aValue = getCementoStringValue(a[sortBy.column]);
    const bValue = getCementoStringValue(b[sortBy.column]);

    if (aValue < bValue) {
      return sortBy.direction === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortBy.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  return sortedObjects;
};

export default useProcessedObjects;
