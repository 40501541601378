import { useState, useMemo } from 'react';
import Select, { components } from 'react-select';

import { useDetectClickOutside } from 'react-detect-click-outside';
import useIntl from '../../intl/useIntl';
import Text from '../../../web/components/CementoComponents/Text';
import theme from '../../../web/assets/css/theme';
import GroupByIcon from './GroupByIcon';
import { VIEW_TYPES } from '../const';

const GroupsControls = ({ viewType, value, groupByOptions, onChange }) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useDetectClickOutside({ onTriggered: () => setIsOpen(false) });

  const [primaryGroup, secondaryGroup] = value;

  const toggleOverlay = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const handleGroupChange = (group) => (selectedOption) => {
    let result = secondaryGroup ? [primaryGroup, secondaryGroup] : [primaryGroup];
    result[group] = selectedOption.value;
    onChange(result);
  };

  const handleReset = (group) => () => {
    if (group === 0) return onChange([]);
    else return onChange([primaryGroup]);
  };

  const options = useMemo(() => {
    return groupByOptions.map((item) => {
      return { value: item.key, label: <Text>{item.title}</Text> };
    });
  }, [groupByOptions]);

  const groupsValue =
    viewType === VIEW_TYPES.TABLE
      ? [
          options.find((option) => option.value === primaryGroup),
          options.find((option) => option.value === secondaryGroup) || null,
        ]
      : [options.find((option) => option.value === primaryGroup)];

  return (
    <div className='GroupsControls_container' ref={ref}>
      <div onClick={toggleOverlay} className='GroupsControls_header-button'>
        {intl.formatMessage({ id: 'objectsWrapper.groupsControls.title' })}
        <GroupByIcon style={{ marginRight: 8 }} />
      </div>
      {isOpen && (
        <div className='GroupsControls_menu'>
          {groupsValue.map((groupValue, index) => (
            <div key={`group${index}`} className='GroupsControls_menu_item'>
              <Select
                value={groupValue}
                onChange={handleGroupChange(index)}
                styles={SortSelectStyles}
                options={options.filter((option) => option.value !== (index === 0 ? secondaryGroup : primaryGroup))}
                components={{
                  SingleValue: ({ children, ...props }) => (
                    <components.SingleValue {...props}>
                      <div style={{ display: 'flex' }}>{children}</div>
                    </components.SingleValue>
                  ),
                }}
              />

              <div className='GroupsControls_menu_item_reset cursor-pointer' onClick={handleReset(index)}>
                {intl.formatMessage({ id: 'objectsWrapper.groupsControls.reset' })}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const SortSelectStyles = {
  ...theme.selectStyles,
  container: (base) => ({
    ...theme.selectStyles.container(base),
    minWidth: 160,
  }),
};

export default GroupsControls;
