import { defineMessages } from 'react-intl';

export default defineMessages({
  companyView: {
    defaultMessage: "Company view",
    id: 'companies.companyView'
  },
  manager: {
    defaultMessage: "Admin",
    id: 'companies.manager'
  },
  empty: {
    defaultMessage: 'No companies found',
    id: 'companies.company.empty'
  },
  loading: {
    defaultMessage: 'Loading Companies',
    id: 'companies.company.loading'
  },
  search: {
    defaultMessage: 'Search for a company',
    id: 'companies.company.search'
  },
  select: {
    defaultMessage: 'Member\'s company',
    id: 'companies.company.select'
  },
  generalCompanies: {
    defaultMessage: 'General Companies',
    id: 'companies.company.generalCompanies'
  },
  unknownCompany: {
    defaultMessage: 'Individuals',
    id: 'companies.company.unknown'
  },
  createNew: {
    defaultMessage: 'Create new company',
    id: 'companies.company.createNew'
  },
  writeCompanyName: {
    defaultMessage: 'Write company name',
    id: 'companies.company.writeCompanyName'
  },
  companyName: {
    defaultMessage: 'Company Name',
    id: 'companies.company.companyName'
  },
  companyType: {
    defaultMessage: 'Company Type',
    id: 'companies.company.companyType'
  },
  companyLogo: {
    defaultMessage: "Company logo",
    id: "system.manage.companyLogo"
  },
  companyTrades: { 
    defaultMessage: "Company trades",
    id: "companies.company.companyTrades",
  },
  similarCompanies: {
    defaultMessage: 'Similar companies names',
    id: 'companies.company.similarCompanies'
  },
  didYouMean: {
    defaultMessage: 'Did you mean?:',
    id: 'companies.company.didYouMean'
  },
  createMyCompany: {
    defaultMessage: 'No, Create my company',
    id: 'companies.company.createMyCompany'
  },
  selectCompanyType: {
    defaultMessage: 'Select company Type',
    id: 'companies.company.selectCompanyType'
  },
  selectCompany: {
    defaultMessage: "Select company",
    id: "companies.company.selectCompany"
  },
  selectCompanies: {
    defaultMessage: "Select companies",
    id: "companies.company.selectCompanies"
  },
  addContractors: {
    defaultMessage: "Add contractors",
    id: "companies.company.addContractors"
  },
  selectContractors: {
    defaultMessage: "Select contractors",
    id: "companies.company.selectContractors"
  },
  newCompanyPropertiesValidation: {
    defaultMessage: 'Please make sure you filled the trade and choose at least one project',
    id: 'companies.company.newCompanyPropertiesValidation'
  },
  companyTypes: {
    GENERAL: {
      defaultMessage: 'General',
      id: 'companies.companyTypes.GENERAL'
    },
    GC: {
      defaultMessage: 'General Contractor',
      id: 'companies.companyTypes.GC'
    },
    SUB: {
      defaultMessage: 'Subcontractor',
      id: 'companies.companyTypes.SUB'
    },
    DEV: {
      defaultMessage: 'Developer',
      id: 'companies.companyTypes.DEV'
    },
    CONS: {
      defaultMessage: 'Consultants',
      id: 'companies.companyTypes.CONS'
    },
    PROP: {
      defaultMessage: 'Property management',
      id: 'companies.companyTypes.PROP'
    },
    OTHER: {
      defaultMessage: 'Other',
      id: 'companies.companyTypes.OTHER'
    },
    SUPPLIER: {
      defaultMessage: 'Supplier',
      id: 'companies.companyTypes.SUPPLIER'
    },
    SUPERVISION: {
      defaultMessage: 'Supervision',
      id: 'companies.companyTypes.SUPERVISION'
    }
  },
  notPermittedFeature: {
    companyView: {
      qaTitle: {
        "defaultMessage": "Welcome to the company quality screen",
        "id": "companies.notPermittedFeature.companyView.qa.title"
      },
      qaContent: {
        "defaultMessage": "Please contact Cemento team at customers@cemento.ai",
        "id": "companies.notPermittedFeature.companyView.qa.content"
      },
      safetyTitle: {
        "defaultMessage": "Welcome to the company safety screen",
        "id": "companies.notPermittedFeature.companyView.qa.title"
      },
      safetyContent: {
        "defaultMessage": "Please contact Cemento team at customers@cemento.ai",
        "id": "companies.notPermittedFeature.companyView.qa.content"
      },
      settingsTitle: {
        "defaultMessage": "Welcome to the company members settings screen",
        "id": "companies.notPermittedFeature.companyView.settings.title"
      },
      settingsContent: {
        "defaultMessage": "Please contact Cemento team at customers@cemento.ai",
        "id": "companies.notPermittedFeature.companyView.settings.content"
      }
    },
  },
  companiesSettings: {
    "defaultMessage": "Settings",
    "id": "companies.companyViewManager.companiesSettings"
  },
  companiesHome: {
    "defaultMessage": "Home",
    "id": "companies.companyViewManager.companiesHome"
  },
  companyDetails: { 
    defaultMessage: 'Company details',
    id: 'companies.company.companyDetails'
  },
  companies: {
    defaultMessage: 'Companies',
    id: 'safety.main.companies',
  },
  workerCompany: {
    "defaultMessage": "Worker's company",
    "id": "companies.companyViewManager.workerCompany"
  },
  addCompany: {
    "defaultMessage": "Add company",
    "id": "companies.addCompany",
  },
  companyData:{
    "defaultMessage": "Company data",
    "id": "companies.companyData",
  },
  projectManagerPerson: {
		defaultMessage: 'Project Manager',
		id: 'companies.projectManagerPerson'
	}
});
