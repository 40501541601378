const NoItemsIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    style={{
      enableBackground: 'new 0 0 66 66',
    }}
    viewBox='0 0 66 82.5'
    {...props}>
    <path d='M22.71 31.52H8.72l-7.22 8.6h13.99zM48.67 29.11c5.48-5.48 5.48-14.37 0-19.85-5.48-5.48-14.37-5.48-19.85 0s-5.48 14.37 0 19.85 14.37 5.48 19.85 0zM30.94 11.38c2.08-2.08 4.86-3.23 7.8-3.23 2.95 0 5.72 1.15 7.8 3.23 4.3 4.3 4.3 11.3 0 15.6a10.96 10.96 0 0 1-7.8 3.23c-2.95 0-5.72-1.15-7.8-3.23-4.3-4.3-4.3-11.3 0-15.6z' />
    <path d='M38.74 28.22c2.41 0 4.68-.94 6.39-2.65 3.52-3.52 3.52-9.25 0-12.78a8.962 8.962 0 0 0-6.39-2.65c-2.41 0-4.68.94-6.39 2.65-3.52 3.52-3.52 9.25 0 12.78a8.98 8.98 0 0 0 6.39 2.65zm-5.11-12.74a.996.996 0 1 1 1.41-1.41l3.7 3.7 3.7-3.7a.996.996 0 1 1 1.41 1.41l-3.7 3.7 3.7 3.7a.996.996 0 0 1-.71 1.7c-.26 0-.51-.1-.71-.29l-3.7-3.7-3.7 3.7c-.2.2-.45.29-.71.29s-.51-.1-.71-.29a.996.996 0 0 1 0-1.41l3.7-3.7-3.68-3.7zM7.72 42.12v18.73h14.99V34.63l-6.29 7.49zM47.33 32.94l-.15-.15a15.868 15.868 0 0 1-8.45 2.42c-3.78 0-7.36-1.3-10.23-3.69h-3.8l7.22 8.59h20.52l-3.63-3.63a5.135 5.135 0 0 1-1.48-3.54zM63.59 39.64l-8.96-8.96a3.114 3.114 0 0 0-2.88-.83l-.56-.56a16.002 16.002 0 0 1-2.34 2.34l.56.56c-.23 1 .05 2.1.83 2.88l8.96 8.96a3.108 3.108 0 0 0 4.39 0 3.108 3.108 0 0 0 0-4.39z' />
    <path d='M24.71 34.63v26.22h26.62V42.12H31l-6.29-7.49zm11.37 22.69h-7.84c-.55 0-1-.45-1-1s.45-1 1-1h7.84c.55 0 1 .45 1 1s-.45 1-1 1zm1-4.91c0 .55-.45 1-1 1h-7.84c-.55 0-1-.45-1-1s.45-1 1-1h7.84c.55 0 1 .45 1 1z' />
  </svg>
);
export default NoItemsIcon;
