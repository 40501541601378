import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { ObjectsWrapperDataContext, ObjectsWrapperManipulationContext } from '../ObjectsWrapper';
import systemMessages from '../../app/systemMessages';
import theme from '../../../web/assets/css/theme';
import StandardInput from '../../../web/components/CementoComponents/StandardInput';
import useIntl from '../../intl/useIntl';
import buildingsMessages from '../../buildings/buildingsMessages';
import floorsMessages from '../../floors/floorsMessages';
import unitsMessages from '../../units/unitsMessages';
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import { convertCementoML } from '../../app/funcs';
import { useCementoRouter } from '../../../web/components/Router/util/withRouterHOC';
import { getCementoStringValue } from '../utils';

const WITH_IDS = 'withIds';
const WITH_FILES = 'withFiles';
const WITH_FILE_URLS = 'withFileUrls';

/**
 *
 * @param {{ viewType?: 'grid' | 'list' }} props
 * @returns
 */
const ExportButton = () => {
  const { match } = useCementoRouter();
  const intl = useIntl();
  const rtl = useSelector((state) => state.app.rtl);

  const { data, rawObjects } = useContext(ObjectsWrapperDataContext);
  const { subjectType, columnDefinitions } = useContext(ObjectsWrapperManipulationContext);

  const selectedMenuColumns = columnDefinitions.reduce((acc, c) => {
    acc[c.key] = c.isVisible;
    return acc;
  }, {});

  const allProps = useMemo(() => _.keyBy(rawObjects, 'id'), [rawObjects]);
  const filteredRowsMap = useMemo(() => _.keyBy(data, 'id'), [data]);

  const selectedProjectId = match.params.selectedProjectId;

  const prepareDataForExport = (mode) => {
    mode = mode || '';
    const isWithIdsMode = mode.indexOf(WITH_IDS) !== -1;
    const isWithFilesMode = mode.indexOf(WITH_FILES) !== -1;
    const isWithFileUrlsMode = mode.indexOf(WITH_FILE_URLS) !== -1;
    const sheetName = 'Properties';

    let relevantPopulatedObjects = _.values(allProps || {}).filter((o) => filteredRowsMap[o.id]);

    let primaryColumns = [];
    let columnsToExport = {};
    if (subjectType === 'locations') {
      primaryColumns = [
        intl.formatMessage(buildingsMessages.building),
        intl.formatMessage(floorsMessages.floor),
        intl.formatMessage(unitsMessages.unit),
      ];

      Object.entries(selectedMenuColumns).forEach(([colId, options]) => {
        if (_.get(options, ['table'], false)) columnsToExport = _.set(columnsToExport, colId, true);
      });
    } else {
      if (isWithIdsMode) primaryColumns = ['ID'];
      else {
        primaryColumns = relevantPopulatedObjects.reduce((acc, populatedObject) => {
          const currPrimaryColName = populatedObject.getNested(['primaryProp', 'propTitle'], null);
          if (!currPrimaryColName || acc.includes(currPrimaryColName)) return acc;

          return [...acc, currPrimaryColName];
        }, []);
      }
    }

    let data = {
      [sheetName]: {
        rowsData: [],
        sheetStructure: {},
        sheetView: [{ state: 'frozen', xSplit: primaryColumns.length, ySplit: 1, rightToLeft: rtl }],
      },
    };

    primaryColumns.forEach(
      (colName) => (data = data.setNested([sheetName, 'sheetStructure', colName], { name: colName }))
    );

    const getInstanceDataPath = (instanceId) =>
      `properties/instances/projects/${selectedProjectId}/${subjectType + 'Info'}/${instanceId}/data`;

    let dynamicSheetStructurePerSection = {};
    relevantPopulatedObjects.forEach((populatedObject) => {
      const { props: properties, id } = populatedObject;
      let rowData = {};
      if (isWithIdsMode) rowData['ID'] = id;

      Object.values(properties || {})
        .sort((propA, propB) => propA?.ordinalNo - propB?.ordinalNo)
        .forEach((property) => {
          let propId = property.id;
          if (propId === 'groups' || (subjectType === 'locations' && !_.get(columnsToExport, [propId], false))) return;

          let { section, type, lastCertExpirationTS, instanceId } = property;
          const propTitle = property.title?.id ? intl.formatMessage(property.title) : property.getCementoTitle();
          const processedObject = filteredRowsMap[populatedObject.id];
          const processedPropData = processedObject[propId];
          const propData = populatedObject[propId];

          let dataText = getCementoStringValue(processedPropData);

          if (!_.isNil(propData))
            switch (type) {
              case propertyTypes.DATE:
                dataText = new Date(propData);
                break;

              case propertyTypes.PICTURE:
              case propertyTypes.VIDEO:
              case propertyTypes.PDF:
              case propertyTypes.FILES_ARRAY:
              case propertyTypes.DRAWINGS_ARRAY:
                if (isWithFilesMode) dataText = getInstanceDataPath(instanceId);
                else if (isWithFileUrlsMode) {
                  let filesData = propData;
                  if (type === propertyTypes.PICTURE || type === propertyTypes.VIDEO) filesData = [{ uri: propData }];
                  else if (type === propertyTypes.PDF) filesData = [propData];

                  filesData = (Array.isArray(filesData) ? filesData : [filesData]).filter(Boolean);

                  dataText = filesData.length
                    ? filesData
                        .filter((fileMeta) => Boolean(fileMeta))
                        .map((fileMeta) => fileMeta.uri)
                        .filter(Boolean)
                        .join(', ')
                    : null;
                } else {
                  let filesData = (Array.isArray(propData) ? propData : [propData]).filter(Boolean);

                  dataText = filesData.length
                    ? intl.formatMessage(systemMessages.fileCount, { filesCounter: filesData.length })
                    : null;
                }
                break;

              case propertyTypes.CERTIFICATION:
                if (isWithFilesMode) dataText = getInstanceDataPath(instanceId);
                else if (isWithFileUrlsMode) {
                  let lastCert = Array.isArray(propData) && _.last(propData);
                  if (lastCert && lastCert.attachments)
                    dataText = lastCert.attachments
                      .map((fileMeta) => fileMeta.data || fileMeta.uri)
                      .filter(Boolean)
                      .join(', ');
                  else dataText = null;
                } else dataText = lastCertExpirationTS ? new Date(lastCertExpirationTS) : null;
                break;

              case propertyTypes.BOOLEAN:
                dataText = !_.isNil(propData) ? Boolean(propData) : null;
                break;

              case propertyTypes.STRING:
                dataText = dataText
                  ? convertCementoML(
                      dataText,
                      (string) => string,
                      (string) => string,
                      '\n'
                    )
                      .filter(Boolean)
                      .join('')
                  : null;
                break;

              default:
                break;
            }

          const sectionTitle = section.getCementoTitle();
          const isPrimaryColumn = primaryColumns.includes(propTitle);
          let columnName = null;
          let richColName = [];
          if (isPrimaryColumn) {
            columnName = propTitle;
          } else {
            columnName = `${sectionTitle}\n${propTitle.split('\n').join(' ').trim()}`;
            richColName.push({ text: sectionTitle + '\n', style: { color: theme.brandPrimary, bold: true } });
          }

          richColName.push({ text: propTitle.split('\n').join(' ').trim(), style: { bold: true } });
          rowData[columnName] = dataText;
          if (!dynamicSheetStructurePerSection.getNested([sectionTitle, columnName], false))
            dynamicSheetStructurePerSection = dynamicSheetStructurePerSection.setNested([sectionTitle, columnName], {
              name: richColName,
              style: { alignment: { wrapText: true } },
              ordinalNo: isPrimaryColumn ? 0 : property.ordinalNo,
            });
        });

      data[sheetName].rowsData.push(rowData);
    });

    let orderedSheetStructure = {};
    dynamicSheetStructurePerSection.loopEach((sectionTitle, sheetStructure) => {
      orderedSheetStructure = {
        ...orderedSheetStructure,
        ['emptyCol' + sectionTitle]: {
          name: '',
          style: {
            width: 2,
            backgroundColor: '#d9d9d9',
          },
          ordinalNo: _.chain(orderedSheetStructure).values().last().get('ordinalNo').value(), // these are styling columns, we give same ordinalNo as last column of the section so they stick together later when they get ordered
        },
        ...sheetStructure,
      };
    });
    data = data.setNested([sheetName, 'sheetStructure'], {
      ...data[sheetName].sheetStructure,
      ...orderedSheetStructure,
    });

    return {
      fileName: `${selectedProjectId}_${subjectType === 'locations' ? 'units' : subjectType}_properties`,
      data,
    };
  };

  const exportSettings = {
    exportMethods: [
      {
        id: 'default',
        label: systemMessages.manage.excelExport,
        getDataToExportFunc: () => prepareDataForExport(null, true),
      },
      {
        id: WITH_IDS,
        label: 'Export with IDs',
        getDataToExportFunc: () => prepareDataForExport(WITH_IDS),
        viewerPermissions: [{ adminMode: 1 }],
      },
      {
        id: `${WITH_FILES}`,
        label: 'Export with Files',
        getDataToExportFunc: () => prepareDataForExport(`${WITH_FILES}`),
        viewerPermissions: [{ adminMode: 1 }],
      },
      {
        id: `${WITH_FILES}_${WITH_IDS}`,
        label: 'Export with File DB Paths',
        getDataToExportFunc: () => prepareDataForExport(`${WITH_FILES}_${WITH_IDS}`),
        viewerPermissions: [{ adminMode: 1 }],
      },
      {
        id: `${WITH_FILE_URLS}`,
        label: 'Export with File URLs',
        getDataToExportFunc: () => prepareDataForExport(WITH_FILE_URLS),
        viewerPermissions: [{ adminMode: 1 }],
      },
    ],
  };

  return (
    <StandardInput
      type={'Excel'}
      containerStyle={{ flex: 0, position: 'relative', zIndex: 99 }}
      label={systemMessages.manage.exportTitle}
      settings={exportSettings}
      innerStyle={{ marginRight: theme.margin - 5 }}
    />
  );
};

export default ExportButton;
