import React, { useMemo, useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { getDefaultProjectMenus, getProjectCombinedMenus } from '../../menus/funcs';
import { injectIntl } from '../../../common/app/myInjectIntl';
import BurgerMenuIcon from '../../assets/img/icons/BurgerMenu';
import { headerMainRoutes } from '../../routes/main';
import cssTheme from '../../assets/css/theme';
import systemMessages from '../../../common/app/systemMessages';
import { FILTER_URL_KEY, VISIBLE_COLUMNS_URL_KEY } from '../../app/constants';
import { encodeFilterToSearch, encodeValueToSearch } from '../../app/funcs';
import { envParams } from '../../../common/configureMiddleware';
import { platformActions } from '../../../common/platformActions';
import { DotLoader } from "react-spinners";
import Text from "../CementoComponents/Text";
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'column',
		width: '280px',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		borderBottom: `1px solid ${theme.palette.divider}`,
		padding: '4px',
		'& span': {
			margin: '0 8px',
		},
	},
	root: {
		flexGrow: 1,
		display: 'flex',
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
		borderLeft: `1px solid ${theme.palette.divider}`,
		width: '50%',
	},
	tabPanels: {
		width: '50%',
	},
	tab: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		fontWeight: cssTheme.bold,
		color: cssTheme.textColorDark,
		'& > span': {
			alignItems: 'flex-start',
			textAlign: 'start',
		},
	},
	selectedTab: {
		color: cssTheme.brandPrimary,
	},
	tabsIndicator: {
		backgroundColor: cssTheme.brandPrimary,
	},
	tabPanel: {
		display: 'flex',
		flexDirection: 'column',
		'& div + *': {
			marginTop: '1rem',
		},
		'& p': {
			fontSize: '0.7rem',
			color: cssTheme.textColorGray,
			fontWeight: cssTheme.bold,
		},
		'& a': {
			color: cssTheme.textColorDark,
		},
	},
	cursorPointer: {
		cursor: 'pointer',
	},
}));

const configToMenuMap = {
	// Paired
	'safety': 'safety',
	'quality': 'qa',
	'spec': 'info',

	// Default menu
	'members': 'members',
	'issues': 'issues',
	'drawings': 'drawings',
	'records': 'records',
	'details': 'details',

	// Default config
	'dailyReport': 'dailyReport',
};

const VerticalMenu = ({ projectId, configurations, closeMenu, intl }) => {
	const classes = useStyles();
	const [value, setValue] = useState(0);
	const [menus, setMenus] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
  
	useEffect(() => {
	  // Define the async function inside the useEffect
	  const fetchMenus = async () => {
		try {
		  // Make the HTTP request

		  const res = await platformActions.net.fetch(`${envParams.apiServer}/v1/settings?projectId=${projectId}&subjects=["menu"]&excludeValues=false`);
		  const responseData = await res.getJson();
		  // Update the state with the fetched data
		  setMenus(responseData.menu);
		} catch (error) {
		  // Update the state with the error
		  setError(error);
		} finally {
		  // Update the loading state
		  setLoading(false);
		}
	  };
  
	  // Call the async function
	  fetchMenus();
	}, []); // The empty dependency array ensures this effect runs only once when the component mounts

  
	const projectMenus = useMemo(() => {
		const projectMenus = getProjectCombinedMenus({
			menus,
			defaultProjectMenu: getDefaultProjectMenus({ formsInfoPropTypes: null }),
		});

		// Menus that we don't want to show
		let hiddenMenus = Object.entries(configurations).reduce(
			(acc, [key, value]) => {
				if (!value.isActive) {
					acc.push(configToMenuMap[key]);
				}
				return acc;
			},
			['forms', 'siteControl'], // Menus that we won't show by default
		);

		const filteredMenus = Object.entries(projectMenus).reduce((acc, [key, menu]) => {
			if (!hiddenMenus.includes(key) && Object.keys(menu).length) {
				const submenus = Object.entries(menu).reduce((acc, [submenuKey, submenuValue]) => {
					const filteredOptions = submenuValue.options.filter(option => option.contentType || option.subjectType);
					if (filteredOptions.length) {
						acc[submenuKey] = {
							...submenuValue,
							options: filteredOptions,
						};
					}
					return acc;
				}, {});

				acc[key] = submenus;
			}
			return acc;
		}, {});

		return filteredMenus;
	}, [menus]);

	const handleChange = (e, value) => {
		setValue(value);
	};

	const buildRedirectHref = option => {
		const { contentType, query, id } = option;
		const filter = option?.values?.filter || {}
		let visibleColumns;
		if (_.size(option.values?.columnVisibility)) {
			visibleColumns = Object.entries(option?.values?.columnVisibility)
        .filter(([, { table: isVisible }]) => isVisible)
        .map(([key]) => key);
		}

		
		let type = option.pageType || option.type;
		let buildingView = null;
		if (type === 'buildingView') {
			buildingView = 'locationContainerPage/ALL/_/_';
			type = null;
		} else if (type === 'dashboardView') {
			type = 'dashboard';
		}

		const path = [projectId, contentType, type, buildingView, id].filter(Boolean);
		let newQuery = query ? query : '';
		newQuery = encodeValueToSearch(VISIBLE_COLUMNS_URL_KEY, visibleColumns, newQuery);
		let href = `/main/projectContainerPage/${path.join('/')}${newQuery ? `?${newQuery}` : ''}`;
		
		if(!_.isEmpty(filter)){
			const filterQuery = encodeFilterToSearch(filter , '', FILTER_URL_KEY);
			href += `&${filterQuery}`
		}

		return href
	};

	const getLinkLabel = label => (typeof label === 'object' ? intl.formatMessage(label) : label);

	const headerMainRoutesNamesMapByContentType = useMemo(
		() =>
			headerMainRoutes.reduce((acc, route) => {
				acc[route.contentType] = intl.formatMessage(route.name);
				return acc;
			}, {}),
		[headerMainRoutes],
	);
  
	return (
		<div className={classes.container}>
			<header className={classes.header}>
				<BurgerMenuIcon className={classes.cursorPointer} onClick={closeMenu} />
				<span>{intl.formatMessage(systemMessages.quickMenu)}</span>
			</header>
			<div className={classes.root}>
				<Tabs
					classes={{ indicator: classes.tabsIndicator }}
					orientation='vertical'
					variant='fullWidth'
					value={value}
					onChange={handleChange}
					className={classes.tabs}
				>
					{Object.keys(projectMenus).map(
						key =>
							headerMainRoutesNamesMapByContentType[key] && (
								<Tab
									classes={{
										root: classes.tab,
										selected: classes.selectedTab,
									}}
									key={`Tab-${key}`}
									label={headerMainRoutesNamesMapByContentType[key]}
								/>
							),
					)}
				</Tabs>
				<div className={classes.tabPanels}>
					{(loading || error) ? 
						<div style={{alignContent: 'center', width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems: 'center'}}>
							{error && <><div style={{margin:cssTheme.margin}}><Text>{systemMessages.error}</Text><br/>{error.message}</div></>}
							{loading && <DotLoader
								size={20}
								color={cssTheme.brandPrimary}
								loading={true}
								/>}
						</div> : 
						Object.entries(projectMenus).map(([menuKey, menu], index) => (
							<TabPanel className={classes.tabPanel} key={`TabPanel-${menuKey}-${index}`} value={value} index={index}>
								{Object.values(menu).map(section => (
									<div key={`TabPanel-wrapper-${section.caption.defaultMessage}`}>
										<Typography key={`TabPanel-Section-${section.caption.defaultMessage}`}>
											{intl.formatMessage(section.caption)}
										</Typography>
										<div style={{ display: 'flex', flexDirection: 'column' }} key={`TabPanel-div-${menuKey}-${index}`}>
											{Object.values(section.options).map((option, index) => (
												<Link
													key={`TabPanel-Section-${section.caption.defaultMessage}-Link-${index}`}
													href={buildRedirectHref(option)}
													target='_blank'
												>
													{getLinkLabel(option.label)}
												</Link>
											))}
										</div>
									</div>
								))}
							</TabPanel>
						))}
				</div>
			</div>
		</div>
	);
};

const TabPanel = props => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index ? <Box p={3}>{children}</Box> : null}
		</div>
	);
};

export default injectIntl(VerticalMenu);
