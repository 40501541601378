import { useCallback, useEffect, useState } from 'react';
import { decodeValueFromSearch, encodeValueToSearch } from '../app/funcs';
import { useLocation } from 'react-router-dom';
import { useCementoRouter } from '../components/Router/util/withRouterHOC';

/**
 * @param {string} key
 * @param {any} [defaultVal]
 * @returns
 */
const useLocationSearchValue = (key, defaultVal) => {
  const hookLocation = useLocation();
  const { history } = useCementoRouter();
  const [searchValue, setSearchValue] = useState(() => decodeValueFromSearch(key, hookLocation.search) ?? defaultVal);

  useEffect(() => {
    setSearchValue(decodeValueFromSearch(key, hookLocation.search || '') ?? defaultVal);
  }, [key, hookLocation.search]);

  /** @type {ReturnType<typeof useCallback<(value: any | ((prevValue: any) => any)) => any>>} */
  const handleSetLocationSearchValue = useCallback(
    (value) => {
      const newVal =
        typeof value === 'function' ? value(decodeValueFromSearch(key, hookLocation.search) ?? defaultVal) : value;
      history.push({ search: encodeValueToSearch(key, newVal, hookLocation.search) });
    },
    [key, hookLocation.search, history]
  );

  return [searchValue, handleSetLocationSearchValue];
};

export default useLocationSearchValue;
