import { memo, useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { getCommonCellStyles } from '../../util';
import { getCementoStringValue } from '../../../../../utils';
import {
  FILES_ARRAY,
  DRAWINGS_ARRAY,
  PDF,
  PICTURE,
  VIDEO,
  BOOLEAN,
} from '../../../../../../propertiesTypes/propertiesTypes';
import IssueStateCell from './IssueStateCell';
import AttachmentsCell from './AttachmentsCell';
import CheckboxCell from './CheckboxCell';
import TradeCell from './TradeCell';
import ExpandableTableCell from '../ExpandableTableCell';

const Base = ({ customCellId, isSummaryCell, isGroupBy, cellContent }) => (
  <span
    key={customCellId + '_text'}
    className={'CustomCell_base'}
    title={cellContent}
    style={{
      padding: isSummaryCell ? 0 : '0 4px',
      cursor: isSummaryCell ? 'auto' : 'pointer',
      color: isSummaryCell && !isGroupBy ? 'rgb(247, 125, 4)' : 'black',
      fontSize: isSummaryCell && !isGroupBy ? 21 : 14,
      fontWeight: isSummaryCell && !isGroupBy ? 600 : 14,
    }}>
    {cellContent}
  </span>
);
const BaseMemo = memo(Base);

const CustomCell = ({ cell, className }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const style = useMemo(() => {
    return getCommonCellStyles({ column: cell.column, isDragging, transform });
  }, [cell.column, isDragging, transform]);

  const { customCellId, columnId, cementoType, cellValue, cellContent, isSummaryCell, isGroupBy, CustomComponent } =
    useMemo(() => {
      const columnId = cell.column.id;
      const cementoType = cell.column.columnDef.cementoType;
      const cellValue = cell.row.original[columnId];
      const cellContent = getCementoStringValue(cell.row.original[columnId]);
      const isSummaryCell = !!cell.row.original.subRows;
      const isGroupBy = cell.column.columnDef.groupByIndex === 0 && cell.row.original.subRows;
      const customCellId = cellValue?.key || columnId;

      const CustomComponent = CustomUI[customCellId] || CustomUI[cementoType] || BaseMemo;

      return {
        customCellId,
        columnId,
        cementoType,
        cellValue,
        cellContent,
        isSummaryCell,
        CustomComponent,
        isGroupBy,
      };
    }, []);

  return (
    <td key={cell.id + '_customCell'} style={style} className={`CustomCell ${className}`} ref={setNodeRef}>
      <div style={{ width: style?.width }}>
        <ExpandableTableCell cell={cell} cellValue={cellValue}>
          <CustomComponent
            key={cell.id + '_customCellComponent'}
            id={customCellId}
            columnId={columnId}
            cementoType={cementoType}
            cellValue={cellValue}
            cellContent={cellContent}
            isSummaryCell={isSummaryCell}
            isGroupBy={isGroupBy}
            cell={cell}
          />
        </ExpandableTableCell>
      </div>
    </td>
  );
};

const CustomUI = {
  'issueState': IssueStateCell,
  'trade': TradeCell,
  [FILES_ARRAY]: AttachmentsCell,
  [DRAWINGS_ARRAY]: AttachmentsCell,
  [PDF]: AttachmentsCell,
  [PICTURE]: AttachmentsCell,
  [VIDEO]: AttachmentsCell,
  [BOOLEAN]: CheckboxCell,
};

export default memo(CustomCell);
