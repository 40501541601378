import { flattenBy } from '@tanstack/react-table';
import theme from '../../../../../../../web/assets/css/theme';
import DotStatusText from '../../../../../../../web/components/CementoComponents/DotStatusText';
import { getStatusParams } from '../../../../../../issues/issueStates';
import './style.css';
import _ from 'lodash';

const dotSize = 10;

const dotStyle = {
  height: dotSize,
  width: dotSize,
  marginInline: theme.margin / 3,
  flexShrink: 0,
};

const labelStyle = {
  flexShrink: 0,
};

const containerStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  margin: '0 5px',
};

const IssueStateCell = ({ cell, cellValue, isSummaryCell }) => {
  if (isSummaryCell) {
    const countByStatus = cell.row.getLeafRows().reduce((acc, row) => {
      if (!row.originalSubRows) {
        acc[row.original.issueState.id] = (acc[row.original.issueState.id] || 0) + 1;
      }
      return acc;
    }, {});

    return (
      <div className='IssueStateCell_summary'>
        {Object.entries(countByStatus).map(([statusId, count]) => {
          const params = getStatusParams({ issueState: statusId });
          return (
            <DotStatusText
              key={statusId}
              label={count}
              color={params.color}
              dotStyle={dotStyle}
              containerStyle={containerStyle}
              labelStyle={labelStyle}
            />
          );
        })}
      </div>
    );
  }

  const params = getStatusParams({ issueState: cellValue?.id });

  return (
    <div className='IssueStateCell IssueStateCell_root'>
      <div className='IssueStateCell__content'>
        <DotStatusText
          label={params.message}
          color={params.color}
          dotStyle={dotStyle}
          containerStyle={containerStyle}
          labelStyle={labelStyle}
        />
      </div>
    </div>
  );
};

export default IssueStateCell;
