import React, {useCallback, useContext, useEffect, useState} from "react";
import { ProjectContext } from "../../../common/projects/contexts";
import { injectIntl } from "react-intl";
import _ from "lodash";

import MultiCheckSelect from "../../components/CementoComponents/MultiCheckSelect";
import InputField from "../../components/CementoComponents/InputField";
import Modal from "../../components/CementoComponents/Modal";

// Assets
import theme from "../../assets/css/theme";
import { Checkbox } from "@material-ui/core";
import buttonsMessages from "../../../common/app/buttonsMessages";
import reportsMessages from "../../../common/reports/reportsMessages";

const TableFilters = ({
  filters: propFilters,
  updateFilters: propUpdateFilters,
  intl,
  selectedFilterSet: propSelectedFilterSet,
  updateSelectedFilterSet: propUpdateSelectedFilterSet,
  originalSections,
  originalColumns,
  handleSaveFilter,
  showColumnSelector,
  propertiesSections,
  propertiesTypes,
  subjectName,
}) => {
  const { lang, rtl, checklists } = useContext(ProjectContext);
  const [adminModal, setAdminModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [selectedFilterSet, setSelectedFilterSet] = useState({});

  useEffect(() => {
    setFilters(propFilters);
  }, [propFilters]);

  useEffect(() => {
    setSelectedFilterSet(propSelectedFilterSet);
  }, [propSelectedFilterSet]);

  const updateFilters = useCallback((updates) => {
    const newFilters = {
      ...filters,
      ...updates,
    }
    setFilters(newFilters);
    propUpdateFilters(newFilters);
  }, [propUpdateFilters, filters]);

  const updateSelectedFilterSet = useCallback((updates) => {
    const newFilterSet = {
      ...selectedFilterSet,
      ...updates,
    }
    setSelectedFilterSet(newFilterSet);
    propUpdateSelectedFilterSet(newFilterSet);
  }, [propUpdateSelectedFilterSet, selectedFilterSet]);

  const openAdminModal = useCallback(() => {
    setAdminModal(true);
  }, []);

  const closeAdminModal = useCallback(() => {
    setAdminModal(false);
  }, []);

  const handleMultiSelectChange = (type, isPairedChecklist, pairedToType) => (allItemsNew, allCheckedMap, allUnheckedMap) => {
		const newColumnVisibility = {
			...(filters || {}).columnVisibility,
		};

		Object.values(allCheckedMap || {}).forEach(newItem => {
			if (isPairedChecklist) {
				if (!newColumnVisibility[newItem.id]?.[pairedToType]) {
					_.set(newColumnVisibility, [newItem.id, type], true);
				}
			} else {
				_.set(newColumnVisibility, [newItem.id, type], true);
				if (newItem.subId) {
					const basePath = [newItem.id, 'subColumnVisibility', newItem.subId];
					_.set(newColumnVisibility, [...basePath, type], true);
				}
			}
		});
		Object.values(allUnheckedMap || {}).forEach(newItem => {
			if (isPairedChecklist) {
				if (newColumnVisibility[newItem.id]?.[pairedToType]) {
					delete newColumnVisibility[newItem.id][type];
				}
			} else {
				if (newColumnVisibility[newItem.id]?.[type]) {
					if (newItem.subId) {
						if (_.get(newColumnVisibility, [newItem.id, 'subColumnVisibility', newItem.subId, type])) {
							delete newColumnVisibility[newItem.id].subColumnVisibility[newItem.subId][type];
							if (!Object.values(newColumnVisibility[newItem.id].subColumnVisibility).find(a => a[type])) {
								delete newColumnVisibility[newItem.id][type];
								delete newColumnVisibility[newItem.id].subColumnVisibility;
							}
						}
					} else {
						delete newColumnVisibility[newItem.id][type];
					}
				}
			}
		});

		const newFilters = _.set(filters || {}, ['columnVisibility'], newColumnVisibility);
		updateFilters(newFilters);
	};

  const filteredOriginalColumns = Object.values(originalColumns || {}).filter((col) =>
    Boolean(!checklists.getNested([(col || {}).id, "originChecklist"]))
  ); // filter duplications

  // TODO: Do this only when on admin mode
  let columnTableVisibilityArray = [];
  let columnTableVisibilityMap = {};
  Object.values(filteredOriginalColumns || {})
    .filter((x) => (!originalSections || x.parentId))
    .forEach((column) => {
      const sectionTitle = originalSections
        ? _.get(originalSections, [column.parentId, "title"], "")
        : column.section;
      if (filters?.filterBySubColumn) {
        Object.values(column.subColumns || {}).forEach((subColumn) => {
          const itemChecked = Boolean(
            filters &&
            _.get(filters, [
              "columnVisibility",
              column.id,
              "subColumnVisibility",
              subColumn.checklistItemId,
              "table",
            ])
          );
          const columnObject = {
            id: column.id,
            subId: subColumn.checklistItemId,
            label:
              sectionTitle + " / " + column.title + " / " + subColumn.title,
            checked: itemChecked,
            ordinalNo: subColumn.num,
          };
          columnTableVisibilityArray.push(columnObject);
          columnTableVisibilityMap[column.id] = columnObject;
        });
      } else {
        const checked = Boolean(filters?.getNested(["columnVisibility", column.id, "table"]));
        const columnObject = {
          id: column.id,
          label: sectionTitle + " / " + column.title,
          checked,
          ordinalNo: column.num,
        };
        columnTableVisibilityArray.push(columnObject);
        columnTableVisibilityMap[column.id] = columnObject;
      }
    });

  let columnCardVisibilityArray = Object.values(filteredOriginalColumns || {})
    .filter((x) => !originalSections || x.parentId)
    .map((column) => {
      const isChecked = Boolean(
        _.get(filters, ["columnVisibility", column.id, "card"])
      );
      const sectionTitle =
        _.get(originalSections, [column.parentId, "title"], column.section) ||
        "";
      return {
        id: column.id,
        label: sectionTitle + " / " + column.title,
        checked: isChecked,
        ordinalNo: column.num,
      };
    });

  const subjectProperties = _.get(propertiesTypes, [subjectName], {});

  _.values(subjectProperties).forEach((property) => {
    const isExistsInTheArrayAlready = columnTableVisibilityMap[property.id];
    if (!isExistsInTheArrayAlready) {
      const sectionTitle = _.get(
        propertiesSections,
        [subjectName, property.sectionId, "title", lang],
        ""
      );
      
      let propertyTitle = property.title?.id ? intl.formatMessage(property.title) : property.getCementoTitle()

      columnTableVisibilityArray.push({
        id: property.id,
        label: sectionTitle + ' / ' + propertyTitle,
        checked: _.get(filters, ['columnVisibility', property.id, 'table'], false),
        ordinalNo: property.num,
      });
      columnCardVisibilityArray.push({
        id: property.id,
        label: sectionTitle + ' / ' + propertyTitle,
        checked: _.get(filters, ['columnVisibility', property.id, 'card'], false),
        ordinalNo: property.num,
      });
    }
  });

  columnTableVisibilityArray = columnTableVisibilityArray.sort((a, b) =>
    (a.label || "").localeCompare(b.label || "")
  );
  columnCardVisibilityArray = columnCardVisibilityArray.sort((a, b) =>
    (a.label || "").localeCompare(b.label || "")
  );

  let reportLabel = (selectedFilterSet || {}).label;
  if (reportLabel && typeof reportLabel == "object") {
    reportLabel = intl.formatMessage(reportLabel);
  }

  return (
    <div
      style={{
        height: 30,
        alignSelf: "center",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        background: "lightgray",
        [rtl ? "marginLeft" : "marginRight"]: 10,
        cursor: "pointer",
        padding: 5,
        borderRadius: 5,
      }}
    >
      <div onClick={openAdminModal}>
        <span>{intl.formatMessage(buttonsMessages.selectColumns)}</span>
      </div>
      <Modal style={{ zIndex: theme.zIndexes.highest }} onClose={closeAdminModal} open={adminModal}>
        {Boolean(adminModal) && (
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "flex-end",
            flex: 1,
            padding: 2 * theme.paddingSize,
            maxHeight: 800,
          }}
        >
          <span>Filter by sub columns</span>
          <Checkbox
            checked={(filters || {}).filterBySubColumn}
            onChange={(e, isChecked) => {
              let columnVisibility = Object.keys(
                (filters || {}).columnVisibility || {}
              ).reduce((acc, filterId) => {
                acc[filterId] = { card: true };
                return acc;
              }, {});
              updateFilters({
                ...(filters || {}),
                columnVisibility,
                filterBySubColumn: isChecked,
              });
            }}
          />
          <div style={{ margin: theme.paddingSize, maxHeight: "inherit" }}>
            <span>{intl.formatMessage(reportsMessages.columnsPicker.tableColumns)}</span>
            <MultiCheckSelect
              style={{ margin: 5 }}
              items={columnTableVisibilityArray}
              titlePropPath={["label"]}
              onChange={(...args) => {
                const isPairedChecklist = true
                handleMultiSelectChange('card', isPairedChecklist, 'table')(...args);
                handleMultiSelectChange('table')(...args);
              }}
            />
          </div>

          {Boolean(showColumnSelector) && (
            <div style={{ margin: theme.paddingSize, maxHeight: "inherit" }}>
              <span>{intl.formatMessage(reportsMessages.columnsPicker.sidecardColumns)}</span>
              <MultiCheckSelect
                style={{ margin: 5 }}
                items={columnCardVisibilityArray}
                titlePropPath={["label"]}
                onChange={handleMultiSelectChange('card')}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              alignSelf: "stretch",
              justifyContent: "space-between",
              margin: theme.paddingSize,
            }}
          >
            <span>{intl.formatMessage(reportsMessages.columnsPicker.saveSettings)}</span>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  whiteSpace: "nowrap",
                }}
              >
                <span style={{ marginLeft: theme.padding }}>
                  {intl.formatMessage(reportsMessages.columnsPicker.reportTitle)}
                </span>
                <InputField
                  type={"String"}
                  key={"reportName"}
                  value={reportLabel}
                  onChange={(val) => {
                    let newSelectedfilterSet = Object.assign(
                      {},
                      selectedFilterSet,
                      { label: val }
                    );
                    updateSelectedFilterSet(newSelectedfilterSet);
                  }}
                />
              </div>

              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  whiteSpace: "nowrap",
                }}
              >
                <span style={{ marginLeft: theme.padding }}>
                  {intl.formatMessage(reportsMessages.columnsPicker.ordinalNumber)}
                </span>
                <InputField
                  style={{ width: "100%" }}
                  type={"Number"}
                  key={"reportNum"}
                  value={(selectedFilterSet || {}).num}
                  onChange={(val) => {
                    let newSelectedfilterSet = Object.assign(
                      {},
                      selectedFilterSet,
                      { num: val }
                    );
                    updateSelectedFilterSet(newSelectedfilterSet);
                  }}
                />
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                disabled={!(selectedFilterSet || {}).label}
                onClick={() => handleSaveFilter()}
                style={{
                  height: 30,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  background: "lightgray",
                  marginRight: 10,
                  marginLeft: 10,
                  cursor: "pointer",
                  padding: 5,
                  borderRadius: 5,
                }}
              >
                <span>{intl.formatMessage(buttonsMessages.saveAsNew)}</span>
              </div>

              {Boolean(selectedFilterSet) && (
                <div
                  disabled={!(selectedFilterSet || {}).label}
                  onClick={() => handleSaveFilter(selectedFilterSet)}
                  style={{
                    height: 30,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    marginLeft: 10,
                    background: "lightgray",
                    cursor: "pointer",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <span>{intl.formatMessage(buttonsMessages.update)}</span>
                </div>
              )}

              {Boolean(selectedFilterSet) && (
                <div
                  disabled={!(selectedFilterSet || {}).label}
                  onClick={() => handleSaveFilter(selectedFilterSet, true)}
                  style={{
                    height: 30,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    marginLeft: 10,
                    background: "lightgray",
                    cursor: "pointer",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <span>{intl.formatMessage(buttonsMessages.delete)}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        )}
      </Modal>
    </div>
  );
}

export default injectIntl(TableFilters);
