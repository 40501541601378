import { useCallback, useContext, useMemo, useState } from 'react';
import { GridContainer, GridItem } from '../../../../web/components';
import theme from '../../../../web/assets/css/theme';
import VirtualList from '../../../../web/components/List/react-tiny-virtual-list.es';
import collapse from '../../../../web/assets/img/icons/collapse.png';
import radioEmpty from '../../../../web/assets/img/icons/radio-empty.png';
import radioFull from '../../../../web/assets/img/icons/radio-full.png';
import { useSelector } from 'react-redux';
import Text from '../../../../web/components/CementoComponents/Text';
import { CountBar } from '../../../../web/components/CementoComponents/CountBar';
import _ from 'lodash';
import { useRef } from 'react';
import { ObjectsWrapperDataContext, ObjectsWrapperManipulationContext } from '../../ObjectsWrapper';
import useGroupBy from '../../hooks/useGroupBy';

const ObjectsWrapperGridView = ({ ItemComponent }) => {
  const rtl = useSelector((state) => state.app.rtl);
  const { data } = useContext(ObjectsWrapperDataContext);
  const { groupBy } = useContext(ObjectsWrapperManipulationContext);

  const [expandedGroups, setExpandedGroups] = useState({});
  const scrollRef = useRef(null);

  const toggleGroupExpansion = useCallback(
    (groupId) => {
      setExpandedGroups((prev) => ({ ...prev, [groupId]: !prev[groupId] }));
    },
    [setExpandedGroups]
  );

  const handleExportGroupsSelect = useCallback((groupId, e) => {
    //
  }, []);

  const groups = useGroupBy(data, [groupBy[0]]);

  const dataKeys = useMemo(() => Object.keys(groups).sort(), [groups]);

  const cardsPerRow = 2;
  const exportSelectionMode = false;
  const { rowsToRender, maxGroupCount } = useMemo(
    () =>
      dataKeys.reduce(
        (acc, groupKey) => {
          const group = groups[groupKey];
          const isExpanded = expandedGroups[groupKey];
          const generate = (_group, _acc) => {
            if (_group.groups) return Object.values(_group.groups).forEach((group) => generate(group, _acc));
            const currentGroupCount = _group?.rows?.length;
            if (currentGroupCount > acc.maxGroupCount) {
              acc.maxGroupCount = currentGroupCount;
            }
            acc.rowsToRender.push({
              type: 'header',
              groupKey,
              group: _group,
              isExpanded,
            });
            if (isExpanded) {
              _.chunk(_group?.rows, cardsPerRow).forEach((chunk) => {
                acc.rowsToRender.push({
                  type: 'data',
                  groupKey,
                  group: _group,
                  values: chunk,
                  isExpanded,
                });
              });
            }
            return _acc;
          };

          generate(group, acc);

          return acc;
        },
        { rowsToRender: [], maxGroupCount: 0 }
      ),
    [groups, dataKeys, cardsPerRow, expandedGroups]
  );

  const visibleRows = rowsToRender.filter((row) => row.type === 'header' || row.isExpanded);

  return (
    <div ref={scrollRef} className='ObjectsWrapperGridView_root height-without-header'>
      <VirtualList
        width={'100%'}
        height={'auto'}
        itemCount={visibleRows.length}
        itemSize={(index) => {
          const { type, isExpanded } = visibleRows[index];
          if (type === 'data') {
            return isExpanded ? 200 : 0;
          }
          return 50;
        }}
        overscanCount={20}
        parentref={scrollRef.current}
        scrollToAlignment={'start'}
        // scrollparentreftoindex={0}
        stickyIndices={[] /*groupsHeadersIndices*/}
        renderItem={({ index, style }) => {
          const { type, groupKey, group, values = [], isExpanded } = visibleRows[index];
          return (
            <GridContainer key={index} style={style}>
              {type === 'data' ? (
                isExpanded ? (
                  <GridItem xs={12} style={{ paddingLeft: 36, paddingRight: 36 }}>
                    <GridContainer spacing={3}>
                      {values.map((item, i) => (
                        <GridItem key={i + '_' + item.id || i} xs={12 / cardsPerRow}>
                          <ItemComponent data={item} />
                        </GridItem>
                      ))}
                    </GridContainer>
                  </GridItem>
                ) : null
              ) : (
                <GridItem
                  key={index + 'group'}
                  id='rowParent'
                  xs={12}
                  style={{
                    cursor: 'pointer',
                    paddingTop: theme.paddingSize,
                    borderBottom: isExpanded ? 'none' : theme.borderLineNeutralLight + '40',
                  }}
                  onClick={() => toggleGroupExpansion(groupKey)}>
                  <div
                    className='row'
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingLeft: 36,
                      paddingRight: 36,
                    }}>
                    <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                      {Boolean(exportSelectionMode) && (
                        <img
                          src={isExpanded ? radioFull : radioEmpty}
                          style={{
                            height: 16,
                            width: 16,
                            [rtl ? 'marginLeft' : 'marginRight']: 10,
                          }}
                          onClick={(e) => handleExportGroupsSelect(groupKey, e)}
                        />
                      )}
                      <img
                        src={collapse}
                        style={{
                          transform: isExpanded ? 'rotate(90deg)' : rtl ? 'rotate(180deg)' : '',
                          height: 10,
                          transition: '0.2s',
                          [rtl ? 'marginLeft' : 'marginRight']: 10,
                        }}
                      />
                      <Text values={{ count: group?.rows?.length }} style={{ fontWeight: 500, fontSize: 16 }}>
                        {group.title}
                      </Text>
                    </div>
                    <CountBar value={group?.rows?.length} totalValue={maxGroupCount} />
                  </div>
                </GridItem>
              )}
            </GridContainer>
          );
        }}
      />
      <style jsx>{`
        #rowParent:hover > .row {
          color: ${theme.brandPrimary};
          transition: color 0.1s ease;
        }
      `}</style>
    </div>
  );
};

export default ObjectsWrapperGridView;
