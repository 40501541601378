import 'react-contexify/dist/ReactContexify.css';

import { useCallback, useContext } from 'react';
import { Menu, Item, useContextMenu } from 'react-contexify';
import { ObjectsWrapperManipulationContext } from '../../../../ObjectsWrapper';
import useIntl from '../../../../../intl/useIntl';

function Filter({ columnId, hideColumn, className = '' }) {
  const intl = useIntl();
  const { setSortBy, setGroupBy } = useContext(ObjectsWrapperManipulationContext);
  const MENU_ID = `table-filter-menu-${columnId}`;

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function handleContextMenu(event) {
    show({
      event,
    });
  }

  const handleItemClick = useCallback(
    ({ id }) => {
      switch (id) {
        case 'sort_asc':
          setSortBy({ column: columnId, direction: 'asc' });
          break;
        case 'sort_desc':
          setSortBy({ column: columnId, direction: 'desc' });
          break;
        case 'group_by_primary':
          setGroupBy(([, secondary]) => [columnId, secondary]);
          break;
        case 'group_by_secondary':
          setGroupBy(([primary]) => [primary, columnId]);
          break;
        case 'hide_column':
          hideColumn();
          break;
      }
    },
    [columnId, setSortBy, setGroupBy]
  );

  return (
    <div className={`OW-HeaderContext cursor-pointer ${className}`}>
      <p onClick={handleContextMenu}>...</p>
      <Menu id={MENU_ID} className='contextMenu'>
        <Item id='sort_asc' onClick={handleItemClick}>
          {intl.formatMessage({ id: 'objectsWrapper.filter.sortAsc' })}
        </Item>
        <Item id='sort_desc' onClick={handleItemClick}>
          {intl.formatMessage({ id: 'objectsWrapper.filter.sortDesc' })}
        </Item>
        <Item id='group_by_primary' onClick={handleItemClick}>
          {intl.formatMessage({ id: 'objectsWrapper.filter.groupByPrimary' })}
        </Item>
        <Item id='group_by_secondary' onClick={handleItemClick}>
          {intl.formatMessage({ id: 'objectsWrapper.filter.groupBySecondary' })}
        </Item>
        <Item id='hide_column' onClick={handleItemClick}>
          {intl.formatMessage({ id: 'objectsWrapper.filter.hideColumn' })}
        </Item>
      </Menu>
    </div>
  );
}

export default Filter;
