import { Navigate } from 'react-router-dom';
import Dashboard from '../../../../views/Dashboard/Dashboard';
import PropertyAnalytics from '../../../../views/Properties/PropertyAnalytics';
import LocationContainerPage from '../../../../views/Locations/LocationContainerPage';
import ObjectsWrapper from '../../../../../common/ObjectWrapper/ObjectsWrapper';
import ObjectsWrapperRemoteConfig from '../../../../../common/ObjectWrapper/helpers/ObjectWrapperRemoteConfig';

export default {
  path: 'safety',
  children: [
    {
      index: true,
      element: <Navigate to={'dashboard'} />,
    },
    {
      path: 'dashboard',
      element: <Dashboard contentType={'safety'} />,
    },
    {
      path: 'multiProjectOverview',
      element: <Dashboard contentType={'safety'} multiProjectViewMode={true} />,
    },
    {
      path: 'analytics/:reportId?',
      element: (
        <ObjectsWrapperRemoteConfig
          key={'project_ObjectsWrapper_safety_analytics'}
          contentType={'safety'}
          section={'analytics'}
        />
      ),
    },
    {
      path: 'objects/:reportId?',
      element: (
        <ObjectsWrapper key={'project_ObjectsWrapper_safety_analytics'} contentType={'safety'} section={'analytics'} />
      ),
    },
    {
      path: 'postsAnalytics/:reportId?',
      element: (
        <ObjectsWrapperRemoteConfig
          key={'project_PropertyAnalytics_safety_postsAnalytics'}
          customSubMenuType={'postsAnalytics'}
          contentType={'safety'}
          section={'postsAnalytics'}
        />
      ),
    },
    {
      path: 'locationContainerPage',
      element: <LocationContainerPage />,
      children: [
        {
          path: ':buildingId/:floorId/:unitId',
          element: <PropertyAnalytics key={'project_PropertyAnalytics_location_safety'} contentType={'safety'} />,
        },
      ],
    },
  ],
};
