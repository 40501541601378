import { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { flexRender } from '@tanstack/react-table';
import { useSortable } from '@dnd-kit/sortable';
import Filter from './Filter';
import { getCommonCellStyles } from '../util';
import { useSelector } from 'react-redux';
import { ObjectsWrapperManipulationContext } from '../../../../ObjectsWrapper';

const DraggableTableHeader = ({ table, header, groupTitle, headerGroupIndex, className }) => {
  const rtl = useSelector((state) => state.app.rtl);
  const { setColumnDefinitions } = useContext(ObjectsWrapperManipulationContext);
  const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
    id: header.column.id,
  });

  const hasChildren = header.column.columnDef.cementoChildren?.length > 0;

  const toggleChildrenHeaders = useCallback(() => {
    if (!hasChildren) return;
    const children = header.column.columnDef.cementoChildren;
    setColumnDefinitions((state) => {
      children.forEach((childId) => {
        const column = state.find((c) => c.key === childId);
        column.isVisible = !column.isExpanded;
      });
      return [...state];
    });
  }, [hasChildren]);

  const hideColumn = useCallback(() => {
    setColumnDefinitions((state) => {
      const column = state.find((c) => c.key === header.column.id);
      column.isVisible = false;
      return [...state];
    });
  }, []);

  const style = useMemo(
    () => getCommonCellStyles({ header, column: header.column, isDragging, transform }),
    [header, isDragging, transform]
  );

  return (
    <th
      key={header.id}
      colSpan={header.colSpan}
      ref={setNodeRef}
      style={style}
      className={`DraggableTableHeader ${className}`}>
      <main>
        <header
          style={{ width: style.width }}
          className={classNames('DraggableTableHeader_header', 'DraggableTableHeader_container__group-header', {
            'DraggableTableHeader_header_empty': !groupTitle?.length,
          })}>
          <span style={{ width: style.width }} className='DraggableTableHeader_text'>
            {groupTitle}
          </span>
        </header>
        <div
          className={classNames('DraggableTableHeader_container', {
            'DraggableTableHeader_container__group-header': header.depth === 1,
            'DraggableTableHeader_container__child-header': header.depth === 2,
          })}
          style={{
            height: '75px',
          }}>
          <div
            className='DraggableTableHeader_border'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: style.width,
            }}>
            <span className='DraggableTableHeader_text'>
              {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
            </span>
            <div
              className='DraggableTableHeader_drag-handle cursor-grab'
              {...attributes}
              {...listeners}
              style={{
                padding: '1rem',
                position: 'absolute',
                [rtl ? 'left' : 'right']: '0',
              }}>
              🟰
            </div>
            {hasChildren ? (
              <div onClick={toggleChildrenHeaders}>
                <span style={{ cursor: 'pointer' }}> ↔️ </span>
              </div>
            ) : null}
          </div>
          {headerGroupIndex === 1 ? (
            <Filter columnId={header.column.id} hideColumn={hideColumn} className='DraggableTableHeader_Filter' />
          ) : null}
          <div
            onDoubleClick={() => header.column.resetSize()}
            onMouseDown={header.getResizeHandler()}
            onTouchStart={header.getResizeHandler()}
            className={`resizer ${table.options.columnResizeDirection} ${
              header.column.getIsResizing() ? 'isResizing' : ''
            }`}
          />
        </div>
      </main>
    </th>
  );
};

export default DraggableTableHeader;
