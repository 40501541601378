import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { defineCardType, getRelevantLocalInstancesByPropId } from '../../lib/utils/utils';
import { useCementoRouter } from '../../../web/components/Router/util/withRouterHOC';
import { isIssuePage } from '../../../web/views/Posts/utils';
import { getNewId } from '../../lib/api';
import systemMessages from '../../app/systemMessages';
import { hideLoading, startLoading, startToast } from '../../app/actions';
import { validatePropType } from '../../propertiesTypes/funcs';
import { uploadPropertiesInstances } from '../../propertiesInstances/actions';
import useIntl from '../../intl/useIntl';

const initialState = {
  object: null,
  formType: 'dailyReport',
  formTemplateId: '-dailyReportForm',
};

const useSelectedObject = ({ subjectType, contentType, propertiesInstances, columnDefinitions, filters, objects }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { location } = useCementoRouter();
  const [_selectedObject, _setSelectedObject] = useState(() => initialState);

  const propertiesInstancesMap = useMemo(() => _.keyBy(propertiesInstances, 'id'), [propertiesInstances]);
  const allObjects = useMemo(() => _.keyBy(objects, 'id'), [objects]);

  // Methods
  const handleObjectCreate = (initialGroupId, newObjectId, isFiredByUser) => {
    if (newObjectId) {
      // show card of newly created object
      return setSelectedObject({ objectId: newObjectId, editMode: false, objectType: contentType });
    }

    let id = getNewId();

    return setSelectedObject({
      objectId: id,
      editMode: true,
      objectType: contentType,
      options: {
        isCreation: true,
        isFiredByUser,
        initialGroupId,
      },
    });
  };

  const hasModifications = true; // TODO

  const handleSave = () => {
    const { title, content, yes, no } = systemMessages.confirmSaveChangesAlert;

    if (hasModifications) {
      return dispatch(
        startToast({
          overlay: true,
          mandatory: true,
          title: title,
          message: content,
          actions: [{ message: yes, onClick: handleConfirmSave, color: 'success' }, { message: no }],
        })
      );
    }

    return handleConfirmSave();
  };

  const handleConfirmSave = () => {
    if (hasModifications) {
      // TODO: change for support of employees and equipment
      let propertiesHaveValidTypes = true;
      propertiesInstances.forEach((prop) => {
        if (!propertiesHaveValidTypes) return;
        if (!validatePropType(prop.propType)) propertiesHaveValidTypes = false;
      });

      if (!propertiesHaveValidTypes) {
        dispatch(startToast({ title: systemMessages.errorOnSave, type: 'error' }));
        return;
      }

      const subjectName = subjectType + 'Info';
      const callback = (success) => {
        dispatch(hideLoading());
        if (!success) {
          dispatch(startToast({ title: systemMessages.errorOnSave, type: 'error' }));
          return;
        }

        dispatch(
          startToast({
            title: systemMessages.objectSavedSuccessfully,
            type: 'success',
            values: { objectName: intl.formatMessage(systemMessages.properties) },
          })
        );
      };
      dispatch(startLoading({ title: systemMessages.loadingMessage, overlay: true }));

      return;
      setTimeout(() => {
        dispatch(uploadPropertiesInstances(selectedProjectId, updatedPropertiesArr, subjectName, callback));
      }, 1);
    }
  };

  // Base

  const setSelectedObject = ({ objectId, editMode, objectType, options }) => {
    _setSelectedObject((selectedObject) => ({
      ...selectedObject,
      object: allObjects[objectId] || { id: objectId },
      editMode,
      objectType,
      options,
    }));
  };

  const resetSelectedObject = () => _setSelectedObject(initialState);

  const selectedObject = useMemo(() => {
    if (!_selectedObject.object?.id && !_selectedObject?.options?.editMode) return null;
    const baseProps = calculateSelectedObject({
      object: _selectedObject.object,
      options: _selectedObject.options,
      objectType: _selectedObject.objectType,
      editMode: _selectedObject.editMode,
      formType: _selectedObject.formType,
      formTemplateId: _selectedObject.formTemplateId,
      subjectType,
      filters,
      contentType,
      columnDefinitions,
      propertiesInstancesMap,
      itemType: new URLSearchParams(location?.search || '').get('itemType'),
    });

    const methods = {
      onObjectCreate: (initialGroupId, newObjectId) => handleObjectCreate(initialGroupId, newObjectId, false),
      // onCancel: resetSelectedObject,
      onSave: !_selectedObject.options?.isCreation ? handleSave : undefined,
      onLocalInstancesChange: onLocalInstancesChange,
      onDisableChange: handleCardChangeStatus,
      onMemberUpdate: onMemberUpdate,
      onCompanyUpdate: onCompanyUpdate,
      onGroupUpdate: onGroupUpdate,
      disableEditMode: handleConfirmCancel,
      onDeleteObject: !_selectedObject.options?.isCreation ? resetSelectedObject : undefined,
      setHasUnSavedChanges: setHasUnSavedChanges,
    };

    return {
      id: _selectedObject.object?.id,
      type: defineCardType(subjectType),
      props: {
        ...baseProps,
        ...methods,
      },
    };
  }, [_selectedObject.object?.id]);
 
  return {
    selectedObject,
    setSelectedObject,
    resetSelectedObject,
    createNewObject: () => handleObjectCreate(null, null, true),
  };
};

const calculateSelectedObject = ({
  object,
  contentType,
  columnDefinitions,
  formType,
  formTemplateId,
  subjectType,
  filters,
  objectType,
  editMode,
  propertiesInstancesMap,
  itemType,
  options,
}) => {
  if (!object) return null;
  const { id: objectId } = object;

  const primaryColumn = columnDefinitions.find((col) => col.isPrimary);

  const {
    scrollTargetSectionId,
    objectExtraInfo,
    tableMethods,
    isCreation,
    // isFiredByUser = false,
    // callback,
    initialGroupId,
    employeesPresence,
    hideCertifications,
  } = options || {};

  let subjectName = subjectType + 'Info';
  let filteredProperties = null;
  const columnVisibility = _.get(filters, 'columnVisibility');
  if (columnVisibility) {
    filteredProperties = {};
    _.entries(columnVisibility).forEach(([propertyId, propertyShowOptions]) => {
      if (propertyShowOptions.card) filteredProperties[propertyId] = true;
    });
  }

  const targetObject = getTargetObject({
    object,
    subjectType,
    options,
    contentType,
    itemType,
  });

  const selectedObjectProps = {
    objectId,
    tableMethods: tableMethods ? tableMethods : undefined,
    subjectName,
    subjectType,
    objectExtraInfo: Object.assign({ formType, formTemplateId }, objectExtraInfo),
    initialGroupId,
    scrollTargetSectionId,
    primaryPropId: primaryColumn ? primaryColumn.id : null,
    filteredProperties,
    localInstancesByPropertyMap: getRelevantLocalInstancesByPropId(propertiesInstancesMap, objectId),
    dataWasImported: Boolean(Object.keys(propertiesInstancesMap).length),
    objectType, // used only by UsersManagerCard
    employeesPresence,
    hideCertifications,
    isDisabled: isCreation ? false : !editMode,
    createObjectMode: isCreation ? subjectType : undefined,
    creation: Boolean(isCreation),
    targetObject,
  };

  return selectedObjectProps;
};

const onLocalInstancesChange = () => {
  // Update local data object, should not be needed
  console.log('onLocalInstancesChange');
};

const handleCardChangeStatus = () => {
  // Sets if card is disabled
  console.log('handleCardChangeStatus');
};

const onMemberUpdate = () => {
  // Not used for objects now, so should not be needed
  console.log('onMemberUpdate');
};

const onCompanyUpdate = () => {
  // Not used for objects now, so should not be needed
  console.log('onCompanyUpdate');
};

const onGroupUpdate = () => {
  // Not used for objects now, so should not be needed
  console.log('onGroupUpdate');
};

const handleConfirmCancel = () => {
  // Set edit mode to false
  console.log('handleConfirmCancel');
};

const handleObjectDelete = () => {
  // Delete from local instaces using onLocalInstancesChange, should not be needed
  console.log('handleObjectDelete');
};

const setHasUnSavedChanges = () => {
  // Set hasUnSavedChanges state to manage the warning message
  console.log('setHasUnSavedChanges');
};

const getTargetObject = ({ object, subjectType, options, contentType, itemType }) => {
  if (options?.isCreation && subjectType === 'posts') {
    return {
      isIssue: isIssuePage(contentType, itemType),
      mode: 'draft',
    };
  }
  if (object) {
    return Object.assign({}, object, {
      instances: object.instances || object.props,
    });
  }
  return {};
};

export default useSelectedObject;
