import _ from 'lodash';
import {isEmptyValue, safeJSONParse} from '../../common/app/funcs';
import { DEFAULT_PERMISSIONS_PATH_DELIMETER } from '../../common/permissions/funcs';

/**
 *  
 * @param {any} val 
 * @param {string} urlKey 
 * @param {string} originalSearch 
 */
export const encodeValueToSearch = (urlKey, val, originalSearch = location.search) => {
  const searchParams = new URLSearchParams(originalSearch);

  if (isEmptyValue(val)) {
    searchParams.delete(urlKey);
  } else {
    searchParams.set(urlKey, JSON.stringify(val));
  }
  
  return searchParams.toString();
}

/**
 * 
 * @param {string} urlKey 
 * @param {string} search 
 */

export const decodeValueFromSearch = (urlKey, search = location.search) => {
  const searchParams = new URLSearchParams(search);
  const val = searchParams.get(urlKey);
  return safeJSONParse(val, true);
}

const COMMA_SEPARATOR = '--c--';
const AND_REPLACER = '--a--';
const PLUS_REPLACER = '--p--';

const encodeArray = (arr) => {
  let arrString = arr.length === 1 ? `${arr[0]}${COMMA_SEPARATOR}` : arr.join(COMMA_SEPARATOR); // Add coma separator to keep array format as string
  return arrString
    .replaceAll('&', AND_REPLACER)
    .replaceAll('+', PLUS_REPLACER);
};

const decodeArray = (str) => {
  return str
    .replaceAll(AND_REPLACER, '&')
    .replaceAll(PLUS_REPLACER, '+')
    .split(COMMA_SEPARATOR)
    .filter(Boolean); // If an array has only one value we added the coma serpartor at the end so we need to filter it out
};

export const encodeFilterToSearch = (filterObject, originalSearch, filterUrlKey = 'filter', paramsToIgnore = []) => {
  const filterObjectSearch = new URLSearchParams();
  Object.entries(filterObject || {}).forEach(([key, val]) => {
    filterObjectSearch.set(key, _.isArray(val) ? encodeArray(val) : JSON.stringify(val));
  });
  
  // Remove duplicates
  let newSearch = new URLSearchParams(Object.fromEntries(new URLSearchParams(originalSearch)));

  if(paramsToIgnore.length > 0){
    paramsToIgnore.forEach((str) => newSearch.delete(str));
  }

  const filterString = filterObjectSearch.toString();
  
  if (filterString)
    newSearch.set(filterUrlKey, filterString);
  else
    newSearch.delete(filterUrlKey);

  return newSearch.toString();
}

export const decodeFiltersFromSearch = (locationSearch, filterUrlKey = 'filter', pathDelimeter = DEFAULT_PERMISSIONS_PATH_DELIMETER) => {
  const urlSearchParamsObj = new URLSearchParams(locationSearch);
  const filterParams = urlSearchParamsObj.get(filterUrlKey) || '';
  const filterParamsObj = new URLSearchParams(filterParams);

  let originalFilterObj = {};
  let filterQuery = {};
  filterParamsObj.forEach((paramVal, paramKey) => {
    const objKey = paramKey.indexOf('TARGET') !== -1 ? 'target' : `target${pathDelimeter}${paramKey}`;
    const parsedVal = safeJSONParse(paramVal);

    if (typeof parsedVal !== 'boolean' && parsedVal && !_.isFinite(Number(paramVal))) {
      filterQuery[objKey] = parsedVal;
      originalFilterObj[paramKey] = parsedVal;
    } else {
      const parsedVal = decodeArray(paramVal) || null;
      filterQuery[objKey] = parsedVal;
      originalFilterObj[paramKey] = parsedVal;
    }
  });

  return {
    cementoQuery: Object.keys(filterQuery).length ? [filterQuery] : null,
    originalFilterObj,
  };
}