import { useMemo } from 'react';
import { getCementoStringValue } from '../utils';

const useGroupBy = (objects, groupBy) => {
  const groupedData = useMemo(() => {
    if (!groupBy?.length || !objects?.length) {
      return { all: { rows: objects } };
    }

    return objects.reduce((acc, obj) => {
      let currentGroup = acc;

      groupBy.forEach((group, index) => {
        const value = obj[group];
        const groupId = value?.displayName ?? value?.id;
        const cementoTitle = getCementoStringValue(value);
        const groupTitle = cementoTitle?.length ? cementoTitle : '-';
        const isLastGroup = index === groupBy.length - 1;

        if (!currentGroup[groupTitle]) {
          let currGroup = {
            id: groupId,
            index,
            title: groupTitle,
            groupName: group,
          };
          currentGroup[groupTitle] = Object.assign(currGroup, isLastGroup ? { rows: [] } : { groups: {} });
        }

        currentGroup = isLastGroup ? currentGroup[groupTitle] : currentGroup[groupTitle].groups;
      });
      currentGroup.rows = [...(currentGroup.rows ?? []), obj];

      return acc;
    }, {});
  }, [objects, groupBy]);

  return groupedData;
};

export default useGroupBy;
