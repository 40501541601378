import theme from "../app/theme";
import { convertTextLinks, deepEqual } from "../app/funcs";
import _ from "lodash";
import systemMessages from "../app/systemMessages";
import { platformActions } from "../platformActions";
import { useEffect, useState } from "react";
import usePrevious from "./usePrevious";

const addPrefixSuffixCase = (textString, uppercase, prefix, suffix) => {
    let newString = textString || '';
    if (typeof newString === 'string') {
        if (uppercase && newString.toUpperCase)
            newString = newString.toUpperCase();

        if (typeof prefix === 'string')
            newString = prefix + newString;

        if (typeof suffix === 'string')
            newString += suffix;
    }
    return newString;
};

const truncateTextNative = (textString, maxChars) => {
    if (maxChars && Number.isInteger(maxChars) && textString.length > maxChars) {
        return textString.slice(0, maxChars - 3) + '...';
    }
    return textString
};

const useText = (props) => {
    const {
        maxChars,
        textString = '',
        intl,
        shouldSplitLines,
        linksMode,
        linksPlaceholder,
        linksTransformer,
        maxLines,
        prefix,
        suffix,
        uppercase,
        children,
        values,
        style,
    } = props;

    const [finalProcessedStringArray, setFinalProcessedStringArray] = useState([]);
    const prevProps = usePrevious(props);

    useEffect(() => {
        if (!platformActions?.app?.getPlatform) return finalProcessedStringArray;
        const didChange = didRelevantPropsChange(prevProps, props);
        if (didChange) {
            const nextFinalProcessedStringArray = processStringArray({
                children,
                values,
                intl,
                uppercase,
                prefix,
                suffix,
                shouldSplitLines,
                linksMode,
                maxLines,
                maxChars,
                style,
                textString,
                linksPlaceholder,
                linksTransformer,
                finalProcessedStringArray
            });

            if (!deepEqual(finalProcessedStringArray, nextFinalProcessedStringArray)) {
                setFinalProcessedStringArray(nextFinalProcessedStringArray);
            }
        }

    }, [
        maxChars,
        textString,
        children,
        values,
        shouldSplitLines,
        linksMode,
        linksPlaceholder,
        linksTransformer,
        maxLines,
        style,
        prefix,
        suffix,
        uppercase,
    ]);

    return { finalProcessedStringArray };
};

export default useText;

const webPlatformTextProcess = ({
    inner,
    uppercase,
    prefix,
    suffix,
    shouldSplitLines,
    linksMode,
    intl,
    maxLines,
    maxChars,
    style,
}) => {
    let finalProcessedStringArray = [];
    if (typeof inner == 'string') {
        inner = addPrefixSuffixCase(inner, uppercase, prefix, suffix);
    }

    if (typeof inner == 'string' && inner.indexOf('\n') != -1) {
        inner = inner.split('\n');
        if (!shouldSplitLines) {
            inner = [inner.join(' ')];
        }
    }
    else {
        inner = [inner];
    }

    inner.map((curr) => {
        if (_.isNil(curr))
            return null;
        let ret = curr;
        let linksPlaceholder = '';
        let innerStyle = style;
        if (linksMode === 'convert') {
            linksPlaceholder = intl.formatMessage(systemMessages.clickHere);
        }

        if (linksMode) {
            ret = convertTextLinks(curr, linksPlaceholder);
        }
        if (typeof ret === 'string' && maxLines && !innerStyle) {
            let maxCH = maxChars || 75;
            ret = ((ret || '').length < maxCH) ? ret : ret.slice(0, maxCH) + '...';
            
            const fontSize = style?.fontSize || theme.fontSize;
            const lineHeight = maxLines ? (style ? (style.lineHeight || style.fontSize || 1.4) : 1.4) : null;
            const height = maxLines ? (fontSize * lineHeight * maxLines) : null;
            innerStyle = {
                display: style?.display || '-webkit-inline-box',
                height,
                fontSize,
                lineHeight: lineHeight ? String(lineHeight) : null,
                '-webkit-line-clamp': String(maxLines),
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            };
        }
    

        finalProcessedStringArray.push({ text: ret, style: innerStyle });
    });
    return finalProcessedStringArray;
};

const nativePlatformTextProcess = ({
    inner,
    textString,
    uppercase,
    prefix,
    suffix,
    maxChars,
    linksMode,
    linksPlaceholder,
    linksTransformer
}) => {
    let finalProcessedStringArray = [];

    if (typeof inner == 'string' || typeof inner == 'number') {
        textString = inner;
    }

    if (inner && typeof inner == 'object' && !inner?.defaultMessage) {

        finalProcessedStringArray.push(inner);
    } else {
        if (typeof textString == 'string') {
            textString = addPrefixSuffixCase(textString, uppercase, prefix, suffix);
        }

        if (maxChars) {
            textString = truncateTextNative(textString, maxChars);
        }

        if (linksMode) {
            textString = convertTextLinks(textString, linksPlaceholder, linksTransformer);
            finalProcessedStringArray = convertTextLinks(textString, linksPlaceholder, linksTransformer);
        } else {
            finalProcessedStringArray.push(textString);
        }
    }
    return finalProcessedStringArray;
};

const processStringArray = ({
    children,
    values,
    intl,
    uppercase,
    prefix,
    suffix,
    shouldSplitLines,
    linksMode,
    maxLines,
    maxChars,
    style,
    textString,
    linksPlaceholder,
    linksTransformer,
}) => {

    let inner = children;
    if (children && typeof children === 'object' && children.defaultMessage) {
        let vals = undefined;
        if (values) {
            vals = {};
            values.loopEach((k, v) => vals[k] = (typeof v === 'object' && v.defaultMessage) ? intl.formatMessage(v) : v);
        }
        inner = intl.formatMessage(children, vals);
    }

    const textProcessParams = {
        inner,
        uppercase,
        prefix,
        suffix,
        shouldSplitLines,
        linksMode,
        intl,
        maxLines,
        maxChars,
        style,
        linksPlaceholder,
        linksTransformer,
        textString
    };

    return (platformActions.app.getPlatform() === 'web')
        ? webPlatformTextProcess(textProcessParams)
        : nativePlatformTextProcess(textProcessParams);
};


const didRelevantPropsChange = (prevProps, props) => {
    if (!prevProps && props) return true;

    let didChange = false;
    const paths = [
        'maxChars',
        'textString',
        'shouldSplitLines',
        'linksMode',
        'linksPlaceholder',
        'linksTransformer',
        'maxLines',
        'prefix',
        'suffix',
        'uppercase',
        'children',
        'values',
        'style',
    ];

    didChange = paths.some(path => {
        if (_.isFunction(props?.[path])) {
            return JSON.stringify(props?.[path]) != JSON.stringify(prevProps?.[path]);
        } else if (path == 'children') {
            if ( props?.children?.defaultMessage && props?.children?.id){
                return props?.children?.id != prevProps?.children?.id;
            } else {
                return props?.children != prevProps?.children;
            }
        } else if (path !== 'children' && !deepEqual(_.get(prevProps, path), _.get(props, path))) {
            return true;
        }
    });

    return didChange;
};
