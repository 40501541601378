import React, { useEffect, useState } from 'react';
import Modal from '../../../../web/components/CementoComponents/Modal';
import theme from '../../../app/theme';
import StandardInput from '../../../../web/components/CementoComponents/StandardInput';
import Button from '../../../../web/app/standardComponents/Button';

/**
 * 
 * @param {{
 *  open?: boolean,
 *  onDone?: (menuItem?: { id?: string, name: string, ordinalNo: number }, isDelete?: boolean) => void,
 *  onClose?: Function,
 *  menuId: string,
 *  menuName?: string,
 *  menuOrdinalNo?: number,
 * }} props 
 * @returns 
 */
const CreateMenuModal = (props) => {
  const { open, onDone, onClose, menuId, menuName, menuOrdinalNo } = props;
  const [name, setName] = useState(menuName);
  const [ordinalNo, setOrdinalNo] = useState(menuOrdinalNo);

  useEffect(() => {
    setName(menuName);
  }, [menuName]);

  useEffect(() => {
    setOrdinalNo(menuOrdinalNo);
  }, [menuOrdinalNo]);

  return (
    <Modal open={open} onClose={onClose}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: theme.verticalMargin * 10, padding: theme.padding * 4 }}>
        <div style={{ fontSize: theme.fontSizeH5, color: theme.brandPrimary, fontWeight: theme.bold }}>
          Create / Edit Menu
        </div>
        <div style={{ display: 'flex', gap: theme.biggerPadding * 5 }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <StandardInput
              title={'Menu name'}
              type='String'
              value={name}
              onChange={setName}
            />
            <StandardInput
              title={'Ordinal number'}
              type='Number'
              value={ordinalNo || 0}
              onChange={setOrdinalNo}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: theme.horizontalMargin }}>
            <Button title={'Create new'} withPlus onClick={() => onDone({ name, ordinalNo })} style={{ width: '100%' }} />
            <Button title={'Update'} onClick={() => onDone({ id: menuId, name, ordinalNo })} primaryColor={theme.brandSuccess} style={{ width: '100%' }} />
            <Button title={'Delete'} onClick={() => onDone({ id: menuId, name, ordinalNo }, true)} primaryColor={theme.brandDanger} style={{ width: '100%' }} />
            <Button title={'Cancel'} onClick={() => onClose()} shouldInvertColors style={{ width: '100%' }} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CreateMenuModal;