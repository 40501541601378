import iconResolve from '../../../../../../../web/assets/img/icons/CheckBrandPrimary.png';
import iconRejected from '../../../../../../../web/assets/img/icons/CheckFalse.png';

const ICON_SIZE = 16;

const CheckboxCell = ({ cellValue, cellContent, isSummaryCell }) => {
  if (isSummaryCell) return cellContent;
  const displayValue = cellValue?.cementoValue?.value;
  return typeof displayValue === 'boolean' ? (
    <img src={displayValue ? iconResolve : iconRejected} style={{ height: ICON_SIZE, width: ICON_SIZE }} />
  ) : null;
};

export default CheckboxCell;
