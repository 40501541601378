import { useSelector } from 'react-redux';
import theme from '../../../../../../../web/assets/css/theme';
import attachment from '../../../../../../../web/assets/img/icons/attachment.png';
import Text from '../../../../../../../web/components/CementoComponents/Text';
import systemMessages from '../../../../../../app/systemMessages';

const AttachmentsCell = ({ cellValue, cellContent, isSummaryCell }) => {
  const rtl = useSelector((state) => state.app.rtl);
  if (isSummaryCell) return cellContent;

  const attachments = cellValue?.cementoValue?.value;
  const filesCounter = attachments?.length || (attachments?.uri ? 1 : 0);

  if (!filesCounter) return null;

  return (
    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img
        src={attachment}
        style={{
          color: theme.brandPrimary,
          height: 13,
          [rtl ? 'marginLeft' : 'marginRight']: theme.verticalMargin,
        }}
      />
      <Text style={{ fontWeight: theme.noBold }} values={{ filesCounter }} withTooltip={false}>
        {systemMessages.fileCount}
      </Text>
    </div>
  );
};
export default AttachmentsCell;
