import { useSelector } from 'react-redux';
import theme from '../../../../../../web/assets/css/theme';
import toggleRight from '../../../../../../web/assets/img/icons/toggleRight.png';

const ExpandableTableCell = ({ cell, children }) => {
  const rtl = useSelector((state) => state.app.rtl);
  const { row, column } = cell.getContext();

  const isGroupBy = column.columnDef.groupByIndex === 0 && row.original.subRows;
  const isExpandedGroup = isGroupBy && row.getIsExpanded();

  return (
    <div
      onClick={row.getToggleExpandedHandler()}
      className={`ExpandableTableCell ${
        isGroupBy ? (row.original.index === 0 ? 'primaryGroupByBackground' : 'secondaryGroupByBackground') : ''
      }`}
      style={{
        backgroundColor: isExpandedGroup ? theme.brandPrimary : 'transparent',
        paddingRight: `${row.original.index}rem`,
        cursor: isGroupBy ? 'pointer' : 'auto',
      }}>
      <div className='ExpandableTableCell_content'>
        {isGroupBy ? (
          <div>
            <img
              className='ExpandableTableCell_arrowIcon'
              src={toggleRight}
              style={{
                transform: isExpandedGroup ? 'rotate(90deg)' : `rotate(${rtl ? 180 : 0}deg)`,
              }}
            />
          </div>
        ) : null}
        <div
          className='ExpandableTableCell_value'
          style={{
            width: isGroupBy ? '75%' : '100%',
          }}>
          {children}
        </div>
        {isGroupBy && row.original?.totalItems >= 0 ? (
          <span
            className='ExpandableTableCell_count'
            style={{
              color: isExpandedGroup ? theme.brandPrimary : 'white',
              backgroundColor: isExpandedGroup ? 'white' : theme.brandPrimary,
            }}>
            {row.original?.totalItems}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default ExpandableTableCell;
